import React, {useEffect, useState} from "react";
import Footer from "./../../../layouts/Footer";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MainComponent} from "../../../components/MainComponent";
import {useDispatch, useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import {checkDateActions, checkDateSelector} from "../../../store/date-slice";
import {toast} from "react-toastify";
import {
    fetchDateWiseDHUData,
    fetchFloorWiseDHU,
    fetchLineWiseDHU,
    fetchStyleWiseDHU
} from "../../../services/input/dhu-input-service";
import ReactApexChart from "react-apexcharts";
import Select from "react-select";

const formatDate = (dateString) => {
    const options = {day: 'numeric', month: 'short', year: 'numeric'};
    return new Date(dateString).toLocaleDateString(undefined, options);
};
const locations = [{label: "New Zeel Rainwear", value: 1}];

export default function DHU() {

    const [loader, setLoader] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [dateWiseData, setDateWiseData] = useState([]);
    const [selectedDate, setSelectedDate] = useState({floor:{status:false,date:""},line:{status:false,date:""},style:{status:false,date:""}});
    const [floorWiseDHU, setFloorWiseDHU] = useState([]);
    const [lineWiseDHU, setLineWiseDHU] = useState([]);
    const [styleWiseDHU, setStyleWiseDHU] = useState([]);
    const { location} = useSelector(checkDateSelector);
    const [selectedLocation,setSelectedLocation]=useState(null);


    const dispatch = useDispatch();
    const {token} = useSelector(loginSelector);
    const {fromDate, toDate} = useSelector(checkDateSelector);
    async function getDateWiseDHUData() {
        setLoader(true);
        try {
            const result = await fetchDateWiseDHUData(fromDate, toDate,location, token);
            if (result.status) {
                setDateWiseData(result.data)
                setChartData(result.data.map(item => ({
                    category: item?.production_date,
                    value: item?.dhu.toFixed(2)
                })));

            } else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }

    async function getFloorWiseDHU(date, unitId) {
        setLoader(true);
        try {
            const result = await fetchFloorWiseDHU(fromDate, date, unitId, token);
            if (result.status) {

                setFloorWiseDHU(result.data)
                setSelectedDate({floor:{status:true,date:date},line:{status:false,date:""},style:{status:false,date:""}})
            } else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }

    async function getLineWiseDHU(date, unitId, floorId) {
        setLoader(true);
        try {
            const result = await fetchLineWiseDHU(fromDate, date, unitId, floorId, token);
            if (result.status) {
                setLineWiseDHU(result.data);
                setSelectedDate({...selectedDate,line:{status:true,date:date},style:{status:false,date:""}})

            } else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }

    async function getStyleWiseDHU(date, unitId, floorId, lineId) {
        setLoader(true);
        try {
            const result = await fetchStyleWiseDHU(fromDate, date, unitId, floorId, lineId, token);
            if (result.status) {
                setStyleWiseDHU(result.data)
                setSelectedDate({...selectedDate,style:{status:true,date:date}})
            } else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }


    // Chart configuration
    // const chartOptions = {
    //     chart: {
    //         type: 'area',
    //         height: 350,
    //     },
    //     plotOptions: {
    //         bar: {
    //             horizontal: false,
    //             colors: {
    //                 ranges: [{
    //                     from: Number.MIN_SAFE_INTEGER,
    //                     to: 0,
    //                     color: negativeColor,
    //                 }, {
    //                     from: 1,
    //                     to: Number.MAX_SAFE_INTEGER,
    //                     color: positiveColor,
    //                 }],
    //             },
    //         },
    //     },
    //     series: [{
    //         name: 'Values',
    //         data: chartData.map(item => item.value),
    //     }],
    //     xaxis: {
    //         categories: chartData.map(item => item.category),
    //     },
    // };
    // const chartOptions = {
    //     chart: {
    //         type: 'line',
    //         height: 350,
    //     },
    //     xaxis: {
    //         categories: chartData.map(item => item.category),
    //         labels: {
    //             rotate: -45,
    //             formatter: function (val) {
    //                 return formatDate(val);
    //             },
    //         },
    //     },
    //     yaxis: {
    //         title: {
    //             text: 'Rs',
    //         },
    //     },
    //     dataLabels: {
    //         enabled: false,
    //     },
    //     stroke: {
    //         curve: 'smooth',
    //     },
    //     series: [
    //         {
    //             name: 'Value',
    //             data: chartData.map(item => item.value),
    //         },
    //     ],
    //     title: {
    //         text: 'Dhu',
    //     },
    //     markers: {
    //         size: 6, // Adjust the size of the markers
    //         strokeColors: '#fff', // Marker border color
    //         colors: '#229cff', // Marker fill color
    //         strokeWidth: 2, // Border width of the markers
    //         hover: {
    //             size: 8, // Marker size on hover
    //         },
    //     },
    //     fill: {
    //         type: 'gradient',
    //         gradient: {
    //             shadeIntensity: 1,
    //             opacityFrom: 0.7,
    //             opacityTo: 0.9,
    //             stops: [0, 100],
    //         },
    //     },
    //
    // };

    const options = {
        series: [
            {
                name: "Value",
                data: chartData.map(item => item.value)
            },
        ],
        chart: {
            height: 350,
            type: 'line',
            dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            toolbar: {
                show: false
            }
        },
        colors: ['#77B6EA', '#545454'],
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: 'smooth'
        },
        title: {
            text: 'Defect per Hundred Units',
            align: 'left'
        },
        grid: {
            borderColor: '#e7e7e7',
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        markers: {
            size: 1
        },
        xaxis: {
            categories: chartData.map(item => item.category),
            title: {
                text: 'Dates'
            }
        },
        yaxis: {
            title: {
                text: 'DHU %'
            },
            min: 5,
            max: 40
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
        }
    };


    useEffect(() => {
        if (location) {
            setSelectedLocation(locations.find((lc) => lc.value === Number(location)))
        }
    }, [location]);
    useEffect(() => {
        if (fromDate && toDate && location) {
            getDateWiseDHUData();
        }
    }, [fromDate, toDate,location]);


    return (<React.Fragment>

        <MainComponent>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Dhu</li>
                        </ol>
                        <h4 className="main-title mb-0">Dhu</h4>
                    </div>
                </div>
                <Row style={{border: "1px solid #0d6efd"}} className="py-3">
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="fromDate">From
                            Date</Form.Label>
                        <Form.Control type="date" name="fromDate" id="fromDate"
                                      value={fromDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.fromDateUpdate({fromDate:e.target.value}))}} placeholder="fromDate"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="toDate">To
                            Date</Form.Label>
                        <Form.Control type="date" name="toDate" id="toDate"
                                      value={toDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.toDateUpdate({toDate:e.target.value}))
                                      }} placeholder="To Date"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="location">Location </Form.Label>
                        <Select name="location" options={locations} isSearchable={true} isClearable
                                value={selectedLocation}
                                className="is-invalid" onChange={(newValue) => {
                            dispatch(checkDateActions.addLocation({location: newValue?.value}))
                        }}/>

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Button type="submit" className="btn btn-primary mt-4">Submit</Button>
                    </Col>
                </Row>


                <Row className="g-3">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>Dhu</Card.Title>
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>
                                <Table hover responsive bordered striped>
                                    <thead>
                                    <tr>
                                        <th>Id</th>
                                        <th>Action</th>
                                        <th>Unit Name</th>
                                        <th>Date</th>
                                        <th>Total Output</th>
                                        <th>Total Pass</th>
                                        <th>Total Defect</th>
                                        <th>DHU</th>
                                        <th>Defect Pcs</th>
                                        <th>Defect %</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dateWiseData.length > 0 && dateWiseData
                                        .map((item, index) => (
                                            <tr key={item.production_date}>
                                                <td>{index + 1}</td>
                                                <td onClick={() => getFloorWiseDHU(item?.production_date, item?.unit_id)}
                                                    style={{cursor: "pointer"}}>Floor Details
                                                </td>
                                                <td>{item.unit_name}</td>
                                                <td>{item.production_date}</td>
                                                <td>{item.total_output}</td>
                                                <td>{item.total_pass}</td>
                                                <td>{item.total_defect}</td>
                                                <td>{item.dhu.toFixed(2)}</td>
                                                <td>{item.defect_pcs}</td>
                                                <td>{item.defective_percentage.toFixed(2)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>

                </Row>

                <Row className="g-3 mt-4">
                    <ReactApexChart options={options} series={options.series} type="line" height={400} />
                </Row>

                {selectedDate.floor.date !== "" && selectedDate.floor.status && (
                    <Row className="g-3">
                        <Card className="card-settings mt-4">
                            <Card.Header>
                                <Card.Title>DHU Data Floor Wise {selectedDate.floor.date} </Card.Title>
                                {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <div>

                                    <Table hover responsive bordered striped>
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Action</th>
                                            <th>Unit Name</th>
                                            <th>Floor Name</th>
                                            <th>Date</th>
                                            <th>Total Output</th>
                                            <th>Total Pass</th>
                                            <th>Total Defect</th>
                                            <th>DHU</th>
                                            <th>Defect Pcs</th>
                                            <th>Defect %</th>
                                            {/* Add more table headers here */}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {/*{*/}
                                        {/*    "unit_id": 1,*/}
                                        {/*    "unit_name": "NEW ZEEL RAINWEAR",*/}
                                        {/*    "floor_name": "OBG STITCHING",*/}
                                        {/*    "production_date": "2023-10-01",*/}
                                        {/*    "total_output": 1080,*/}
                                        {/*    "total_pass": 1040,*/}
                                        {/*    "total_defect": 40,*/}
                                        {/*    "dhu": 3.7037037037037033,*/}
                                        {/*    "defect_pcs": 40,*/}
                                        {/*    "defective_percentage": 3.7037037037037033*/}
                                        {/*}*/}
                                        {floorWiseDHU.length > 0 && floorWiseDHU
                                            .map((item, index) => (
                                                <tr key={item.production_date}>
                                                    <td>{index + 1}</td>
                                                    <td onClick={() => getLineWiseDHU(item?.production_date, item?.unit_id,item.floor_id)}
                                                        style={{cursor: "pointer"}}>Lines Details
                                                    </td>
                                                    <td>{item.unit_name}</td>
                                                    <td>{item.floor_name}</td>
                                                    <td>{item.production_date}</td>
                                                    <td>{item.total_output}</td>
                                                    <td>{item.total_pass}</td>
                                                    <td>{item.total_defect}</td>
                                                    <td>{item.dhu.toFixed(2)}</td>
                                                    <td>{item.defect_pcs}</td>
                                                    <td>{item.defective_percentage.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </div>
                            </Card.Body>
                        </Card>

                    </Row>
                )}

                {selectedDate.line.date !== "" && selectedDate.line.status && (
                    <Row className="g-3">
                        <Card className="card-settings mt-4">
                            <Card.Header>
                                <Card.Title>DHU Data Line Wise {selectedDate.line.date} </Card.Title>
                                {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <div>

                                    <Table hover responsive bordered striped>
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Action</th>
                                            <th>Unit Name</th>
                                            <th>Floor Name</th>
                                            <th>Line Name</th>
                                            <th>Date</th>
                                            <th>Total Output</th>
                                            <th>Total Pass</th>
                                            <th>Total Defect</th>
                                            <th>DHU</th>
                                            <th>Defect Pcs</th>
                                            <th>Defect %</th>
                                            {/* Add more table headers here */}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lineWiseDHU.length > 0 && lineWiseDHU
                                            .map((item, index) => (
                                                <tr key={item.production_date}>
                                                    <td>{index + 1}</td>
                                                    <td onClick={() => getStyleWiseDHU(item?.production_date, item?.unit_id,item?.floor_id,item?.line_id,)}
                                                        style={{cursor: "pointer"}}>Style Details
                                                    </td>
                                                    <td>{item.unit_name}</td>
                                                    <td>{item.floor_name}</td>
                                                    <td>{item.line_name}</td>
                                                    <td>{item.production_date}</td>
                                                    <td>{item.total_output}</td>
                                                    <td>{item.total_pass}</td>
                                                    <td>{item.total_defect}</td>
                                                    <td>{item.dhu.toFixed(2)}</td>
                                                    <td>{item.defect_pcs}</td>
                                                    <td>{item.defective_percentage.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </div>
                            </Card.Body>
                        </Card>

                    </Row>
                )}



                {selectedDate.style.date !== "" && selectedDate.style.status && (
                    <Row className="g-3">
                        <Card className="card-settings mt-4">
                            <Card.Header>
                                <Card.Title>DHU Data Style Wise {selectedDate.style.date} </Card.Title>
                                {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <div>

                                    <Table hover responsive bordered striped>
                                        <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Unit Name</th>
                                            <th>Floor Name</th>
                                            <th>Line Name</th>
                                            <th>Style Name</th>
                                            <th>Date</th>
                                            <th>Total Output</th>
                                            <th>Total Pass</th>
                                            <th>Total Defect</th>
                                            <th>DHU</th>
                                            <th>Defect Pcs</th>
                                            <th>Defect %</th>
                                            {/* Add more table headers here */}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {styleWiseDHU.length > 0 && styleWiseDHU
                                            .map((item, index) => (
                                                <tr key={item.production_date}>
                                                    <td>{index + 1}</td>

                                                    <td>{item.unit_name}</td>
                                                    <td>{item.floor_name}</td>
                                                    <td>{item.line_name}</td>
                                                    <td>{item.style_name}</td>
                                                    <td>{item.production_date}</td>
                                                    <td>{item.total_output}</td>
                                                    <td>{item.total_pass}</td>
                                                    <td>{item.total_defect}</td>
                                                    <td>{item.dhu.toFixed(2)}</td>
                                                    <td>{item.defect_pcs}</td>
                                                    <td>{item.defective_percentage.toFixed(2)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>

                                </div>
                            </Card.Body>
                        </Card>

                    </Row>
                )}

                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}

