import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";
import {fetchUnits} from "../../../services/master/unit-service";
import UnitTable from "./UnitTable";

export default function Units() {

    const {token} = useSelector(loginSelector);
    const [loader, setLoader] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllUnitsList = async () => {
        setLoader(true);
        try {
            const result = await fetchUnits(currentPage, itemsPerPage, token);
            if (result.status) {
                setItems(result.data.data)
                setCurrentPage(result.data.page_number);
                setCurrentPage(result.data.page_number);
                setTotalPages(result.data.total_records >= 0 ? Math.ceil(result.data.total_records / itemsPerPage) : 1)
            }
        } catch (err) {
            console.log(err);
        }
        setLoader(false);

    };

    useEffect(() => {
        getAllUnitsList();
    }, [currentPage]);




    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Units</li>
                            </ol>
                            <h4 className="main-title mb-0">Units</h4>
                        </div>
                    </div>

                    <Row className="g-3">
                        <UnitTable items={items} currentPage={currentPage} totalPages={totalPages}
                                   currentPageHandle={currentPageHandle}/>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
