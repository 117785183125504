import React from 'react';
import {Table, Pagination, Card} from 'react-bootstrap';

const LineTable = ({items, currentPage, totalPages, currentPageHandle}) => {
    function handlePageChange(page) {
        currentPageHandle(page);
    }
    return (<>
        <Card className="card-settings mt-4">
            <Card.Header>
                <Card.Title>Line List</Card.Title>
                {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
            </Card.Header>
            <Card.Body className="p-4">
                    <div>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Line Name</th>
                                <th>Floor Name</th>
                                {/* Add more table headers here */}
                            </tr>
                            </thead>
                            <tbody>

                            {items.length > 0 && items.map(item => (<tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.line_name}</td>
                                    <td>{item.floor_name}</td>
                                    <td>{item.action}</td>
                                    {/* Add more table cells here */}
                                </tr>))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            />
                            {Array.from({length: totalPages}, (_, index) => (<Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>))}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            />
                        </Pagination>
                    </div>
                </Card.Body>
            </Card>
        </>);
};

export default LineTable;
