import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {getRoles} from "../../services/roles-permissions-services";
import {useFormik} from "formik";
import {userSchema} from "../../schemas";
import Select from "react-select";
import {getUser} from "../../services/user-services";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const initialValues = {name: "", email: "", mobile: "", userIdType: "", empId: "", role: ""};
const UserIdSelect = [{"label": "Select Type", "value": ""},{"label": "PayRoll", "value": 1}, {"label": "Generate", "value": 2}];
const RoleForm = ({isEditMode,clearForm, submitHandler, userId, token}) => {
    const navigate=useNavigate();
    const [loader, setLoader] = useState(false);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});
    const [selectedType, setSelectedType] = useState(null);
    const {values, touched, resetForm, setFieldValue, errors, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: userSchema,
        onSubmit: ((values) => {
            submitHandler(values);
            resetForm();
        })
    })

    const getAllRoles = async () => {
        setLoader(false);
        try {
            const result = await getRoles(token);
            if (result.status) {
                setRoles(result.data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoader(false);
    }


    const getUserById = async (id) => {
        setLoader(true);
        try {
            const result = await getUser(id, token);
            if (result.status) {
                setFieldValue('name', result.data.name);
                setFieldValue('email', result.data.email);
                setFieldValue('mobile', result.data.mobile);
                setFieldValue('userIdType', result.data.emp_id_type);
                setFieldValue('empId', result.data.emp_id);
                setFieldValue('role', result.data.roles[0].name);
                setSelectedType(UserIdSelect.filter((item)=>item.value === result.data.emp_id_type)[0]);
            } else {
                toast.error(result.message);
                navigate("/users");
            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }

    useEffect(() => {
        getAllRoles();
    }, [])

    useEffect(() => {
        if (isEditMode && userId > 0) {
            getUserById(userId);
        }
    }, [userId, isEditMode]);

    useEffect(() => {
        setSelectedRole(roles.filter(role => role.label === values.role));
    }, [values.role])

    useEffect(() => {
        resetForm();
        setSelectedType(null);
        setSelectedRole({});

    },[clearForm])

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>{isEditMode ? "Update" : "Create"} User</Card.Title>
                    {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                </Card.Header>
                <Card.Body className="p-4">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="name" className="col-form-label">Name</Form.Label>*/}
                                <h6 className="col-form-label">Name</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="text" name="name" id="name" value={values.name}
                                              onChange={handleChange} placeholder="Name"
                                              isInvalid={!!errors.name && touched.name}/>
                                {errors.name && touched.name && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="email" className="col-form-label">Email</Form.Label>*/}
                                <h6 className="col-form-label">Email</h6>
                            </Col>

                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="email" name="email" id="email" value={values.email}
                                              onChange={handleChange} placeholder="Email"
                                              isInvalid={!!errors.email && touched.email}/>
                                {errors.email && touched.email && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                )}
                            </Col>

                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="mobile" className="col-form-label">Mobile</Form.Label>*/}
                                <h6 className="col-form-label">Mobile</h6>
                            </Col>
                            <Col xs={12} md={5}  className="mt-2">
                                <Form.Control type="mobile" name="mobile" id="mobile" value={values.mobile}
                                              onChange={handleChange} placeholder="Mobile"
                                              isInvalid={!!errors.mobile && touched.mobile}/>
                                {errors.mobile && touched.mobile && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.mobile}
                                    </Form.Control.Feedback>
                                )}
                            </Col>

                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="userIdType" className="col-form-label">Employee Id</Form.Label>*/}
                                <h6 className="col-form-label">Employee Id</h6>
                            </Col>

                            <Col xs={12} md={5} className="mt-2">

                                <Select name="userIdType" options={UserIdSelect} isSearchable={true} isClearable
                                        value={selectedType}
                                        className="is-invalid" onChange={(newValue) => {
                                    setFieldValue('userIdType', newValue.value);
                                    setFieldValue('empId', "EMPID_0001");
                                    setSelectedType(newValue)
                                }}/>

                                {errors.userIdType && touched.userIdType && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.userIdType}
                                    </Form.Control.Feedback>
                                )}
                            </Col>


                            {values.userIdType===1 && (
                                <>
                                    <Col xs={12} md={1} className="mt-2">
                                        {/*<Form.Label htmlFor="empId" className="col-form-label">Employee Id</Form.Label>*/}
                                        <h6 className="col-form-label">Employee Id</h6>
                                    </Col>
                                <Col xs={12} md={5} className="mt-2">
                                    <Form.Control type="text" name="empId" id="empId" value={values.empId}
                                                  placeholder="empId" disabled={true}
                                                  isInvalid={!!errors.empId && touched.empId}/>
                                    {errors.empId && touched.empId && (
                                        <Form.Control.Feedback type="invalid">
                                            {errors.empId}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                </>
                            )}

                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="role" className="col-form-label">Select Role</Form.Label>*/}
                                <h6 className="col-form-label">Select Role</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">
                                <Select name="role" options={roles} isSearchable={true} isClearable value={selectedRole}
                                        className="is-invalid" onChange={(newValue) => {
                                    setSelectedRole(newValue);
                                    setFieldValue('role', newValue.label);
                                }}/>

                                {errors.role && touched.role && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.role}
                                    </Form.Control.Feedback>
                                )}
                            </Col>

                            <Col xs={12} md={5} className="mt-2">
                                <Button type="submit" variant="primary" className="btn-sign">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};
export default RoleForm;
