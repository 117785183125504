const dashboardMenu = [
    {
        "label": "Roles",
        "link": "roles",
        "icon": "ri-book-line"
    },
    {
        "label": "Users",
        "link": "users",
        "icon": "ri-user-fill"
    },

];

const inputForms = [
    {
        "label": "HR Input",
        "link": "input-forms/hr-input",
        "icon": "ri-book-line"
    },
    {
        "label": "IE Input",
        "link": "input-forms/ie-input",
        "icon": "ri-book-line"
    },
    {
        "label": "Man Power Allotment",
        "link": "input-forms/manpower-allotment",
        "icon": "ri-book-line"
    },   {
        "label": "DHU Input",
        "link": "input-forms/dhu-input",
        "icon": "ri-book-line"
    },
];

const Master = [
    {
        "label": "Units",
        "link": "/master/units",
        "icon": "ri-community-line"
    },

    {
        "label": "Working Hours",
        "link": "/master/working-hours",
        "icon": "ri-24-hours-line"
    },
    {
        "label": "Floors",
        "link": "/master/floors",
        "icon": "ri-building-4-line"
    },
    {
        "label": "Lines",
        "link": "/master/lines",
        "icon": "ri-book-line"
    },
    // {
    //     "label": "Sam Range",
    //     "link": "/master/sam-range",
    //     "icon": "ri-book-line"
    // },

];


export {dashboardMenu, inputForms, Master};
