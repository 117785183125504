import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    fromDate: "",
    toDate: "",
    location: localStorage.getItem("location"),
}

export const dateSlice = createSlice({
    name: 'checkDate',
    initialState,
    reducers: {
        updateDates: (state, action) => {
            state.fromDate = action.payload.fromDate;
            state.toDate = action.payload.toDate;

        }, fromDateUpdate: (state, action) => {
            state.fromDate = action.payload.fromDate;

        }, toDateUpdate: (state, action) => {
            state.toDate = action.payload.toDate;
        },
        addLocation: (state, action) => {
            localStorage.setItem("location", action.payload.location);
            state.location = action.payload.location;
        },

    },
})

// Action creators are generated for each case reducer function
export const checkDateSelector = (state) => state.checkdate;

export const checkDateActions = dateSlice.actions


export default dateSlice.reducer



