import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const addDHUInput = async (inputDate, unitId, styles, token) => {

    const data = JSON.stringify({
        input_date: inputDate,
        unit_id: unitId,
        styles
    });

    const res = await axios.post(`${BASE_URL}/add-dhu-input`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};
export const fetchStylesByDateUnit = async (inputDate, unitId, token) => {
    const data = JSON.stringify({
        unit_id: unitId,
        input_date: inputDate,
    });
    const res = await axios.post(`${BASE_URL}/get-style-by-date-unit`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchDhuInputList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-dhu-input-list`, data, config);
    return res.data;
};


export const getDHUInput = async (dhuId, token) => {
    const res = await axios.get(`${BASE_URL}/get-dhu-input/${dhuId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const updateDHUInput = async (id, inputDate, unitId, styles, token) => {

    const data = JSON.stringify({
        id: id,
        input_date: inputDate,
        unit_id: unitId,
        styles
    });

    const res = await axios.post(`${BASE_URL}/update-dhu-input`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchDateWiseDHUData = async (fromData, toDate,unitId, token) => {
    const data = JSON.stringify({
        from_date: fromData,
        to_date: toDate,
        unit_id: unitId,
    });
    const res = await axios.post(`${BASE_URL}/get-date-wise-dhu`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchFloorWiseDHU = async (fromDate,toDate, unitId, token) => {
    const data = JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        unit_id: unitId,
    });
    const res = await axios.post(`${BASE_URL}/get-floor-wise-dhu`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchLineWiseDHU = async (fromDate,toDate, unitId,floorId, token) => {
    console.log(unitId,"unit Id")
    const data = JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        unit_id: unitId,
        floor_id: floorId,
    });
    console.log(data,"floorWiseDate");
    const res = await axios.post(`${BASE_URL}/get-line-wise-dhu`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchStyleWiseDHU = async (fromDate,toDate, unitId,floorId,lineId, token) => {
    const data = JSON.stringify({
        from_date: fromDate,
        to_date: toDate,
        unit_id: unitId,
        floor_id: floorId,
        line_id: lineId,
    });
    const res = await axios.post(`${BASE_URL}/get-style-wise-dhu`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};



