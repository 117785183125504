import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import Select from "react-select";
import {useNavigate} from "react-router-dom";
import {operationSchema} from "../../../schemas";

const initialValues = {operation: "", status: ""};
const machineStatus =[{"label": "Enabled", "value": "1"},{"label": "Disabled", "value": "2"}];

const OperationForm = ({isEditMode,clearForm, submitHandler, opEntryId, token}) => {
    const navigate=useNavigate();
    const [loader, setLoader] = useState(false);
    const [selectedStatus,setSelectedStatus] = useState(null);

    const {values, touched, resetForm, setFieldValue, errors, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: operationSchema,
        onSubmit: ((values) => {
            submitHandler(values);
            resetForm();
        })
    })


    useEffect(() => {
        resetForm();
    },[clearForm])

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-one mt-5">
                <Card.Header>
                    <Card.Title as="h1" style={{
                        fontWeight: "700",
                        fontSize: "24px"
                    }}>{isEditMode ? "Update" : "Add"} Operation</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={3}>
                                <Form.Label htmlFor="operation">Machine Type</Form.Label>
                                <Form.Control type="text" name="operation" id="operation" value={values.operation}
                                              onChange={handleChange} placeholder="Name"
                                              isInvalid={!!errors.operation && touched.operation}/>
                                {errors.operation && touched.operation && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.operation}
                                    </Form.Control.Feedback>
                                )}
                            </Col>


                            <Col xs={12} md={3}>
                                <Form.Label htmlFor="status">Status</Form.Label>
                                <Select name="status" options={machineStatus} isSearchable={true} isClearable
                                        value={selectedStatus}
                                        className="is-invalid" onChange={(newValue) => {
                                    setFieldValue('status', newValue.value);
                                    setSelectedStatus(newValue)
                                }}/>

                                {errors.status && touched.status && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.status}
                                    </Form.Control.Feedback>
                                )}
                            </Col>


                            <Col xs={12} md={3} className="d-flex align-items-center">
                                <Button type="submit" variant="primary" className="btn-sign mt-4">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};
export default OperationForm;
