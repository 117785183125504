import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn: !!localStorage.getItem("token"),
    token: localStorage.getItem("token"),
    userRole: localStorage.getItem("userRole"),
    email: localStorage.getItem("email"),
    name: localStorage.getItem("name"),
    access: JSON.parse(localStorage.getItem("access")),
    userLimit: localStorage.getItem("userLimit"),
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        login(state, action) {
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("userRole", action.payload.userRole);
            localStorage.setItem("name", action.payload.name);
            localStorage.setItem("email", action.payload.email);
            localStorage.setItem("access", JSON.stringify(action.payload.access));
            localStorage.setItem("userLimit", JSON.stringify(action.payload.userLimit));
            state.isLoggedIn = !!action.payload.token;
            state.token = action.payload.token;
            state.userRole = action.payload.userRole;
            state.name = action.payload.name;
            state.email = action.payload.email;
            state.access = action.payload.access;
            state.userLimit = action.payload.userLimit;
        },
        logout(state) {
            localStorage.removeItem("token");
            localStorage.removeItem("userRole");
            localStorage.removeItem("name");
            localStorage.removeItem("email");
            localStorage.removeItem("access");
            localStorage.removeItem("userLimit");
            state.token = "";
            state.isLoggedIn = false;
            state.userRole = "";
            state.name = "";
            state.email = "";
            state.access = [];
            state.userLimit =0;
        },

    },
})

export const loginActions = loginSlice.actions
export const loginSelector = (state) => state.login;
export default loginSlice.reducer
