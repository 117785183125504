import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const createFloor = async (floorName, token) => {
    const data = JSON.stringify({
        floor_name:floorName,
    });
    const res = await axios.post(`${BASE_URL}/create-floor`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchFloorList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-floor-list`, data, config);
    return res.data;
};


export const getFloor = async (floorId, token) => {
    const res = await axios.get(`${BASE_URL}/get-floor/${floorId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const fetchFloorsByDateDepartment = async (inputDate,Department,token) => {
    const data = JSON.stringify({
        input_date:inputDate,
        department:Department
    });
    const res = await axios.post(`${BASE_URL}/get-floors-by-date-department`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};
export const fetchFloors = async (token) => {
    const res = await axios.get(`${BASE_URL}/get-floors`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const updateFloor = async (
    floorId,
    floorName,
    token,

) => {

    const data = JSON.stringify({
        id: floorId,
        floor_name:floorName,
    });


    const res = await axios.post(`${BASE_URL}/update-floor`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const deleteFloor = async (floorId, token) => {
    const res = await axios.delete(`${BASE_URL}/delete-floor/${floorId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


