import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import HrTable from "./HrTable";
import {toast} from "react-toastify";
import {loginSelector} from "../../../store/login-slice";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";
import HrForm from "./HrForm";
import {addHrInput, fetchHrInputList, updateHRInput} from "../../../services/input/hr-input-service";

export default function HrInput() {

    const {hrInputId} = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const {token} = useSelector(loginSelector);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [resetForm, setResetForm] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllHrInputList = async () => {
        setLoader(true);
        try {
            const result = await fetchHrInputList(currentPage, itemsPerPage, token);


            if (result.status) {
                setItems(result.data.data.map(row => ({
                    ...row, action: (<>
                        <Link to={`/input-forms/hr-input/${row.id}`}><i className="ri-edit-box-line"></i></Link> &nbsp;
                    </>)
                })));
                setCurrentPage(result.data.page_number);
                setCurrentPage(result.data.page_number);
                setTotalPages(result.data.total_records >= 0 ? (result.data.total_records / itemsPerPage) : 1)
            }
        } catch (err) {
            // console.log(err);
        }
        setLoader(false);

    };



    const submitHandler = async (data) => {

        setLoader(true)
        const {inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost} = data;
        try {
            const result = await addHrInput(inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost, token);
            if (result.status) {
                toast.success(result.message);
                getAllHrInputList();
            } else {
                setLoader(false);
                toast.error(result.message);
                return false;
            }
            setLoader(false);
            navigate("/input-forms/hr-input");

        } catch (err) {
            setLoader(false);
            console.log(err);
        }

    }

    const updateHandler = async (id, data) => {
        const {inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost} = data;

        try {
            const result = await updateHRInput(id, inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost, token);
            if (result.status) {
                toast.success(result.message);
                navigate("/input-forms/hr-input");
                await getAllHrInputList();
            } else {
                toast.error(result.message);
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    }



    useEffect(() => {
        if (hrInputId) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [hrInputId]);

    useEffect(() => {
        getAllHrInputList();
    }, [])

    useEffect(() => {
        setResetForm(!resetForm);
    }, [hrInputId]);


    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">HR Input</li>
                            </ol>
                            <h4 className="main-title mb-0">HR Input</h4>
                        </div>
                    </div>

                    <Row className="g-3">
                        <HrForm
                            hrInputId={hrInputId || 0}
                            isEditMode={isEditMode}
                            clearForm={resetForm}
                            submitHandler={(obj) => {
                                if (isEditMode) return updateHandler(hrInputId || 0, obj);;
                                return submitHandler(obj);
                            }}
                            token={token}/>
                    </Row>
                    <Row className="g-3 mt-4">
                        <HrTable items={items} currentPage={currentPage} totalPages={totalPages}
                                 currentPageHandle={currentPageHandle}/>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
