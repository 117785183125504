/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showLoader: false,
};
// isLoggedIn: !!localStorage.getItem('token')

const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    showLoader(state, action) {
      state.showLoader = action.payload;
    },
  },
});

export const loaderSelector = (state) => state.loader;

export const loaderActions = loaderSlice.actions;

export default loaderSlice.reducer;
