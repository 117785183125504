import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";


export default function Intimation() {


    return (
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Intimation</li>
                            </ol>
                        </div>
                    </div>
                    <Row className="g-3 my-3">
                       <h2>Intimation</h2>
                    </Row>

                    <Footer/>
                </div>
            </MainComponent>
    );
};
