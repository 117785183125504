import React, {useEffect, useState} from "react";
import Footer from "../../../layouts/Footer";
import {Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import OperationForm from "./OperationForm";
import {MainComponent} from "../../../components/MainComponent";


export default function MachineType() {

    const {operationId} = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const {token} = useSelector(loginSelector);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [resetForm, setResetForm] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllUserList = async () => {
        setLoader(true);
        // try {
        //     const result = await fetchUserList(currentPage, itemsPerPage, token);
        //     if (result.status) {
        //         setItems(result.data.data.map(row => ({
        //             ...row, action: (<>
        //                 <Link to={`/users/${row.id}`}><i class="ri-edit-box-line"></i></Link> &nbsp;
        //                 <Link onClick={() => userDelete(row.id)}><i class="ri-delete-bin-2-line"></i></Link>
        //             </>)
        //         })));
        //         setCurrentPage(result.data.page_number);
        //         setCurrentPage(result.data.page_number);
        //         setTotalPages(result.data.total_records >= 0 ? (result.data.total_records / itemsPerPage) : 1)
        //     }
        // } catch (err) {
        //     console.log(err);
        // }
        setLoader(false);

    };


    const updateHandler = async (id, data) => {
        const {name, email, role, operationIdType, empId, mobile} = data;
        // try {
        //     const result = await updateUser(id, name, email, role, token, operationIdType, empId, mobile);
        //     if (result.status) {
        //         toast.success(" User Updated !");
        //         navigate("/users");
        //         getAllUserList();
        //     } else {
        //         toast.error(result.message);
        //         return false;
        //     }
        // } catch (err) {
        //     console.log(err);
        // }
    }


    const submitHandler = async (data) => {
        setLoader(true);

        const {name, email, role, operationIdType, empId, mobile} = data;
        // try {
        //     const result = await createUser(name, email, role, operationIdType, empId, token, mobile);
        //     if (result.status) {
        //         toast.success("User Created !");
        //         getAllUserList();
        //     } else {
        //         setLoader(false);
        //         toast.error(result.message);
        //         return false;
        //     }
        // } catch (err) {
        //     console.log(err);
        // }
        setLoader(false);

    }


    async function userDelete(operationId) {
        setLoader(true);
        // try {
        //     const result = await deleteUser(operationId, token);
        //     if (result.status) {
        //         toast.success(" User Deleted !");
        //         getAllUserList();
        //     } else {
        //         toast.error(result.message);
        //     }
        // } catch (err) {
        //     console.log(err);
        // }
        setLoader(false);
    }

    useEffect(() => {
        if (operationId) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [operationId]);

    useEffect(() => {
        // getAllUserList();
    }, [])

    useEffect(() => {
        setResetForm(!resetForm);
    }, [operationId]);


    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Operation</li>
                            </ol>
                        </div>
                    </div>

                    <Row className="g-3">
                        <OperationForm operationId={operationId || 0}
                                     isEditMode={isEditMode}
                                     clearForm={resetForm}
                                     submitHandler={(obj) => {
                                         if (isEditMode) return updateHandler(operationId || 0, obj);
                                         return submitHandler(obj);
                                     }}
                                     token={token}/>
                    </Row>

                    <Row className="g-3">
                        {/*<OperationTable items={items} currentPage={currentPage} totalPages={totalPages}*/}
                        {/*              currentPageHandle={currentPageHandle}/>*/}
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
