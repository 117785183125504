import React, {useEffect, useState} from "react";
import Header from "./../../../layouts/Header";
import Footer from "./../../../layouts/Footer";
import {Card, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";

export default function Attrition() {
    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };
    const seriesOne = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];

    const optionOne = {
        chart: {
            parentHeightOffset: 0, stacked: true, toolbar: {show: false}
        }, dataLabels: {enabled: false}, grid: {
            borderColor: 'rgba(72,94,144, 0.07)', padding: {
                top: -20, left: 5
            }
        }, states: states, colors: ['#506fd9', '#fff', '#c8ccd4'], plotOptions: {
            bar: {
                columnWidth: '35%'
            },
        }, stroke: {
            curve: 'straight', lineCap: 'square', width: 0
        }, tooltip: {enabled: false}, fill: {opacity: 1}, legend: {show: false}, xaxis: {
            type: 'numeric', tickAmount: 11, decimalsInFloat: 0, labels: {
                style: {
                    fontSize: '11px'
                }
            }
        }, yaxis: {
            max: 4000, tickAmount: 8, labels: {
                style: {
                    colors: ['#a2abb5'], fontSize: '11px'
                }
            }
        }
    };


    return (<React.Fragment>
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Attrition</li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3">
                    <Card className="card-one card-product-inventory">
                        <Card.Header>
                            <Card.Title as="h6">Product Inventory</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ul className="legend mb-3 position-absolute">
                                <li>Remaining Quantity</li>
                                <li>Sold Quantity</li>
                            </ul>
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310}
                                            className="apex-chart-twelve mt-4 pt-3"/>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}


