import axios from "axios";
import {BASE_URL} from "../utils/urls";

export const fetchModuleListForMenu = async (token) => {
    const res = await axios.get(`${BASE_URL}/module-list-for-menu`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const menuConfigurationSetting = async (menuConfig, token) => {
    const data = JSON.stringify({
        menu_config: menuConfig,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/menu-configuration-setting`, data, config);
    return res.data;
};


export const fetchMenuConfigurationSetting = async (token) => {
    const res = await axios.get(`${BASE_URL}/get-menu-configuration-setting`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};
