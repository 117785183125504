import React, {useEffect, useState} from "react";
import Footer from "./../../../layouts/Footer";
import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";
import {useDispatch, useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import {fetchDateWiseData, fetchDayWiseData} from "../../../services/cost/cutting-profit-loss-service";
import {checkDateActions, checkDateSelector} from "../../../store/date-slice";
import {toast} from "react-toastify";
import Select from "react-select";

const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const locations = [{label: "New Zeel Rainwear", value: 1}];
export default function CuttingProfitLoss() {

    const [loader, setLoader] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [dateWiseData, setDateWiseData] = useState([]);
    const [selectedDate, setSelectedDate] = useState("");
    const [dayWiseData, setDayWiseData] = useState({dayData:[],todayCost:"",totalCost:"",profitLoss:"",totalRevenue:""});
    const dispatch=useDispatch();
    const {token} = useSelector(loginSelector);
    const {fromDate, toDate} = useSelector(checkDateSelector);
    const { location} = useSelector(checkDateSelector);
    const [selectedLocation,setSelectedLocation]=useState(null);

    function isPositiveNumber(num) {
        return num >= 0;
    }

    async function getCuttingData() {
        setLoader(true);
        try {
            const result = await fetchDateWiseData(fromDate, toDate,location, token);
            if (result.status) {
                setDateWiseData(result.data)
                setChartData(result.data.map(item => ({
                    category: item.production_date,
                    value: item.profit_loss
                })));

            }else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }
    async function getDayWiseData() {
        setLoader(true);
        try {
            const result = await fetchDayWiseData(fromDate,selectedDate,location, token);
            if (result.status) {
                setDayWiseData(result.data)
            }else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }


    // Chart configuration
    // const chartOptions = {
    //     chart: {
    //         type: 'area',
    //         height: 350,
    //     },
    //     plotOptions: {
    //         bar: {
    //             horizontal: false,
    //             colors: {
    //                 ranges: [{
    //                     from: Number.MIN_SAFE_INTEGER,
    //                     to: 0,
    //                     color: negativeColor,
    //                 }, {
    //                     from: 1,
    //                     to: Number.MAX_SAFE_INTEGER,
    //                     color: positiveColor,
    //                 }],
    //             },
    //         },
    //     },
    //     series: [{
    //         name: 'Values',
    //         data: chartData.map(item => item.value),
    //     }],
    //     xaxis: {
    //         categories: chartData.map(item => item.category),
    //     },
    // };
    const chartOptions = {
        chart: {
            type: 'area',
            height: 350,
        },
        xaxis: {
            categories: chartData.map(item => item.category),
            labels: {
                rotate: -45,
                formatter: function (val) {
                    return formatDate(val);
                },
            },
        },
        yaxis: {
            title: {
                text: 'Rs',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        series: [
            {
                name: 'Value',
                data: chartData.map(item => item.value),
            },
        ],
        title: {
            text: 'Cutting Profit Loss',
        },
        markers: {
            size: 6, // Adjust the size of the markers
            strokeColors: '#fff', // Marker border color
            colors: '#229cff', // Marker fill color
            strokeWidth: 2, // Border width of the markers
            hover: {
                size: 8, // Marker size on hover
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
            },
        },

    };


    useEffect(() => {
        if (location) {
            setSelectedLocation(locations.find((lc) => lc.value === Number(location)))
        }
    }, [location]);


    useEffect(() => {
        if (fromDate && toDate && location){
            getCuttingData();
        }
    }, [fromDate,toDate,location]);

    useEffect(() => {
        if (selectedDate!==""){
            getDayWiseData()
        }
    }, [selectedDate]);

    return (<React.Fragment>

        <MainComponent>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Cutting Profit Loss</li>
                        </ol>
                        <h4 className="main-title mb-0">Cutting Profit Loss</h4>
                    </div>
                </div>
                <Row style={{border: "1px solid #0d6efd"}} className="py-3">
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="fromDate">From
                            Date</Form.Label>
                        <Form.Control type="date" name="fromDate" id="fromDate"
                                      value={fromDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.fromDateUpdate({fromDate:e.target.value}))}} placeholder="fromDate"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="toDate">To
                            Date</Form.Label>
                        <Form.Control type="date" name="toDate" id="toDate"
                                      value={toDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.toDateUpdate({toDate:e.target.value}))
                                      }} placeholder="To Date"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="location">Location </Form.Label>
                        <Select name="location" options={locations} isSearchable={true} isClearable
                                value={selectedLocation}
                                className="is-invalid" onChange={(newValue) => {
                            dispatch(checkDateActions.addLocation({location: newValue?.value}))
                        }}/>

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Button type="submit" className="btn btn-primary mt-4">Submit</Button>
                    </Col>
                </Row>

                <Row className="g-3">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>Cutting Profit Loss</Card.Title>
                            {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>

                                <Table hover responsive bordered striped>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Date</th>
                                        <th>Revenue</th>
                                        <th>ManPower</th>
                                        <th>Cost</th>
                                        <th>OT Hours</th>
                                        <th>OT Hours Cost</th>
                                        <th>Cutting Qty</th>
                                        <th>Profit Loss</th>
                                        {/* Add more table headers here */}
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {dateWiseData.length > 0 && dateWiseData
                                        .map((item, index) => (
                                            <tr key={item.production_date}>
                                                <td>{index + 1}</td>
                                                <td onClick={()=>setSelectedDate(item.production_date)} style={{cursor:"pointer"}}>{item.production_date}</td>
                                                <td>{item.total_revenue}</td>
                                                <td>{item.man_power}</td>
                                                <td>{item.total_cost}</td>
                                                <td>{item.ot_hours}</td>
                                                <td>{item.ot_hours_cost}</td>
                                                <td>{item.total_qty}</td>
                                                <td className={isPositiveNumber(item.profit_loss)?"bg-success text-white":"bg-danger text-white"}>{item.profit_loss}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                            </div>
                        </Card.Body>
                    </Card>

                </Row>

                <Row className="g-3 mt-4">
                    <ReactApexChart options={chartOptions} series={chartOptions.series} type="area" height={400} />
                </Row>

                {selectedDate!=="" && (
                    <Row className="g-3">
                        <Card className="card-settings mt-4">
                            <Card.Header>
                                <Card.Title>Cutting Profit Loss {fromDate} - {selectedDate} </Card.Title>
                                {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                            </Card.Header>
                            <Card.Body className="p-4">
                                <div>

                                    <Table hover responsive bordered striped>
                                        <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Style</th>
                                            <th>Order No</th>
                                            <th>Planned Cost</th>
                                            <th>Cutting Qty</th>
                                            <th>Total Cutting Qty</th>
                                            <th>Revenue</th>
                                            <th>Actual today Cost</th>
                                            <th>Total Actual Cost</th>
                                            <th>Profit Loss</th>
                                            {/* Add more table headers here */}
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {dayWiseData?.dayData.length > 0 && dayWiseData?.dayData
                                            .map((item, index) => (
                                                <tr key={item.style_name}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.style_name}</td>
                                                    <td>{item.order_no}</td>
                                                    <td>{item.planned_cost}</td>
                                                    <td>{item.cutting_qty}</td>
                                                    <td>{item.total_qty}</td>
                                                    <td>{item.total_revenue}</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                    <td>-</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>{dayWiseData?.totalRevenue}</th>
                                            <th>{dayWiseData?.todayCost}</th>
                                            <th>{dayWiseData?.totalCost}</th>
                                            <th>{dayWiseData?.profitLoss}</th>
                                            {/* Add more table headers here */}
                                        </tr>

                                        </tfoot>
                                    </Table>

                                </div>
                            </Card.Body>
                        </Card>

                    </Row>
                )}

                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}

