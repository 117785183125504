import Footer from "../../layouts/Footer";
import {Row} from "react-bootstrap";
import {MainComponent} from "../../components/MainComponent";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";

const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};
export default function Test() {
        const data=[
        {
            "category": "2023-09-04",
            "value": 132885
        },
        {
            "category": "2023-09-05",
            "value": -93985
        },
        {
            "category": "2023-09-06",
            "value": 155287
        },
        {
            "category": "2023-09-07",
            "value": 50648
        },
        {
            "category": "2023-09-08",
            "value": 5040
        },
        {
            "category": "2023-09-09",
            "value": 200990
        },
        {
            "category": "2023-09-10",
            "value": 132360
        },
        {
            "category": "2023-09-11",
            "value": -103460
        },
        {
            "category": "2023-09-12",
            "value": 119975
        },
        {
            "category": "2023-09-13",
            "value": 52730
        },
        {
            "category": "2023-09-14",
            "value": 77200
        },
        {
            "category": "2023-09-16",
            "value": 219930
        },
        {
            "category": "2023-09-17",
            "value": 108660
        },
        {
            "category": "2023-09-18",
            "value": 47382
        },
        {
            "category": "2023-09-19",
            "value": 153678
        },
        {
            "category": "2023-09-20",
            "value": 30924
        }
    ];
    // Extract categories and values from data
    const categories = data.map(item => item.category);
    const values = data.map(item => item.value);

    const chartOptions = {
        chart: {
            type: 'area',
            height: 350,
        },
        xaxis: {
            categories: categories,
            labels: {
                rotate: -45,
                formatter: function (val) {
                    return formatDate(val);
                },
            },
        },
        yaxis: {
            title: {
                text: 'Rs',
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        series: [
            {
                name: 'Value',
                data: values,
            },
        ],
        title: {
            text: 'Area Chart Example',
        },
        markers: {
            size: 6, // Adjust the size of the markers
            strokeColors: '#fff', // Marker border color
            colors: '#229cff', // Marker fill color
            strokeWidth: 2, // Border width of the markers
            hover: {
                size: 8, // Marker size on hover
            },
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 100],
            },
        },

    };

    return (
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Test</li>
                            </ol>
                        </div>
                    </div>
                    <Row className="g-3 my-3">
                       <h2>testing</h2>
                    </Row>
                    <Row className="g-3 my-3">
                        <ReactApexChart options={chartOptions} series={chartOptions.series} type="area" height={350} />
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
    );
};
