import React from "react";
import Footer from "./../../../layouts/Footer";
import {Card, Col, Nav, Row,ProgressBar} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";

export default function InspectionAnalysis() {
    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };
    const seriesOne = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];

    const optionOne = {
        chart: {
            parentHeightOffset: 0, stacked: true, toolbar: {show: false}
        }, dataLabels: {enabled: false}, grid: {
            borderColor: 'rgba(72,94,144, 0.07)', padding: {
                top: -20, left: 5
            }
        }, states: states, colors: ['#506fd9', '#fff', '#c8ccd4'], plotOptions: {
            bar: {
                columnWidth: '35%'
            },
        }, stroke: {
            curve: 'straight', lineCap: 'square', width: 0
        }, tooltip: {enabled: false}, fill: {opacity: 1}, legend: {show: false}, xaxis: {
            type: 'numeric', tickAmount: 11, decimalsInFloat: 0, labels: {
                style: {
                    fontSize: '11px'
                }
            }
        }, yaxis: {
            max: 4000, tickAmount: 8, labels: {
                style: {
                    colors: ['#a2abb5'], fontSize: '11px'
                }
            }
        }
    };

    const seriesEight = [{
        type: 'column',
        data: [[0, 2], [1, 3], [2, 5], [3, 7], [4, 12], [5, 17], [6, 10], [7, 14], [8, 15], [9, 12], [10, 8]]
    }, {
        type: 'column',
        data: [[0, 12], [1, 7], [2, 4], [3, 5], [4, 8], [5, 10], [6, 4], [7, 7], [8, 11], [9, 9], [10, 5]]
    }];
    const optionEight = {
        chart: {
            parentHeightOffset: 0,
            stacked: true,
            type: 'line',
            toolbar: { show: false }
        },
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                left: 5,
                bottom: -15
            }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#506fd9', '#e5e9f2'],
        plotOptions: {
            bar: {
                columnWidth: '40%',
                endingShape: 'rounded'
            },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square',
            width: 0
        },
        yaxis: {
            min: 0,
            max: 30,
            tickAmount: 5
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#6e7985',
                    fontSize: '10px',
                    fontWeight: '500'
                }
            },
        },
        tooltip: { enabled: false },
        legend: { show: false }
    };

    const optionDonut = {
        chart: {
            parentHeightOffset: 0
        },
        colors: ['#506fd9', '#85b6ff', '#51596d', '#eff1f5'],
        dataLabels: { enabled: false },
        grid: {
            padding: {
                top: 0,
                bottom: 0
            }
        },
        legend: { show: false }
    };

    return (<React.Fragment>

        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">INSPECTION ANALYSIS</li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3">
                    <Card className="card-one card-product-inventory">
                        <Card.Header>
                            <Card.Title as="h6">Product Inventory</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ul className="legend mb-3 position-absolute">
                                <li>Remaining Quantity</li>
                                <li>Sold Quantity</li>
                            </ul>
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310}
                                            className="apex-chart-twelve mt-4 pt-3"/>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="mt-4">
                    <Col xl="8">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Expense Analytics</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-4">
                                <Row className="g-4">
                                    <Col md="6">
                                        <ReactApexChart series={seriesEight} options={optionEight} height={210} className="apex-chart-three" />
                                    </Col>
                                    <Col md="6">
                                        <div className="d-flex">
                                            <i className="ri-hotel-line fs-48 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-1">Fixed Expenses</h6>
                                                <p className="fs-sm text-secondary mb-0">Fixed expenses are generally fixed in both amount and interval.</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <i className="ri-wallet-3-line fs-48 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-1">Periodic Expenses</h6>
                                                <p className="fs-sm text-secondary mb-0">Periodic expenses are usually less frequent and the amount can vary.</p>
                                            </div>
                                        </div>
                                        <div className="d-flex mt-3">
                                            <i className="ri-shopping-bag-3-line fs-48 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-1">Variable Expenses</h6>
                                                <p className="fs-sm text-secondary mb-0">Change as the quantity of good or service that produces changes.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Billed Opportunities</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="position-relative d-flex justify-content-center">
                                <ReactApexChart series={[35, 20, 20, 15]} options={optionDonut} height="auto" type="donut" className="apex-donut-two" />

                                <div className="finance-donut-value">
                                    <h1>1.8B</h1>
                                    <p>86.24%</p>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card className="card-one">
                            <Card.Body className="p-3 p-xl-4">
                                <Row className="justify-content-center g-3 mb-2 mb-xl-4">
                                    <Col xs="6" sm="4" md>
                                        <div className="finance-item">
                                            <div className="finance-item-circle">
                                                <h1>4.7B</h1>
                                                <label>Total Income</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" sm="4" md>
                                        <div className="finance-item">
                                            <div className="finance-item-circle">
                                                <h1>60M</h1>
                                                <label>Total Expenses</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" sm="4" md>
                                        <div className="finance-item">
                                            <div className="finance-item-circle bg-gray-400">
                                                <h1>2.1B</h1>
                                                <label>Net Profit</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" sm="4" md>
                                        <div className="finance-item">
                                            <div className="finance-item-circle">
                                                <h1>18.2%</h1>
                                                <label>Quick Ratio</label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" sm="4" md>
                                        <div className="finance-item">
                                            <div className="finance-item-circle">
                                                <h1>6.8%</h1>
                                                <label>Current Ratio</label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="g-4 g-lg-5 pt-3">
                                    <Col sm="6" xl="3">
                                        <div className="d-flex">
                                            <i className="ri-wallet-2-line fs-32 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-2">Accounts Receivable</h6>
                                                <p className="fs-sm text-secondary mb-0">The proceeds or payment which the company will receive from its customers.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" xl="3">
                                        <div className="d-flex">
                                            <i className="ri-refund-2-line fs-32 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-2">Accounts Payable</h6>
                                                <p className="fs-sm text-secondary mb-0">Money owed by a business to its suppliers shown as a liability.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" xl="3">
                                        <div className="d-flex">
                                            <i className="ri-exchange-line fs-32 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-2">Quick Ratio</h6>
                                                <p className="fs-sm text-secondary mb-0">Measures the ability of a company to use its near cash or quick assets.</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="6" xl="3">
                                        <div className="d-flex">
                                            <i className="ri-exchange-dollar-line fs-32 lh-1 me-3"></i>
                                            <div>
                                                <h6 className="fw-semibold text-dark mb-2">Current Ratio</h6>
                                                <p className="fs-sm text-secondary mb-0">Measures whether a firm has enough resources to meet its short-term obligations.</p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}

