import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import DHUTable from "./DHUTable";
import {toast} from "react-toastify";
import {loginSelector} from "../../../store/login-slice";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";
import DHUForm from "./DHUForm";
import {addDHUInput, fetchDhuInputList, updateDHUInput} from "../../../services/input/dhu-input-service";

export default function DhuInput() {

    const {dhuId} = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const {token} = useSelector(loginSelector);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [resetForm, setResetForm] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllDHUInputList = async () => {
        setLoader(true);
        try {
            const result = await fetchDhuInputList(currentPage, itemsPerPage, token);


            if (result.status) {
                setItems(result.data.data.map(row => ({
                    ...row, action: (<>
                        <Link to={`/input-forms/dhu-input/${row.id}`}><i className="ri-edit-box-line"></i></Link> &nbsp;
                    </>)
                })));
                setCurrentPage(result.data.page_number);
                setCurrentPage(result.data.page_number);
                setTotalPages(result.data.total_records >= 0 ? (result.data.total_records / itemsPerPage) : 1)
            }
        } catch (err) {
            // console.log(err);
        }
        setLoader(false);

    };



    const submitHandler = async (data) => {
        setLoader(true)
        const {inputDate, unitId, styles} = data;
        try {
            const result=await addDHUInput(inputDate,unitId,styles,token);
            if (result.status){
                toast.success(result.message);
            }
            // addDHUInput
            setLoader(false);
            navigate("/input-forms/dhu-input");
            await getAllDHUInputList();
        } catch (err) {
            setLoader(false);
            console.log(err);
        }

    }

    const updateHandler = async (id, data) => {
        const {inputDate, unitId, styles} = data;

        try {
            const result = await updateDHUInput(id, inputDate, unitId, styles, token);
            if (result.status) {
                toast.success(result.message);
                navigate("/input-forms/dhu-input");
                await getAllDHUInputList();
            } else {
                toast.error(result.message);
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    }



    useEffect(() => {
        if (dhuId) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [dhuId]);

    useEffect(() => {
        getAllDHUInputList();
    }, [])

    useEffect(() => {
        setResetForm(!resetForm);
    }, [dhuId]);


    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">DHU Input</li>
                            </ol>
                            <h4 className="main-title mb-0">DHU Input</h4>
                        </div>
                    </div>

                    <Row className="g-3">
                        <DHUForm
                            dhuId={dhuId || 0}
                            isEditMode={isEditMode}
                            clearForm={resetForm}
                            submitHandler={(obj) => {
                                if (isEditMode) return updateHandler(dhuId || 0, obj);;
                                return submitHandler(obj);
                            }}
                            token={token}/>
                    </Row>
                    <Row className="g-3 mt-4">
                        <DHUTable items={items} currentPage={currentPage} totalPages={totalPages}
                                  currentPageHandle={currentPageHandle}/>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
