import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

export default function DeliveryKpis() {

    function capitalizeEveryWord(sentence) {
        return sentence.replace(/\b\w/g, c => c.toUpperCase());
    }
    function kpisButton(icon, color, name, data,route) {

        // return <Col sm={12} md={3} lg={12} xl={3} style={{height: 80}}>
        //     <Link to={route}>
        //         <Card className="shadow">
        //             <div className="d-flex">
        //                 <div className={`w-30 p-2 ${color} text-white d-flex justify-content-center align-items-center`}>
        //                     <i class={`${icon} larger-icon`}></i>
        //                 </div>
        //                 <div className={`w-70 p-2 ${isPositiveNumber(data)?"text-success":"text-danger"}`}>
        //                     <span className="text-nowrap">{name}</span> <br/> {data}
        //                 </div>
        //             </div>
        //         </Card>
        //     </Link>
        // </Col>;

        return <Col xs="6" xl="3">
            <Link to={route}>
                <Card className="card-one">
                    <Card.Body>
                        <Card.Title as="label" className="fs-sm fw-medium mb-1">{capitalizeEveryWord(name)}</Card.Title>
                        <h3 className="card-value mb-1"><i className={icon}></i> {data}</h3>
                        <small><span
                            className={"d-inline-flex text-" + (("up" === "up") ? "success" : "danger")}>10% <i
                            className={"ri-arrow-" + (("down" === "up") ? "up" : "down") + "-line"}></i></span> than
                            last week</small>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    }

    return (
        <>
            <Row className="g-3 mt-3">
                <h2 className="text-center">Delivery Kips</h2>
            </Row>
            <Row className="g-3 mb-5">
                {kpisButton("ri-briefcase-4-line", "bg-success", "One Time Performance", "44544554","delivery/one-time-performance")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Planned Vs Actual Delay", "70%","delivery/planned-vs-actual-delay")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Production Lead Time", "10/40","delivery/production-lead-time")}
                {kpisButton("ri-briefcase-4-line", "bg-info", "PCD Percentage", "40/60","delivery/pcd-percentage")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Fabric Delayes", "20-40","delivery/fabric-delayes")}
                {kpisButton("ri-briefcase-4-line", "bg-info", "Embd Procees Delays", "40","delivery/embd-procees-delays")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Finishing Rate", "5%","delivery/asking-rate-of-finishing")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Production", "5%","delivery/production")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Performance", "60%","delivery/performance")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Shipping Performance", "60%","delivery/shipping-performance")}
            </Row>
        </>
    );
};