/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showAlert: false,
  alertMessage: null,
  alertColor: null,
  alertDismissible: true,
};
// isLoggedIn: !!localStorage.getItem('token')

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert(state, action) {
      state.showAlert = action.payload;
    },
    alertMessage(state, action) {
      state.alertMessage = action.payload;
    },
    alertColor(state, action) {
      state.alertColor = action.payload;
    },
    alertDismissible(state, action) {
      state.alertDismissible = action.payload;
    },
  },
});

export const alertSelector = (state) => state.alert;

export const alertActions = alertSlice.actions;

export default alertSlice.reducer;
