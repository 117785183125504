import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {ieSchema} from "../../../schemas";
import Select from "react-select";
import {getIEInput} from "../../../services/input/ie-form-service";
import {fetchStyles} from "../../../services/master/style-services";

const initialValues = {style: "",plannedCost:"",cuttingCost:"",finishingCost:"",samValue:""};
const IEForm = ({isEditMode,clearForm, submitHandler, ieId, token}) => {
    const navigate=useNavigate();
    const [loader, setLoader] = useState(false);
    const [styles, setStyles] = useState([]);
    const [selectedStyle,setSelectedStyle]=useState({});

    const {values, touched, resetForm, setFieldValue, errors, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: ieSchema,
        onSubmit: ((values) => {
            submitHandler(values);
            resetForm();
        })
    })


    const fetchIeInputById = async (id) => {
        setLoader(true);
        try {
            const result = await getIEInput(id, token);
            if (result.status) {
                setFieldValue('style', result.data.style);
                setFieldValue('plannedCost',result.data.planned_cost);
                setFieldValue('cuttingCost',result.data.cutting_cost);
                setFieldValue('finishingCost',result.data.finishing_cost);
                setFieldValue('samValue',result.data.sam_value);

            } else {
                toast.error(result.message);
                navigate("/input-forms/ie-input");
            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }

   async function getStylesData() {
        setLoader(true)
        try {

            const result=await fetchStyles(token);
            if (result.status){
                // setStyles(result.data)
                setStyles(result.data.map(item => ({
                    value: item.id,
                    label: item.style_name
                })));
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }
    }


    useEffect(() => {
        if (isEditMode && ieId > 0) {
            fetchIeInputById(ieId);
        }
    }, [ieId, isEditMode]);


    useEffect(() => {
        if (styles.length>0){

            setSelectedStyle(styles.filter((style)=>style.value===values.style))
        }
    }, [values.style]);

    useEffect(() => {
        getStylesData();
    }, []);
    useEffect(() => {
        resetForm();
    },[clearForm])

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Text>{isEditMode ? "Update" : "Add"} IE Input</Card.Text>
                </Card.Header>
                <Card.Body className="p-4">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="style" className="col-form-label">Select Style</Form.Label>*/}
                                <h6 className="col-form-label">Select Style</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">

                                <Select name="style" options={styles} isSearchable={true} isClearable value={selectedStyle}
                                        className="is-invalid" onChange={(newValue) => {
                                    setSelectedStyle(newValue);
                                    setFieldValue('style', newValue.value);
                                }}/>

                                {errors.style && touched.style && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.style}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="plannedCost" className="col-form-label">Planned Cost</Form.Label>*/}
                                <h6 className="col-form-label">Planned Cost</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="text" name="plannedCost" value={values.plannedCost}
                                              onChange={(e)=>{
                                                  setFieldValue('plannedCost',e.target.value)
                                              }} placeholder="Planned Cost"
                                              isInvalid={!!errors.plannedCost && touched.plannedCost}/>
                                {errors.plannedCost && touched.plannedCost && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.plannedCost}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="samValue" className="col-form-label">Sam Value</Form.Label>*/}
                                <h6 className="col-form-label">Sam Value</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="text" name="samValue" id="samValue" value={values.samValue}
                                              onChange={(e)=>{
                                                  setFieldValue('samValue',e.target.value)
                                              }} placeholder="Sam Value"
                                              isInvalid={!!errors.samValue && touched.samValue}/>
                                {errors.samValue && touched.samValue && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.samValue}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="cuttingCost" className="col-form-label">Cutting Cost</Form.Label>*/}
                                <h6 className="col-form-label">Cutting Cost</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">

                                <Form.Control type="text" name="cuttingCost" value={values.cuttingCost}
                                              onChange={(e)=>{
                                                  setFieldValue('cuttingCost',e.target.value)
                                              }} placeholder="Cutting Cost"
                                              isInvalid={!!errors.cuttingCost && touched.cuttingCost}/>
                                {errors.cuttingCost && touched.cuttingCost && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.cuttingCost}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={1} className="mt-2">
                                {/*<Form.Label htmlFor="finishingCost" className="col-form-label">Finishing Cost</Form.Label>*/}
                                <h6 className="col-form-label">Finishing Cost</h6>
                            </Col>
                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="text" name="finishingCost" value={values.finishingCost}
                                              onChange={(e)=>{
                                                  setFieldValue('finishingCost',e.target.value)
                                              }} placeholder="finishing Cost"
                                              isInvalid={!!errors.finishingCost && touched.finishingCost}/>
                                {errors.finishingCost && touched.finishingCost && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.finishingCost}
                                    </Form.Control.Feedback>
                                )}
                            </Col>

                            <Col xs={12} md={5}  className="mt-2 d-flex align-items-center">
                                <Button type="submit" variant="primary" className="btn-sign">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};
export default IEForm;
