import React from "react";
//SmartFactory
// const Roles = React.lazy(() => import("../pages/roles"));
// const Users = React.lazy(() => import("../pages/users"))

import Roles from "../pages/roles";
import Users from "../pages/users"
import Logout from "../pages/users/Logout"

//Cost
import EFF from "../pages/cost/EFF"
import Productivity from "../pages/cost/productivity"
import CuttingProfitLoss from "../pages/cost/cutting-profit-loss"
import PlannedVsActualCost from "../pages/cost/finishing-profit-loss"
import CutToShipVariaion from "../pages/cost/cut-to-ship-variaion"
import CostPerMinute from "../pages/cost/sewing-profit-loss"
import SamProduce from "../pages/cost/sam-produce"
import CostPerMin from "../pages/cost/cost-per-min"
import CapacityUtilization from "../pages/cost/capacity-utilization"
import ManagmnetReview from "../pages/cost/managmnet-review"
import EnergyCost from "../pages/cost/energy-cost"


//Quality
import DHU from "../pages/quality/DHU"
import Intimation from "../pages/quality/intimation"
import Claims from "../pages/quality/claims"

//Delivery

import OneTimePerformance from "../pages/delivery/one-time-performance";
import InspectionAnalysis from "../pages/quality/inspection-analysis";
import TopThreeDeffects from "../pages/quality/top-three-deffects";
import BadPerformanceLine from "../pages/quality/bad-performance-line";
import StyleWiseDeffect from "../pages/quality/style-wise-deffect";
import LineWiseDeffect from "../pages/quality/line-wise-deffect";
import SupervisorWieDeffect from "../pages/quality/supervisor-wie-deffect";
import ProcessWiseDeffect from "../pages/quality/process-wise-deffect";
import PlannedVsActualDelay from "../pages/delivery/planned-vs-actual-delay";
import ProductionLeadTime from "../pages/delivery/production-lead-time";
import PcdPercentage from "../pages/delivery/pcd-percentage";
import FabricDelayes from "../pages/delivery/fabric-delayes";
import EmbdProceesDelays from "../pages/delivery/embd-procees-delays";
import AskingRateOfFinishing from "../pages/delivery/asking-rate-of-finishing";
import Production from "../pages/delivery/production";
import Performance from "../pages/delivery/performance";
import ShippingPerformance from "../pages/delivery/shipping-performance";
import Absenteeism from "../pages/hr/absenteeism";
import Mmr from "../pages/hr/mmr";
import MaleFelameRatio from "../pages/hr/male-felame-ratio";
import StrengthDepartmentWise from "../pages/hr/strength-department-wise";
import Attrition from "../pages/hr/attrition";
import Dashboard from "../pages/dashboard";
//input forms
import HrInput from "../pages/input-forms/hr-form";
import IeForm from "../pages/input-forms/ie-form";
//Man Power Allocation
import ManPowerAllotment from "../pages/input-forms/manpower-allotment";
import StyleWiseManPowerAllocation from "../pages/input-forms/manpower-allotment/style-wise-man-power-allocation"

//DHU Input
import DhuInput from "../pages/input-forms/dhu-form";

//master forms
import Floor from "../pages/master/floor";
import Line from "../pages/master/line";
import MachineType from "../pages/master/machine-type";
import Operation from "../pages/master/operation";
import SamRange from "../pages/master/SameRange";
import MenuConfiguration from "../pages/menu-configruation";
//Testing
import Test from "../pages/test"
import Units from "../pages/master/units";
import WorkingHours from "../pages/master/working-hours";




const protectedRoutes = [
  { path: "dashboard", element:<Dashboard/> },
  { path: "roles", element:<Roles/> },
  { path: "roles/:roleId", element:<Roles/> },
  { path: "users", element:<Users/> },
  { path: "users/:userId", element:<Users/> },
  { path: "logout", element:<Logout/> },
    //Cost Kpis Route
  { path: "cost/eff", element:<EFF/> },
  { path: "cost/productivity", element:<Productivity/>},
  { path: "cost/cutting-profit-loss", element:<CuttingProfitLoss/>},
  { path: "cost/finishing-profit-loss", element:<PlannedVsActualCost/>},
  { path: "cost/cut-to-ship-variaion", element:<CutToShipVariaion/>},
  { path: "cost/sewing-profit-loss", element:<CostPerMinute/>},
  { path: "cost/sam-produce", element:<SamProduce/>},
  { path: "cost/energy-cost", element:<EnergyCost/>},
  { path: "cost/cost-per-min", element:<CostPerMin/>},
  { path: "cost/capacity-utilization", element:<CapacityUtilization/>},
  { path: "cost/managmnet-review", element:<ManagmnetReview/>},
    //Quality Kpis Route
  { path: "quality/intimation", element:<Intimation/> },
  { path: "quality/claims", element:<Claims/> },
  { path: "quality/dhu", element:<DHU/> },
  { path: "quality/inspection-analysis", element:<InspectionAnalysis/> },
  { path: "quality/top-three-deffects", element:<TopThreeDeffects/> },
  { path: "quality/bad-performance-line", element:<BadPerformanceLine/> },
  { path: "quality/style-wise-deffect", element:<StyleWiseDeffect/> },
  { path: "quality/line-wise-deffect", element:<LineWiseDeffect/> },
  { path: "quality/supervisor-wie-deffect", element:<SupervisorWieDeffect/> },
  { path: "quality/process-wise-deffect", element:<ProcessWiseDeffect/> },
  { path: "quality/process-wise-deffect", element:<ProcessWiseDeffect/> },
    //Delivery Routes
  { path: "delivery/one-time-performance", element:<OneTimePerformance/> },
  { path: "delivery/planned-vs-actual-delay", element:<PlannedVsActualDelay/> },
  { path: "delivery/production-lead-time", element:<ProductionLeadTime/> },
  { path: "delivery/pcd-percentage", element:<PcdPercentage/> },
  { path: "delivery/fabric-delayes", element:<FabricDelayes/> },
  { path: "delivery/embd-procees-delays", element:<EmbdProceesDelays/> },
  { path: "delivery/asking-rate-of-finishing", element:<AskingRateOfFinishing/> },
  { path: "delivery/production", element:<Production/> },
  { path: "delivery/performance", element:<Performance/> },
  { path: "delivery/shipping-performance", element:<ShippingPerformance/> },
    //Hr Routes
  { path: "hr/attrition", element:<Attrition/> },
  { path: "hr/absenteeism", element:<Absenteeism/> },
  { path: "hr/mmr", element:<Mmr/> },
  { path: "hr/male-felame-ratio", element:<MaleFelameRatio/> },
  { path: "hr/male-felame-ratio", element:<MaleFelameRatio/> },
  { path: "hr/strength-department-wise", element:<StrengthDepartmentWise/> },
    /*****Input Form*****/
    // Hr Input
  { path: "input-forms/hr-input", element:<HrInput/> },
  { path: "input-forms/hr-input/:hrInputId", element:<HrInput/> },
    //IE Form
  { path: "input-forms/ie-input", element:<IeForm/> },
  { path: "input-forms/ie-input/:ieId", element:<IeForm/> },
  //ManPowerAllocation
  { path: "input-forms/manpower-allotment", element:<ManPowerAllotment/> },
  { path: "input-forms/manpower-allotment/:manPowerAllotmentId", element:<ManPowerAllotment/> },
  { path: "input-forms/style-wise-manpower-allocation", element:<StyleWiseManPowerAllocation/> },
  //DHU Form
  { path: "input-forms/dhu-input", element:<DhuInput/> },
  { path: "input-forms/dhu-input/:dhuId", element:<DhuInput/> },

    /***Master Form***/
    //Units
  { path: "master/units", element:<Units/> },
    //Floors
  { path: "master/working-hours", element:<WorkingHours/> },
  { path: "master/working-hours/:unitId", element:<WorkingHours/> },
  //Floors
  { path: "master/floors", element:<Floor/> },
  { path: "master/floors/:floorId", element:<Floor/> },
    //Lines
  { path: "master/lines", element:<Line/> },
  { path: "master/lines/:lineId", element:<Line/> },
    //
  { path: "master/machine-type", element:<MachineType/> },
  { path: "master/operation", element:<Operation/> },
  { path: "master/sam-range", element:<SamRange/> },
    //Profile and configuration
  { path: "config/menu-config", element:<MenuConfiguration/>},
    //testing
  { path: "test", element:<Test/>},


]


export default protectedRoutes;
