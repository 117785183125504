import React from "react";
import Footer from "./../../../layouts/Footer";
import {Card, Col, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";
import Avatar from "../../../components/Avatar";
import img6 from "../../../assets/img/img6.jpg";
import img7 from "../../../assets/img/img7.jpg";
import img8 from "../../../assets/img/img8.jpg";
import img9 from "../../../assets/img/img9.jpg";
import img10 from "../../../assets/img/img10.jpg";
import {Bar} from "react-chartjs-2";


export default function ProductionLeadTime() {
    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const seriesOne = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];
    const optionOne = {
        chart: {
            parentHeightOffset: 0, stacked: true, toolbar: {show: false}
        }, dataLabels: {enabled: false}, grid: {
            borderColor: 'rgba(72,94,144, 0.07)', padding: {
                top: -20, left: 5
            }
        }, states: states, colors: ['#506fd9', '#fff', '#c8ccd4'], plotOptions: {
            bar: {
                columnWidth: '35%'
            },
        }, stroke: {
            curve: 'straight', lineCap: 'square', width: 0
        }, tooltip: {enabled: false}, fill: {opacity: 1}, legend: {show: false}, xaxis: {
            type: 'numeric', tickAmount: 11, decimalsInFloat: 0, labels: {
                style: {
                    fontSize: '11px'
                }
            }
        }, yaxis: {
            max: 4000, tickAmount: 8, labels: {
                style: {
                    colors: ['#a2abb5'], fontSize: '11px'
                }
            }
        }
    };

    const chartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
            data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
            backgroundColor: '#506fd9',
            barPercentage: 0.5
        }, {
            data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
            backgroundColor: '#85b6ff',
            barPercentage: 0.5
        }]
    };

    const chartOption = {
        indexAxis: 'y',
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                beginAtZero: true,
                max: 100,
                grid: {
                    borderColor: '#000',
                    color: '#f3f5f9'
                },
                ticks: {
                    color: '#212830',
                    font: {
                        size: 10,
                        weight: '500'
                    }
                }
            },
            y: {
                grid: {
                    borderWidth: 0,
                    color: '#f3f5f9'
                },
                ticks: {
                    color: '#212830',
                    font: {
                        size: 12
                    }
                }
            }
        }
    };


    return (<React.Fragment>
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Producation Lead Time</li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3">
                    <Card className="card-one card-product-inventory">
                        <Card.Header>
                            <Card.Title as="h6">Product Inventory</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ul className="legend mb-3 position-absolute">
                                <li>Remaining Quantity</li>
                                <li>Sold Quantity</li>
                            </ul>
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310}
                                            className="apex-chart-twelve mt-4 pt-3"/>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="mt-4">

                    <Col md="6" xl="4">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Recent Sellers</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href="" className="nav-link"><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href="" className="nav-link"><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <ul className="people-group">
                                    {[
                                        {
                                            "avatar": img6,
                                            "name": "Allan Rey Palban",
                                            "id": "00222"
                                        }, {
                                            "avatar": img7,
                                            "name": "Adrian Moniño",
                                            "id": "00221"
                                        }, {
                                            "avatar": img8,
                                            "name": "Charlene Plateros",
                                            "id": "00220"
                                        }, {
                                            "avatar": img9,
                                            "name": "Analyn Mercado",
                                            "id": "00219"
                                        }, {
                                            "avatar": img10,
                                            "name": "Rolando Paloso",
                                            "id": "00218"
                                        }
                                    ].map((user, index) => (
                                        <li className="people-item" key={index}>
                                            <Avatar img={user.avatar} />
                                            <div className="people-body">
                                                <h6><Link to="">{user.name}</Link></h6>
                                                <span>Customer ID#{user.id}</span>
                                            </div>
                                            <Nav as="nav" className="nav-icon">
                                                <Nav.Link href=""><i className="ri-user-star-line"></i></Nav.Link>
                                                <Nav.Link href=""><i className="ri-contacts-line"></i></Nav.Link>
                                            </Nav>
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-center">
                                <Link href="" className="fs-sm">Manage Customers</Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md="6" xl="4">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Transaction History</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <ul className="people-group">
                                    {[
                                        {
                                            "bg": "teal",
                                            "icon": "ri-shopping-cart-line",
                                            "label": "Purchase from #10322",
                                            "date": "Oct 21, 2023, 3:30pm",
                                            "value": "+ $250.00",
                                            "status": "Completed",
                                            "color": "success"
                                        }, {
                                            "bg": "info",
                                            "icon": "ri-coins-line",
                                            "label": "Process refund to #00910",
                                            "date": "Oct 19, 2023, 3:30pm",
                                            "value": "- $16.50",
                                            "status": "Processing",
                                            "color": "warning"
                                        }, {
                                            "bg": "primary",
                                            "icon": "ri-truck-line",
                                            "label": "Process delivery to #44333",
                                            "date": "Oct 18, 2023, 6:18pm",
                                            "value": "3 Items",
                                            "status": "For pickup",
                                            "color": "info"
                                        }, {
                                            "bg": "pink",
                                            "icon": "ri-truck-line",
                                            "label": "Payment from #023328",
                                            "date": "Oct 18, 2023, 12:40pm",
                                            "value": "+ $129.50",
                                            "status": "Completed",
                                            "color": "success"
                                        }, {
                                            "bg": "secondary",
                                            "icon": "ri-secure-payment-line",
                                            "label": "Payment failed #087651",
                                            "date": "Oct 15, 2023, 08:09am",
                                            "value": "$150.20",
                                            "status": "Declined",
                                            "color": "danger"
                                        }
                                    ].map((item, index) => (
                                        <li className="people-item" key={index}>
                                            <div className="avatar">
                        <span className={"avatar-initial fs-20 bg-" + item.bg}>
                          <i className={item.icon}></i>
                        </span>
                                            </div>
                                            <div className="people-body">
                                                <h6><Link to="">{item.label}</Link></h6>
                                                <span>{item.date}</span>
                                            </div>
                                            <div className="text-end">
                                                <div className="fs-sm">{item.value}</div>
                                                <span className={"d-block fs-xs text-" + item.color}>{item.status}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-center">
                                <Link to="" className="fs-sm">Manage Transactions</Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}


