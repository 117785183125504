import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {fetchAllPermission, getRole} from "../../services/roles-permissions-services";
import {useFormik} from "formik";
import {rolesSchema} from "../../schemas";
import Select from "react-select";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";

const Plants = [{label: "New Zeel Rainwear", value: 1}];
const Lines = [
    {"label": "Line 1", "value": 1, "plant_id": 1},
    {"label": "Line 2", "value": 2, "plant_id": 2},
    {"label": "Line 3", "value": 3, "plant_id": 1},
    {"label": "Line 4", "value": 4, " plant_id": 2},
];

const initialValues = {roleName: "", permissions: [], plants: [], lines: []};
const RoleForm = ({isEditMode,clearForm, submitHandler, roleId, token}) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [permissions, setPermissions] = useState([]);

    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [selectedLines, setSelectedLines] = useState([]);
    const {values, touched, resetForm, setFieldValue, errors, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues, validationSchema: rolesSchema, onSubmit: ((values) => {
            submitHandler(values);
            resetForm();
            setSelectedPermissions([]);
            setSelectedPlant([]);
            setSelectedLines([]);
        })
    })
    const getAllPermissions = async () => {
        setLoader(false);
        try {
            const result = await fetchAllPermission(token);
            if (result.status) {
                setPermissions(result.data);
            }
        } catch (e) {
            console.log(e);
        }
        setLoader(false);
    }

    const handleCheckboxChange = (event) => {
        const {value, checked} = event.target;
        if (checked) {
            if (value === "All") {
                const allPermissionsExceptAdmin = permissions.filter(item => item !== "Admin");
                setSelectedPermissions([...selectedPermissions, ...allPermissionsExceptAdmin]);
            } else if (value === "Admin") {
                setSelectedPermissions(permissions);
            } else {
                setSelectedPermissions([...selectedPermissions, value]);
            }
        } else {
            if (value === "All") {
                const allPermissionsExceptAdmin = permissions.filter(item => item !== "Admin");
                // let filteredArray = firstArray.filter(item => !secondArray.includes(item));

                setSelectedPermissions(prevState => prevState.filter(item => !allPermissionsExceptAdmin.includes(item)));
            } else if (value === "Admin") {
                setSelectedPermissions([]);
            } else {
                setSelectedPermissions(selectedPermissions.filter((item) => item !== value));
            }
        }
    };

    const getRoleById = async (id) => {
        setLoader(true);
        try {
            const result = await getRole(id, token);
            if (result.status) {
                setFieldValue('roleName', result.data.name);
                setFieldValue('permissions', result.data.permission);
                setFieldValue('plants', result.data.plants);
                setFieldValue('lines', result.data.lines);
                setSelectedPlant(Plants.filter((plant) =>
                    result.data.plants.includes(plant.value)
                ));
                setSelectedLines(Lines.filter(line =>
                    result.data.lines.some(filter =>
                        line.plant_id === filter.plantId && line.value === filter.lineId
                    )
                ));
                setSelectedPermissions(result.data.permission);
            } else {
                toast.error(result.message);
                navigate("/roles");

            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }

    useEffect(() => {
        setFieldValue('permissions', selectedPermissions);
    }, [selectedPermissions])

    useEffect(() => {
        getAllPermissions();
    }, [])

    useEffect(() => {
        if (isEditMode && roleId > 0) {
            getRoleById(roleId);
        }
    }, [roleId, isEditMode]);

    useEffect(() => {
        if (selectedPlant && selectedPlant.length > 0) {
            const plants = selectedPlant.map((element) => element.value);
            setFieldValue('plants', plants)
        }
    }, [selectedPlant]);

    useEffect(() => {
        if (selectedLines && selectedLines.length > 0) {
            const lines = selectedLines.map((element) => {
                return {lineId: element.value, plantId: element.plant_id}
            });
            setFieldValue('lines', lines);
        }
    }, [selectedLines]);

    useEffect(()=>{
        resetForm();
        setSelectedPermissions([]);
        setSelectedPlant([]);
        setSelectedLines([]);
    },[clearForm]);

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>{isEditMode ? "Update" : "Create"} Role</Card.Title>
                    {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                </Card.Header>
                <Card.Body className="p-4">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={2}>
                                {/*<Form.Label htmlFor="roleName" className="col-form-label">Role Name</Form.Label>*/}
                                <h6 className="col-form-label">Role Name</h6>
                            </Col>
                            <Col xs={12} md={4}  mr={5}>
                                <Form.Control type="text" name="roleName" id="roleName" value={values.roleName}
                                              onChange={handleChange} placeholder="Role Name"
                                              isInvalid={!!errors.roleName && touched.roleName}/>

                                {errors.roleName && touched.roleName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.roleName}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={2}>
                                {/*<Form.Label htmlFor="roleName" className="col-form-label">Select Permissions</Form.Label>*/}
                                <h6 className="col-form-label">Select Permissions</h6>
                            </Col>
                            <Col xs={12} md={4} mr={5}>
                                <Row>
                                    {permissions.length > 0 && permissions.map((item) => (
                                        <Col key={item} xs={12} md={4}>
                                            <Form.Check
                                                key={item}
                                                type="checkbox"
                                                id={item}
                                                label={item}
                                                value={item}
                                                checked={selectedPermissions.includes(item)}
                                                onChange={handleCheckboxChange}
                                                isInvalid={!!errors.permissions && touched.permissions}
                                            /></Col>
                                    ))}
                                </Row>
                                {errors.roleName && touched.roleName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.permissions}
                                    </Form.Control.Feedback>)}
                            </Col>

                            <Col xs={12} md={2}  className="mt-4">
                                {/*<Form.Label htmlFor="plants" className="col-form-label">Select Plant</Form.Label>*/}
                                <h6 className="col-form-label">Select Plant</h6>
                            </Col>

                            <Col xs={12} md={4}  mr={5} className="mt-4" >
                                <Select name="plants" isMulti="true" options={Plants} isSearchable={true} isClearable
                                        value={selectedPlant}
                                        className="is-invalid" onChange={(newValue) => {
                                    setSelectedPlant(newValue);
                                    if (newValue.length === 0) {
                                        setFieldValue('plants', []);
                                    }
                                    // setFieldValue('role', newValue.label);
                                }}/>

                                {errors.plants && touched.plants && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.plants}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={2}  className="mt-4">
                                {/*<Form.Label htmlFor="lines" className="col-form-label">Select Line</Form.Label>*/}
                                <h6 className="col-form-label">Select Line</h6>
                            </Col>
                            <Col xs={12} md={4}  mr={5} className="mt-4">
                                <Select name="lines" options={Lines}
                                        isMulti="true" isSearchable={true} isClearable value={selectedLines}
                                        className="is-invalid"
                                        onChange={(newValue) => {
                                            setSelectedLines(newValue);
                                            if (newValue.length === 0) {
                                                setFieldValue('lines', []);
                                            }
                                        }}/>

                                {errors.lines && touched.lines && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.lines}
                                    </Form.Control.Feedback>
                                )}
                            </Col>
                            <Col xs={12} md={3} className="mt-4 pt-4">
                                <Button type="submit" variant="primary" className="btn-sign">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>


        </>
    );
};
export default RoleForm;
