import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchDateWiseDHUData} from "../../../services/input/dhu-input-service";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";

export default function QualityKpis({fromDate,toDate,location}) {
    const {token}=useSelector(loginSelector);
    const [loader,setLoader]=useState(false);
    const [dhu,setDhu]=useState("");

    function capitalizeEveryWord(sentence) {
        return sentence.replace(/\b\w/g, c => c.toUpperCase());
    }
    async function getDateWiseDHUData() {
        setLoader(true);
        try {
            const result = await fetchDateWiseDHUData(fromDate, toDate,location, token);
            if (result.status) {
                console.log(result.data,"result data")
                let totalOutput = 0;
                let defectPcs = 0;
                result.data.forEach(item => {
                    totalOutput += item.total_output??0;
                    defectPcs += item.total_defect??0;
                });

                setDhu(`${defectPcs}/${totalOutput}`);

            } else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }
    function kpisButton(icon, color, name, data,route) {

        // return <Col sm={12} md={3} lg={12} xl={3} style={{height: 80}}>
        //     <Link to={route}>
        //         <Card className="shadow">
        //             <div className="d-flex">
        //                 <div className={`w-30 p-2 ${color} text-white d-flex justify-content-center align-items-center`}>
        //                     <i class={`${icon} larger-icon`}></i>
        //                 </div>
        //                 <div className={`w-70 p-2 ${isPositiveNumber(data)?"text-success":"text-danger"}`}>
        //                     <span className="text-nowrap">{name}</span> <br/> {data}
        //                 </div>
        //             </div>
        //         </Card>
        //     </Link>
        // </Col>;

        return <Col xs="6" xl="3">
            <Link to={route}>
                <Card className="card-one">
                    <Card.Body>
                        <Card.Title as="label" className="fs-sm fw-medium mb-1">{capitalizeEveryWord(name)}</Card.Title>
                        <h3 className="card-value mb-1"><i className={icon}></i> {data}</h3>
                        <small><span
                            className={"d-inline-flex text-" + (("up" === "up") ? "success" : "danger")}>10% <i
                            className={"ri-arrow-" + (("down" === "up") ? "up" : "down") + "-line"}></i></span> than
                            last week</small>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    }

    useEffect(() => {
        if (fromDate && toDate && location){
            getDateWiseDHUData();
        }
    }, [fromDate,toDate,location]);

    return (
        <>
            <Row className="g-3">
                <h2 className="text-center">Quality Kips</h2>
            </Row>
            <Row className="g-3 mt-2">
                {kpisButton("ri-briefcase-4-line", "bg-success", "Intimation", "44544554","quality/intimation")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Claims", "44544554","quality/claims")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "D.H.U", dhu,"quality/dhu")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Inspection Analysis", "70%","quality/inspection-analysis")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Top Three Deffects", "10","quality/top-three-deffects")}
                {kpisButton("ri-briefcase-4-line", "bg-info", "MALE FELAME RATIO", "40/60","")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Bad Performance Line", "40","bad-performance-line")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Style Wise Deffect", "40","quality/style-wise-deffect")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Line Wise Deffect", "40","quality/line-wise-deffect")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Supervisor Wise Deffect", "40","quality/supervisor-wie-deffect")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Process Wise Deffect", "40","quality/process-wise-deffect")}
            </Row>
        </>
    );
};