import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {fetchDateWiseData} from "../../../services/cost/cutting-profit-loss-service";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import {fetchDateWiseSewingData} from "../../../services/cost/sewing-profit-loss-service";
import {toast} from "react-toastify";
import {fetchDateWiseFinishingData} from "../../../services/cost/finishing-profit-loss-service";

export default function CostKpis({fromDate,toDate,location}) {
    const [loader, setLoader] = useState(false);
    const {token} = useSelector(loginSelector);
    const [cuttingProfitLoss, setCuttingProfitLoss] = useState("");
    const [sewingProfitLoss,setSewingProfitLoss]=useState("");
    const [finishingProfitLoss,setFinishingProfitLoss]=useState("");
    // const {fromDate, toDate} = useSelector(checkDateSelector);


    async function getCuttingData() {
        setLoader(true);
        try {
            const result = await fetchDateWiseData(fromDate, toDate,location, token);
            if (result.status) {
                let profitLoss = 0;

                result.data.forEach(item => {
                    profitLoss += item.profit_loss??0;
                });

                setCuttingProfitLoss(profitLoss)

            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }

    async function getSewingDataDateWise() {
        setLoader(true);
        try {
            const result = await fetchDateWiseSewingData(fromDate, toDate,location, token);
            if (result.status) {
                let totalProfitLoss = 0;
                result.data.forEach(item => {
                    totalProfitLoss += item.profit_loss??0;
                });
                setSewingProfitLoss(totalProfitLoss.toFixed(2))

            }else {
                toast.error(result.message)
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }
    async function getFinishingData() {
        setLoader(true);
        try {
            const result = await fetchDateWiseFinishingData(fromDate, toDate,location, token);
            if (result.status) {
                let totalProfitLoss = 0;
                result.data.forEach(item => {
                    totalProfitLoss += item.profit_loss??0;
                });
                setFinishingProfitLoss(totalProfitLoss)


            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }

    }



    useEffect(() => {
        if (fromDate && toDate && location){
            getCuttingData();
            getSewingDataDateWise();
            getFinishingData();
        }
    }, [fromDate,toDate,location]);
    function capitalizeEveryWord(sentence) {
        return sentence.replace(/\b\w/g, c => c.toUpperCase());
    }
    function kpisButton(icon, color, name, data, route) {

        // return <Col sm={12} md={3} lg={12} xl={3} style={{height: 80}}>
        //     <Link to={route}>
        //         <Card className="shadow">
        //             <div className="d-flex">
        //                 <div className={`w-30 p-2 ${color} text-white d-flex justify-content-center align-items-center`}>
        //                     <i class={`${icon} larger-icon`}></i>
        //                 </div>
        //                 <div className={`w-70 p-2 ${isPositiveNumber(data)?"text-success":"text-danger"}`}>
        //                     <span className="text-nowrap">{name}</span> <br/> {data}
        //                 </div>
        //             </div>
        //         </Card>
        //     </Link>
        // </Col>;
        // return     <Col xs="6" xl="3">
        //     <Link to={route}>
        //     <Card className="card-one">
        //         <Card.Body >
        //             <Card.Title as="label" className="fs-sm fw-medium mb-1">{name}</Card.Title>
        //             <h3 className={isPositiveNumber(data)?"card-value mb-1 text-success":"card-value mb-1 text-danger"} >   <i class={`${icon} larger-icon`}></i> {data}</h3>
        //             {/*<small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> than last week</small>*/}
        //         </Card.Body>
        //     </Card>
        //     </Link>
        // </Col>

        return <Col xs="6" xl="3">
            <Link to={route}>
                <Card className="card-one">
                    <Card.Body>
                        <Card.Title as="label" className="fs-sm fw-medium mb-1">{capitalizeEveryWord(name)}</Card.Title>
                        <h3 className="card-value mb-1"><i className={icon}></i> {data}</h3>
                        <small><span
                            className={"d-inline-flex text-" + (("up" === "up") ? "success" : "danger")}>10% <i
                            className={"ri-arrow-" + (("down" === "up") ? "up" : "down") + "-line"}></i></span> than
                            last week</small>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    }

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Row className="g-3">
                <h2 className="text-center">Cost Kips</h2>
            </Row>

            <Row className="g-3 mb-3">
                {kpisButton("ri-scissors-line", "bg-success", "Cutting Profit Loss", cuttingProfitLoss, "cost/cutting-profit-loss")}
                {kpisButton("ri-shirt-line", "bg-info", "Sewing Profit Loss", sewingProfitLoss, "cost/sewing-profit-loss")}
                {/*{kpisButton("ri-briefcase-4-line", "bg-info", "Sewing Profit Loss", "-40000", "#")}*/}
                {kpisButton("ri-t-shirt-air-line", "bg-info", "Finishing Profit Loss", finishingProfitLoss, "cost/finishing-profit-loss")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "cut to ship variation", "20-40", "cost/cut-to-ship-variaion")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Sam Produce", "5%", "cost/sam-produce")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "eff", "10%", "cost/eff")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "Productivity", "70%", "cost/productivity")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Energy Cost", "60%", "cost/energy-cost")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Cost Per Min", "60%", "cost/cost-per-min")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Capacity Utilization", "60%", "cost/capacity-utilization")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "Managmnet Review", "60%", "cost/managmnet-review")}
            </Row>
        </>
    );
};