import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const createIeInput = async (style, plannedCost, samValue, cuttingCost, finishingCost, token) => {
    const data = JSON.stringify({
        style: style,
        planned_cost: plannedCost,
        sam_value: samValue,
        cutting_cost: cuttingCost,
        finishing_cost: finishingCost,
    });
    const res = await axios.post(`${BASE_URL}/create-ie-input`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchIEInputList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-ie-input-list`, data, config);
    return res.data;
};


export const getIEInput = async (ieId, token) => {
    const res = await axios.get(`${BASE_URL}/get-ie-input/${ieId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const updateIeInput = async (
    ieId,
    style, plannedCost, samValue, cuttingCost, finishingCost, token
) => {

    const data = JSON.stringify({
        id: ieId,
        style: style,
        planned_cost: plannedCost,
        sam_value: samValue,
        cutting_cost: cuttingCost,
        finishing_cost: finishingCost,
    });


    const res = await axios.post(`${BASE_URL}/update-ie-input`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const deleteIeInput = async (ieId, token) => {
    const res = await axios.delete(`${BASE_URL}/delete-ie-input/${ieId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


