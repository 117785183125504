import React, {useState} from "react";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";
import ManPowerAllotmentForm from "./ManPowerAllotmentForm";

export default function ManPowerAllotment() {
    const [loader, setLoader] = useState(false);

    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Man Power Allotment</li>
                            </ol>
                            <h4 className="main-title mb-0">Man Power Allotment</h4>
                        </div>
                    </div>

                    <Row className="g-3">
                        <ManPowerAllotmentForm/>
                    </Row>
                    <Row className="g-3">
                     <Link to="/input-forms/style-wise-manpower-allocation">Style Wise ManPower </Link>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
