import React from 'react';
import {Table, Pagination, Card} from 'react-bootstrap';

const HrTable = ({items, currentPage, totalPages, currentPageHandle}) => {
    const departmentType = [
        { label: "Cutting", value: 1 },
        { label: "Sewing", value: 2 },
        { label: "Dispatch", value: 3 }
    ];

    function findLabelByValue(value) {
        for (const department of departmentType) {
            if (department.value === value) {
                return department.label;
            }
        }
        return null; // Return null if no match is found
    }
    function handlePageChange(page) {
        currentPageHandle(page);
    }

    return (<>
                <Card className="card-settings mt-4">
                    <Card.Header>
                        <Card.Title>HR Input List</Card.Title>
                        {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                    </Card.Header>
                    <Card.Body className="p-4">
                    <div>
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Date</th>
                                <th>Unit</th>
                                <th>Floor</th>
                                <th>Department</th>
                                <th>Actions</th>
                                {/* Add more table headers here */}
                            </tr>

                            </thead>
                            <tbody>
                            {items.length > 0 && items.map((item,index) => (<tr key={item.id}>
                                <td>{currentPage===1?index+1:`${currentPage}${index+1}`}</td>
                                    <td>{item.production_date}</td>
                                    <td>{item.unit_name}</td>
                                    <td>{item.floor_name}</td>
                                    <td>{findLabelByValue(item.department)}</td>
                                    <td>{item.action}</td>
                                </tr>))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            />
                            {Array.from({length: totalPages}, (_, index) => (<Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>))}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            />
                        </Pagination>
                    </div>
                </Card.Body>
            </Card>
        </>);
};

export default HrTable;
