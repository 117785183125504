import React, {useEffect, useState} from "react";
import Footer from "../../../layouts/Footer";
import {Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/login-slice";
import WorkingHoursTable from "./WorkingHoursTable";
import UserForm from "./WorkingHoursForm";
import {createUser, deleteUser, fetchUserList, updateUser} from "../../../services/user-services";
import {toast} from "react-toastify";
import {MainComponent} from "../../../components/MainComponent";
import {fetchUnits, updateUnit} from "../../../services/master/unit-service";

export default function WorkingHours() {

    const {unitId} = useParams();
    const [isEditMode, setIsEditMode] = useState(false);
    const {token} = useSelector(loginSelector);
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [resetForm, setResetForm] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllUnitsList = async () => {
        setLoader(true);
        try {
            const result = await fetchUnits(currentPage, itemsPerPage, token);
            if (result.status) {
                setItems(result.data.data.map(row => ({
                    ...row, action: (<>
                        <Link to={`/master/working-hours/${row.id}`}><i className="ri-edit-box-line"></i></Link> &nbsp;
                    </>)
                })));
                setCurrentPage(result.data.page_number);
                setCurrentPage(result.data.page_number);
                setTotalPages(result.data.total_records >= 0 ? Math.ceil(result.data.total_records / itemsPerPage) : 1)
            }
        } catch (err) {
            console.log(err);
        }
        setLoader(false);

    };


    const updateHandler = async (id, data) => {

        const { workingHours} = data;
        try {
            const result = await updateUnit(id, workingHours, token);
            if (result.status) {
                toast.success(result.message);
                navigate("/master/working-hours");
                getAllUnitsList();
            } else {
                toast.error(result.message);
                return false;
            }
        } catch (err) {
            console.log(err);
        }
    }


    const submitHandler = async (data) => {
        // console.log("log submitted")

    }


    useEffect(() => {
        if (unitId) {
            setIsEditMode(true);
        } else {
            setIsEditMode(false);
        }
    }, [unitId]);


    useEffect(() => {
        getAllUnitsList();
    }, [currentPage]);

    useEffect(() => {
        setResetForm(!resetForm);
    }, [unitId]);


    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Working Hours</li>
                            </ol>
                            <h4 className="main-title mb-0">Working Hours</h4>
                        </div>
                    </div>

                    {isEditMode && <Row className="g-3">
                        <UserForm unitId={unitId || 0}
                                  isEditMode={isEditMode}
                                  clearForm={resetForm}
                                  submitHandler={(obj) => {
                                      if (isEditMode) return updateHandler(unitId || 0, obj);
                                      return submitHandler(obj);
                                  }}
                                  token={token}/>
                    </Row>}


                    <Row className="g-3">
                        <WorkingHoursTable items={items} currentPage={currentPage} totalPages={totalPages}
                                           currentPageHandle={currentPageHandle}/>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
