import React from 'react';
import {Table, Pagination, Card} from 'react-bootstrap';

const RolesTable = ({items, currentPage, totalPages, currentPageHandle}) => {

    function handlePageChange(page) {
        currentPageHandle(page);
    }

    return (<>
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>Role List</Card.Title>
                    {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                </Card.Header>
                <Card.Body className="p-4">
                    <div>
                        <Table hover responsive  striped >
                            <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Actions</th>
                                {/* Add more table headers here */}
                            </tr>
                            </thead>
                            <tbody>
                            {items.length > 0 && items.map((item,index) => (
                                <tr key={item.id}>
                                    <td>{currentPage===1?index+1:`${currentPage}${index+1}`}</td>
                                    <td>{item.name}</td>
                                    <td>{item.action}</td>
                                    {/* Add more table cells here */}
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                        <Pagination>
                            <Pagination.Prev
                                disabled={currentPage === 1}
                                onClick={() => handlePageChange(currentPage - 1)}
                            />
                            {Array.from({length: totalPages}, (_, index) => (
                                <Pagination.Item
                                    key={index + 1}
                                    active={index + 1 === currentPage}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </Pagination.Item>
                            ))}
                            <Pagination.Next
                                disabled={currentPage === totalPages}
                                onClick={() => handlePageChange(currentPage + 1)}
                            />
                        </Pagination>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default RolesTable;
