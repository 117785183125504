import React, {useEffect, useState} from "react";
import {Button, Card, Form} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {loginSchema} from "../../schemas"
import {login} from "../../services/auth-services"
import {useDispatch, useSelector} from "react-redux";
import {loginActions, loginSelector} from "../../store/login-slice";
import logo from "../../../src/assets/logo/logo.jpeg"

const initialValues = {
    email: "",
    password: ""
};

export default function Signin() {

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const {isLoggedIn} = useSelector(loginSelector);
    const navigate = useNavigate();
    const getLogin = async (email, password) => {
        setLoader(true);
        try {
            const result = await login(email, password);
            if (result.status) {
                localStorage.setItem("sidebar-skin", "prime");
                dispatch(loginActions.login({
                        token: result.token,
                        userRole: result.data.user_role,
                        name: result.data.name,
                        email: result.data.email,
                        access: result.data.access,
                    })
                );

            }
        } catch (e) {
            // console.log(e)
        }
        setLoader(false);
    }
    const {values, errors, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues, validationSchema: loginSchema, onSubmit: ((values) => {
            getLogin(values.email, values.password);
        })
    })

    useEffect(() => {
        // eslint-disable-next-line no-undef
        document.title = "Login SmartFactory";
    }, []);


    useEffect(() => {
        if (isLoggedIn) {
            navigate("/dashboard");
        }
        // return () => {
        //   navigate('/');
        // }
    }, [isLoggedIn]);

    return (
        <div className="page-sign">
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-sign">
                <Card.Header>
                    <Link to="/" className="header-logo mb-4"><img src={logo} alt=""/></Link>
                    <Card.Title>Sign In</Card.Title>
                    <Card.Text>Welcome back! Please signin to continue.</Card.Text>
                </Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" value={values.email}
                                          onChange={handleChange} onBlur={handleBlur}
                                          placeholder="Enter your email address" isInvalid={!!errors.email}/>
                            <Form.Control.Feedback type="invalid">
                                {errors.email}
                            </Form.Control.Feedback>
                        </div>
                        <div className="mb-4">
                            <Form.Label className="d-flex justify-content-between">Password <Link to="/pages/forgot">Forgot
                                password?</Link></Form.Label>
                            <Form.Control type="password" name="password" value={values.password}
                                          onChange={handleChange} onBlur={handleBlur}
                                          isInvalid={!!errors.password}
                                          placeholder="Enter your password"/>
                            <Form.Control.Feedback type="invalid">
                                {errors.password}
                            </Form.Control.Feedback>
                        </div>
                        <Button type="submit" variant="primary" className="btn-sign">Sign In</Button>

                    </Form>
                </Card.Body>
                <Card.Footer>
                    {/*Don't have an account? <Link to="/pages/signup">Create an Account</Link>*/}
                </Card.Footer>
            </Card>
        </div>
    )
}
