import axios from "axios";
import {BASE_URL} from "../../utils/urls";
export const fetchEmployeeData = async (inputDate, location, token) => {

    const data = JSON.stringify({
        input_date: inputDate,
        location
    });

    const res = await axios.post(`${BASE_URL}/fetch-employee-data`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const addManPowerDataApi = async (tailors, helpers, toDate, location, token) => {

    const data = JSON.stringify({
        tailors,
        helpers,
        to_date: toDate,
        location: location
    });

    const res = await axios.post(`${BASE_URL}/add-man-power-data`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchCheckDataSubmitted = async (inputDate, location, token) => {
    const data = JSON.stringify({
        input_date: inputDate,
        location
    });
    const res = await axios.post(`${BASE_URL}/check-emp-data-submitted`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchLineWiseData = async (inputDate, location, token) => {
    const data = JSON.stringify({
        input_date: inputDate,
        location
    });
    const res = await axios.post(`${BASE_URL}/get-line-wise-manpower-allotment`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchLineWiseOTData = async (inputDate, location, token) => {
    const data = JSON.stringify({
        input_date: inputDate,
        location
    });
    const res = await axios.post(`${BASE_URL}/get-ot-line-wise-manpower-allotment`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchStyleWiseData = async (inputDate, location, token) => {
    const data = JSON.stringify({
        input_date: inputDate,
        location
    });
    const res = await axios.post(`${BASE_URL}/get-style-wise-data`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const styleManPowerUpdate = async (styles, toDate, location, token) => {

    const data = JSON.stringify({
        styles,
        input_date: toDate,
        location: location
    });

    const res = await axios.post(`${BASE_URL}/style-manpower-update`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchLinesByDate = async (inputDate, location, token) => {
    const data = JSON.stringify({
        input_date: inputDate,
        location
    });
    const res = await axios.post(`${BASE_URL}/get-lines-by-date`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};





