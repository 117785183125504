import React, {useEffect, useState} from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Main from './layouts/Main';
import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";


import {ToastContainer} from 'react-toastify';
// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import {useSelector} from "react-redux";
import {loginSelector} from "./store/login-slice";
import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';
import {getRouteAccess} from "./services/user-services";


// set skin on load
window.addEventListener("load", function () {
    let skinMode = localStorage.getItem("skin-mode");
    let HTMLTag = document.querySelector("html");

    if (skinMode) {
        HTMLTag.setAttribute("data-skin", skinMode);
    }
});

export default function App() {

    const [loader, setLoader] = useState(false);
    const [protectedAccess, setProtectedAccess] = useState([]);
    const [filteredRoutes, setFilteredRoutes] = useState([]);
    const {token, isLoggedIn} = useSelector(loginSelector);

    // async function routeAccess() {
    //     setLoader(true);
    //     try {
    //         const result = await getRouteAccess(token);
    //         if (result.status) {
    //             setProtectedAccess(result.data);
    //         } else {
    //             toast(result.message)
    //         }
    //         setLoader(false);
    //     } catch (e) {
    //         setLoader(false);
    //
    //     }
    // }
    //
    // useEffect(() => {
    //     (async () => {
    //       await  routeAccess();
    //     })();
    //
    // }, [token])

    useEffect(() => {
        if (protectedAccess.length > 0) {
            setFilteredRoutes(protectedRoutes.filter(route => {
                return protectedAccess.includes(route.path);
            }));
        }else {
            setFilteredRoutes(protectedRoutes);
        }
    }, [protectedAccess])


    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <BrowserRouter>
                <Routes>
                    {isLoggedIn && filteredRoutes.length>0 && (
                        <>
                        {/*<Route index path="*" element={<Dashboard/>}/>*/}
                        <Route path="/" element={<Main/>}>

                            {filteredRoutes.map((route, index) => {
                                return (
                                    <Route
                                        path={route.path}
                                        element={route.element}
                                        key={index}
                                    />
                                )
                            })}

                            <Route path="*" element={<Navigate to="/" />} />
                        </Route>

                        </>
                    )}

                    {!isLoggedIn && publicRoutes.map((route, index) => {
                        return (
                            <Route
                                path={route.path}
                                element={route.element}
                                key={index}
                            />
                        )
                    })}


                    {!isLoggedIn && (
                        <Route path="*" element={<Navigate to="pages/signin"/>}/>
                    )}

                </Routes>
                <ToastContainer/>

            </BrowserRouter>
        </React.Fragment>

    );
}
