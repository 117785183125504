import * as Yup from "yup"

export const loginSchema = Yup.object({
    email: Yup.string().email().required('Valid Email Required'),
    password: Yup.string().min(6).required('Password required'),
});

export const rolesSchema = Yup.object({
    roleName: Yup.string().min(2).required('Role Name Required'),
    permissions: Yup.array().min(1).required('Permissions Required'),
    plants: Yup.array().min(1).required("Plant's Required"),
    lines: Yup.array().min(1).required("Line's Required"),
});

export const userSchema = Yup.object({
    name: Yup.string().min(2).required('Name Required'),
    email: Yup.string().email().required('Email Required'),
    mobile: Yup.string().min(10).notRequired(),
    role: Yup.string().required('Role Required'),
    userIdType: Yup.number().required('Need to Select User Id Type'),
    empId: Yup.string().required('Plz Add Employee Id'),

});

export const workingHoursSchema = Yup.object({
    id: Yup.number().required('Id Required'),
    unitName: Yup.string().min(2).required('Unit Name Required'),
    workingHours: Yup.number().required('Working Hours Required'),
});
export const forgetPasswordSchema = Yup.object({

    email: Yup.string().email().required('Email Required'),

});

export const passwordResetSchema = Yup.object({

    password: Yup.string().required('Password Required'),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match').required('Confirm Password is required')

});

export const opEntrySchema = Yup.object({
    company_name: Yup.string().min(2).required('Company Name Required'),
    style: Yup.string().required('Style Required'),
    operation: Yup.string().required('operation required'),
    sam_min: Yup.string().required('Sam Min Required'),
    machine_type: Yup.string().required('machine type required'),
    no_of_machine: Yup.number().required('plz add number of machines'),

});

export const machineTypeSchema = Yup.object({
    machine_type: Yup.string().min(2).required('Machine Type Required'),
    status: Yup.string().required('Status Required'),
});

export const operationSchema = Yup.object({
    operation: Yup.string().min(2).required('Operation Required'),
    status: Yup.string().required('Status Required'),
});


//Floor Creation
Yup.object({
    floorName: Yup.string().min(2).required('Floor Required'),
});
Yup.object({
    lineName: Yup.string().min(2).required('Line Required'),
    floor: Yup.string().required('Select Floor Required'),
});
//Hr Input

export const hrInputSchema = Yup.object().shape({
    inputDate: Yup.date().required('Input Date is required'),
    unitId: Yup.number().required('Unit required'),
    floor: Yup.number().required('Floor is required'),
    department: Yup.number().required('Department is required'),
    lines: Yup.array().when('department', ([department], schema) => {
        return department === 2 ? schema.of(
            Yup.object().shape({
                lineId: Yup.number().required("Line Id Required"),
                floorId: Yup.number().required("Floor Id Required"),
                lineName: Yup.string().required('LineName required'),
                floorName: Yup.string().required('Floor required'),
                manPower: Yup.number().required('Man Power Required'),
                manPowerCost: Yup.number().required('Man Power Cost Required'),
                OTHours: Yup.number().required('OT Hours Required'),
                OTHoursCost: Yup.number().required('OT Hours Cost Required'),
            })
        ) : schema.notRequired();
    }),
    manPower: Yup.number().when('department', ([department], schema) => {
        return department === 1 || department === 3 ? schema.required('Man Power is required') : schema.notRequired();
    }),
    manPowerCost: Yup.number().when('department', ([department], schema) => {
        return department === 1 || department === 3 ? schema.required('Man Power Cost is required') : schema.notRequired();
    }),
    OTHours: Yup.number().when('department', ([department], schema) => {
        return department === 1 || department === 3 ? schema.required('OT Hours is required') : schema.notRequired();
    }),
    OTHoursCost: Yup.number().when('department', ([department], schema) => {
        return department === 1 || department === 3 ? schema.required('OT Hours Cost is required') : schema.notRequired();
    }),

});

Yup.object().shape({
    inputDate: Yup.date().required('Input Date is required'),
    companyName: Yup.string().required('Company Name is required'),
    floor: Yup.number().required('Floor is required'),
    department: Yup.string().required('Department is required'),
    styles: Yup.array().when('department', ([department], schema) => {
        return department === '1' || department === '2' ? schema.of(
            Yup.object().shape({
                lineNo: Yup.number().when('department', ([department], schema) => {
                    return department === '2' ? schema.required('Line Id is required') : schema.notRequired();
                }),
                lineName: Yup.string().when('department', ([department], schema) => {
                    return department === '2' ? schema.required('Line Name is required') : schema.notRequired();
                }),
                styleNo: Yup.number().required('Style Id  required'),
                styleName: Yup.string().required('Style Id  required'),
                tailor: Yup.number().required('Tailor Required'),
                helper: Yup.number().required('Helper Required'),
                tailorOt: Yup.number().required('Tailor OT Hours Required'),
                helperOt: Yup.number().required('Helper OT Hours Required'),
            })
        ) : schema.notRequired();
    }),

    // manPower: Yup.number().when('department', ([department], schema) => {
    //     return department === '1' || department === '3' ? schema.required("Man Power Required") : schema.notRequired();
    // }),
    // manPowerCost: Yup.number().when('department', ([department], schema) => {
    //     return department === '1' || department === '3' ? schema.required("Man Power Cost Required") : schema.notRequired();
    // }),
    // lines: Yup.array().when('department', ([department], schema) => {
    //     return department === '2' ? schema.of(
    //         Yup.object().shape({
    //             lineName: Yup.string().required('Line Name required'),
    //             manPower: Yup.number().required('Man Power Required'),
    //             cost: Yup.number().required('Man Power Cost Required'),
    //         })
    //     ) : schema.notRequired();
    // }),
});
export const manPowerAllotmentValidationSchema = Yup.object().shape({
    tailors: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            line: Yup.number().required('Line is required'),
            empCode: Yup.string().required('Employee Code is required'),
            payCode: Yup.string().required('Pay Code is required'),
            empName: Yup.string().required('Employee Name is required'),
            empDepartment: Yup.string().required('Employee Department is required'),
            empDesignation: Yup.string().required('Employee Designation is required'),
            OTLine: Yup.number().required('OT Line is required'),
        })
    ),
    helpers: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            line: Yup.number().required('Line is required'),
            empCode: Yup.string().required('Employee Code is required'),
            payCode: Yup.string().required('Pay Code is required'),
            empName: Yup.string().required('Employee Name is required'),
            empDepartment: Yup.string().required('Employee Department is required'),
            empDesignation: Yup.string().required('Employee Designation is required'),
            OTLine: Yup.number().required('OT Line is required'),
        })
    ),
});

Yup.object().shape({
    type: Yup.string().required('Type is required'),
    line: Yup.string().required('Line is required'),
    lineId: Yup.number().required("Line Required"),
    runDays: Yup.number().required("Run Days Required"),
    style: Yup.string().required("Style Required"),
    styleId: Yup.number().required("Style Required"),
    orderQty: Yup.string().required("Line Required"),
    sam: Yup.number().required("Sam Value Required"),
    shift: Yup.number().required("Shift Hours Required"),
    tailors: Yup.number().required("Tailors Required"),
    helpers: Yup.number().required("Helper Required"),
    totalTailors: Yup.number().required("Tailors Required"),
    totalHelpers: Yup.number().required("Helper Required"),
    tailorOT: Yup.number().required("Tailor OT Required"),
    helperOT: Yup.number().required("Helper OT Required"),
    cuttingToady: Yup.number().required("Cutting Required"),
    stitchingToday: Yup.number().required("Stitching Required"),
});
// export const styleManPowerAllotmentValidationSchema = Yup.object().shape({
//     styles: Yup.array().of(styleObjectSchema),
// });

export const styleManPowerAllotmentValidationSchema = Yup.object().shape({
    styles: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            line: Yup.string().required('Line is required'),
            lineId: Yup.number().required("Line Required"),
            runDays: Yup.number().required("Run Days Required"),
            style: Yup.string().required("Style Required"),
            styleId: Yup.number().required("Style Required"),
            orderQty: Yup.string().required("Line Required"),
            samValue: Yup.number().required("Sam Value Required"),
            shift: Yup.number().required("Shift Hours Required"),
            tailors: Yup.number().required("Tailors Required"),
            helpers: Yup.number().required("Helper Required"),
            totalTailors: Yup.number().required("Tailors Required"),
            totalHelpers:Yup.number().required("Helper Required"),
            tailorOT: Yup.number().required("Tailor OT Required"),
            tailorsOTHours: Yup.number().required("Tailor OT Hours Required"),
            helpersOTHours: Yup.number().required("Tailor OT Hours Required"),
            helperOT: Yup.number().required("Helper OT Required"),
            totalCutting: Yup.number().required("Cutting Required"),
            stitchingToday: Yup.number().required("Stitching Required"),
            productionDate: Yup.string().required("Production Required"),
            effPlan: Yup.number().required("Planned Efficiency Required"),
        })
    ),
});
Yup.object().shape({
    tailors: Yup.array().of(
        Yup.object().shape({
            type: Yup.string().required('Type is required'),
            line: Yup.number().required('Line is required'),
            empCode: Yup.string().required('Employee Code is required'),
            payCode: Yup.string().required('Pay Code is required'),
            empName: Yup.string().required('Employee Name is required'),
            empDepartment: Yup.string().required('Employee Department is required'),
            empDesignation: Yup.string().required('Employee Designation is required'),
            OTLine: Yup.number().required('OT Line is required'),
        })
    ),
});
export const ieSchema = Yup.object({
    style: Yup.string().required('Style Required'),
    plannedCost: Yup.number().required('Planned Cost Required'),
    cuttingCost: Yup.number().required('Cutting Cost Required'),
    finishingCost: Yup.number().required('Finishing Cost Required'),
    samValue: Yup.number().required('Sam Value Required'),
});


export const StyleByDateUnit = Yup.object().shape({
    inputDate: Yup.date().required('Input Date is required'),
    unitId: Yup.number().required('Unit required'),
    styles: Yup.array().of(
        Yup.object().shape({
            styleId: Yup.number().required("Style Id Required"),
            lineId: Yup.number().required("Line Id Required"),
            floorId: Yup.number().required("Floor Id Required"),
            styleName: Yup.string().required('Style required'),
            lineName: Yup.string().required('LineName required'),
            floorName: Yup.string().required('Floor required'),
            totalQty: Yup.number().required('Quantity required'),
            failQty: Yup.number().required('Failed Quantity required'),
            passQty: Yup.number().required('Pass Quantity required'),
        })
    )
});