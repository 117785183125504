import React from "react";
import Footer from "./../../../layouts/Footer";
import {Button, Card, Col, Nav, Row,ProgressBar,Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";
import prod1 from "../../../assets/img/prod1.jpg";
import prod2 from "../../../assets/img/prod2.jpg";
import prod3 from "../../../assets/img/prod3.jpg";
import prod4 from "../../../assets/img/prod4.jpg";
import prod5 from "../../../assets/img/prod5.jpg";

export default function EmbdProceesDelays() {
    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };
    const seriesOne = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];

    const optionOne = {
        chart: {
            parentHeightOffset: 0, stacked: true, toolbar: {show: false}
        }, dataLabels: {enabled: false}, grid: {
            borderColor: 'rgba(72,94,144, 0.07)', padding: {
                top: -20, left: 5
            }
        }, states: states, colors: ['#506fd9', '#fff', '#c8ccd4'], plotOptions: {
            bar: {
                columnWidth: '35%'
            },
        }, stroke: {
            curve: 'straight', lineCap: 'square', width: 0
        }, tooltip: {enabled: false}, fill: {opacity: 1}, legend: {show: false}, xaxis: {
            type: 'numeric', tickAmount: 11, decimalsInFloat: 0, labels: {
                style: {
                    fontSize: '11px'
                }
            }
        }, yaxis: {
            max: 4000, tickAmount: 8, labels: {
                style: {
                    colors: ['#a2abb5'], fontSize: '11px'
                }
            }
        }
    };

    const seriesTwo = [{
        type: 'column',
        data: [[0, 10], [1, 20], [2, 35], [3, 30], [4, 35], [5, 50], [6, 30], [7, 25], [8, 15], [9, 20], [10, 32], [11, 40], [12, 55], [13, 40], [14, 30], [15, 20], [16, 34], [17, 45], [18, 35], [19, 20], [20, 40], [21, 20], [22, 35], [23, 30], [24, 35], [25, 50], [26, 30], [27, 25], [28, 15], [29, 20], [30, 32], [31, 40], [32, 55], [33, 40], [34, 30], [35, 20], [36, 34], [37, 45], [38, 35], [39, 20], [40, 40]]
    }, {
        type: 'area',
        data: [[0, 70], [1, 71], [2, 70], [3, 70], [4, 78], [5, 79], [6, 75], [7, 70], [8, 75], [9, 72], [10, 74], [11, 76], [12, 80], [13, 81], [14, 80], [15, 78], [16, 80], [17, 82], [18, 87], [19, 80], [20, 81], [21, 80], [22, 70], [23, 70], [24, 71], [25, 75], [26, 74], [27, 76], [28, 80], [29, 80], [30, 80], [31, 76], [32, 75], [33, 80], [34, 81], [35, 80], [36, 79], [37, 78], [38, 80], [39, 81], [40, 80]]
    }];
    const optionTwo = {
        chart: {
            parentHeightOffset: 0,
            type: 'line',
            toolbar: { show: false }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#c1ccf1', '#506fd9'],
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                bottom: -5
            },
            yaxis: {
                lines: { show: false }
            }
        },
        fill: {
            type: ['solid', 'gradient'],
            gradient: {
                type: 'vertical',
                opacityFrom: 0.35,
                opacityTo: 0.2,
                gradientToColors: ['#f3f5fc']
            }
        },
        stroke: {
            width: [0, 1.5]
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 8,
            decimalsInFloat: 0,
            labels: {
                style: {
                    colors: '#6e7985',
                    fontSize: '9px'
                }
            },
            axisBorder: { show: false }
        },
        yaxis: {
            show: false,
            min: 0,
            max: 100
        },
        legend: { show: false },
        tooltip: { enabled: false }
    };



    const seriesThree = [{
        type: 'column',
        data: [[0, 32], [1, 40], [2, 55], [3, 40], [4, 30], [5, 20], [6, 34], [7, 45], [8, 35], [9, 20], [10, 40], [11, 20], [12, 35], [13, 30], [14, 35], [15, 50], [16, 30], [17, 25], [18, 15], [19, 20], [20, 32], [21, 40], [22, 55], [23, 40], [24, 30], [25, 20], [26, 34], [27, 45], [28, 35], [29, 20], [30, 40], [31, 20], [32, 35], [33, 30], [34, 35], [35, 50], [36, 30], [37, 25], [38, 15], [39, 20], [40, 32]]
    }, {
        type: 'area',
        data: [[0, 82], [1, 80], [2, 85], [3, 80], [4, 76], [5, 70], [6, 74], [7, 75], [8, 75], [9, 70], [10, 71], [11, 72], [12, 75], [13, 80], [14, 85], [15, 80], [16, 70], [17, 95], [18, 95], [19, 90], [20, 92], [21, 90], [22, 95], [23, 90], [24, 90], [25, 90], [26, 84], [27, 85], [28, 85], [29, 80], [30, 70], [31, 70], [32, 75], [33, 70], [34, 75], [35, 80], [36, 75], [37, 85], [38, 78], [39, 70], [40, 82]]
    }];
    const optionThree = {
        chart: {
            parentHeightOffset: 0,
            type: 'line',
            toolbar: { show: false }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#cde1ff', '#85b6ff'],
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                bottom: -5
            },
            yaxis: {
                lines: { show: false }
            }
        },
        fill: {
            type: ['solid', 'gradient'],
            gradient: {
                type: 'vertical',
                opacityFrom: 0.35,
                opacityTo: 0.2,
                gradientToColors: ['#f3f5fc']
            }
        },
        stroke: {
            width: [0, 1.5]
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 8,
            decimalsInFloat: 0,
            labels: {
                style: {
                    colors: '#6e7985',
                    fontSize: '9px'
                }
            },
            axisBorder: { show: false }
        },
        yaxis: {
            show: false,
            min: 0,
            max: 100
        },
        legend: { show: false },
        tooltip: { enabled: false }
    }

    const seriesFour = [{
        type: 'column',
        data: [[0, 0], [1, 0], [2, 5], [3, 6], [4, 8], [5, 10], [6, 15], [7, 18], [8, 13], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5], [15, 8], [16, 11], [17, 7], [18, 5], [19, 5], [20, 6], [21, 6], [22, 5], [23, 5], [24, 6]]
    }, {
        type: 'column',
        data: [[0, 9], [1, 7], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6], [15, 16], [16, 5], [17, 17], [18, 14], [19, 6], [20, 5], [21, 2], [22, 12], [23, 4], [24, 7]]
    }];
    const optionFour = {
        chart: {
            stacked: true,
            sparkline: { enabled: true }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#506fd9', '#e5e9f2'],
        grid: {
            padding: {
                bottom: 10,
                left: -6,
                right: -5
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                endingShape: 'rounded'
            },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square'
        },
        yaxis: {
            min: 0,
            max: 30
        },
        tooltip: { enabled: false }
    };


    const seriesFive = [{
        type: 'column',
        data: [[0, 2], [1, 3], [2, 5], [3, 7], [4, 12], [5, 17], [6, 10], [7, 14], [8, 15], [9, 12], [10, 8], [11, 6], [12, 9], [13, 12], [14, 5], [15, 10], [16, 12], [17, 16], [18, 13], [19, 7], [20, 4], [21, 2], [22, 2], [23, 2], [24, 5]]
    }, {
        type: 'column',
        data: [[0, 12], [1, 7], [2, 4], [3, 5], [4, 8], [5, 10], [6, 4], [7, 7], [8, 11], [9, 9], [10, 5], [11, 3], [12, 4], [13, 6], [14, 6], [15, 10], [16, 5], [17, 7], [18, 4], [19, 16], [20, 15], [21, 11], [22, 12], [23, 4], [24, 7]]
    }];
    const optionFive = {
        chart: {
            stacked: true,
            sparkline: { enabled: true }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#85b6ff', '#e5e9f2'],
        grid: {
            padding: {
                bottom: 10,
                left: -6,
                right: -5
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                endingShape: 'rounded'
            },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square'
        },
        yaxis: {
            min: 0,
            max: 30
        },
        tooltip: { enabled: false }
    };


    return (<React.Fragment>

        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page"> EMBD / PROCEES DELAYS</li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3">
                    <Card className="card-one card-product-inventory">
                        <Card.Header>
                            <Card.Title as="h6">Product Inventory</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ul className="legend mb-3 position-absolute">
                                <li>Remaining Quantity</li>
                                <li>Sold Quantity</li>
                            </ul>
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310}
                                            className="apex-chart-twelve mt-4 pt-3"/>
                        </Card.Body>
                    </Card>
                </Row>
                <Row className="mt-4">
                    <Col xl="5">
                        <Row className="g-3">
                            {[
                                {
                                    "icon": "ri-shopping-bag-fill",
                                    "percent": {
                                        "color": "success",
                                        "amount": "+28.5%"
                                    },
                                    "value": "$14,803.80",
                                    "label": "Total Sales",
                                    "last": {
                                        "color": "success",
                                        "amount": "2.3%"
                                    }
                                }, {
                                    "icon": "ri-wallet-3-fill",
                                    "percent": {
                                        "color": "danger",
                                        "amount": "-3.8%"
                                    },
                                    "value": "$8,100.63",
                                    "label": "Total Expenses",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.5%"
                                    }
                                }, {
                                    "icon": "ri-shopping-basket-fill",
                                    "percent": {
                                        "color": "danger",
                                        "amount": "-8.4%"
                                    },
                                    "value": "23,480",
                                    "label": "Total Products",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                }, {
                                    "icon": "ri-shopping-basket-fill",
                                    "percent": {
                                        "color": "success",
                                        "amount": "+20.9%"
                                    },
                                    "value": "18,060",
                                    "label": "Products Sold",
                                    "last": {
                                        "color": "success",
                                        "amount": "5.8%"
                                    }
                                }
                            ].map((item, index) => (
                                <Col xs="6" md="3" xl="6" key={index}>
                                    <Card className="card-one card-product">
                                        <Card.Body className="p-3">
                                            <div className="d-flex align-items-center justify-content-between mb-5">
                                                <div className="card-icon"><i className={item.icon}></i></div>
                                                <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                                            </div>
                                            <h2 className="card-value ls--1">{item.value}</h2>
                                            <label className="card-label fw-medium text-dark">{item.label}</label>
                                            <span className="d-flex gap-1 fs-xs">
                        <span className={"d-flex align-items-center text-" + item.last.color}>
                          <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                        </span>
                        <span className="text-secondary">than last week</span>
                      </span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xl="7">
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Product Inventory</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <ul className="legend mb-3 position-absolute">
                                    <li>Remaining Quantity</li>
                                    <li>Sold Quantity</li>
                                </ul>
                                <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310} className="apex-chart-twelve mt-4 pt-3" />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="g-3">
                    <Col xl="5">
                        <Row className="g-3">
                            {[
                                {
                                    "icon": "ri-shopping-bag-fill",
                                    "percent": {
                                        "color": "success",
                                        "amount": "+28.5%"
                                    },
                                    "value": "$14,803.80",
                                    "label": "Total Sales",
                                    "last": {
                                        "color": "success",
                                        "amount": "2.3%"
                                    }
                                }, {
                                    "icon": "ri-wallet-3-fill",
                                    "percent": {
                                        "color": "danger",
                                        "amount": "-3.8%"
                                    },
                                    "value": "$8,100.63",
                                    "label": "Total Expenses",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.5%"
                                    }
                                }, {
                                    "icon": "ri-shopping-basket-fill",
                                    "percent": {
                                        "color": "danger",
                                        "amount": "-8.4%"
                                    },
                                    "value": "23,480",
                                    "label": "Total Products",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                }, {
                                    "icon": "ri-shopping-basket-fill",
                                    "percent": {
                                        "color": "success",
                                        "amount": "+20.9%"
                                    },
                                    "value": "18,060",
                                    "label": "Products Sold",
                                    "last": {
                                        "color": "success",
                                        "amount": "5.8%"
                                    }
                                }
                            ].map((item, index) => (
                                <Col xs="6" md="3" xl="6" key={index}>
                                    <Card className="card-one card-product">
                                        <Card.Body className="p-3">
                                            <div className="d-flex align-items-center justify-content-between mb-5">
                                                <div className="card-icon"><i className={item.icon}></i></div>
                                                <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                                            </div>
                                            <h2 className="card-value ls--1">{item.value}</h2>
                                            <label className="card-label fw-medium text-dark">{item.label}</label>
                                            <span className="d-flex gap-1 fs-xs">
                        <span className={"d-flex align-items-center text-" + item.last.color}>
                          <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                        </span>
                        <span className="text-secondary">than last week</span>
                      </span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col xl="7">
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Product Inventory</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <ul className="legend mb-3 position-absolute">
                                    <li>Remaining Quantity</li>
                                    <li>Sold Quantity</li>
                                </ul>
                                <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310} className="apex-chart-twelve mt-4 pt-3" />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="8">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Popular Products</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                {[
                                    {
                                        "image": prod1,
                                        "name": "iPhone 14 Pro Max Leather Case",
                                        "text": "Leather Case with MagSafe",
                                        "price": "$35.50",
                                        "available": "560",
                                        "sold": "40",
                                        "sales": "$1,420.00"
                                    }, {
                                        "image": prod2,
                                        "name": "Apple Watch Series 7 GPS",
                                        "text": "Midnight Aluminum Sport Band",
                                        "price": "$395.99",
                                        "available": "350",
                                        "sold": "150",
                                        "sales": "$59,398.50"
                                    }, {
                                        "image": prod3,
                                        "name": "Apple Wireless Black Airpods",
                                        "text": "(3rd Generation) Wireless Earbuds",
                                        "price": "$210.95",
                                        "available": "250",
                                        "sold": "100",
                                        "sales": "$21,095.00"
                                    }, {
                                        "image": prod4,
                                        "name": "Macbook Air M2 Chip",
                                        "text": "16GB Ram 512GB SSD Storage",
                                        "price": "$1,910.00",
                                        "available": "100",
                                        "sold": "50",
                                        "sales": "$95,500.00"
                                    }
                                ].map((item, index) => (
                                    <div className="product-wrapper" key={index}>
                                        <Table>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    <div className="product-thumb">
                                                        <img src={item.image} alt="" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <h6 className="mb-1">
                                                        <Link to="">{item.name}</Link>
                                                    </h6>
                                                    <span className="fs-sm text-secondary">{item.text}</span>
                                                </td>
                                                <td>
                                                    <label className="d-block text-secondary fs-sm mb-1">Price</label>
                                                    <span className="d-block fw-medium text-dark ff-numerals">{item.price}</span>
                                                </td>
                                                <td>
                                                    <label className="d-block text-secondary fs-sm mb-1">Available</label>
                                                    <span className="d-block fw-medium text-dark ff-numerals">{item.available}</span>
                                                </td>
                                                <td>
                                                    <label className="d-block text-secondary fs-sm mb-1">Sold</label>
                                                    <span className="d-block fw-medium text-dark ff-numerals">{item.sold}</span>
                                                </td>
                                                <td>
                                                    <label className="d-block text-secondary fs-sm mb-1">Sales</label>
                                                    <span className="d-block fw-medium text-dark ff-numerals">{item.sales}</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl="4">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Revenue By Departments</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                {[
                                    {
                                        "name": "Automotive",
                                        "amount": "$12,550.50",
                                        "color": "success",
                                        "percent": "55.6%",
                                        "progress": 50
                                    }, {
                                        "name": "Home & Kitchen",
                                        "amount": "$9,805.00",
                                        "color": "danger",
                                        "percent": "6.8%",
                                        "progress": 40
                                    }, {
                                        "name": "Health & Fashion",
                                        "amount": "$15,004.90",
                                        "color": "success",
                                        "percent": "69.2%",
                                        "progress": 70
                                    }, {
                                        "name": "Movies & Television",
                                        "amount": "$5,338.00",
                                        "color": "danger",
                                        "percent": "28.5%",
                                        "progress": 25
                                    }, {
                                        "name": "Toys & Games",
                                        "amount": "$4,965.40",
                                        "color": "danger",
                                        "percent": "18.2%",
                                        "progress": 20
                                    }
                                ].map((item, index) => (
                                    <div className="revenue-item" key={index}>
                                        <div className="revenue-item-body">
                                            <span>{item.name}</span>
                                            <span>{item.amount}</span>
                                            <span className={"text-" + item.color}>{item.percent}</span>
                                        </div>
                                        <ProgressBar now={item.progress} />
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}


