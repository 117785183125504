import React, {useEffect, useState} from "react";
import Header from "../layouts/Header";
import bgImage from "../assets/img/bg/300-ZM1013437.jpg"

export const MainComponent = ({children}) => {
    const divStyle = {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '300px', // Set your desired height
    };

    const optionBar = {
        indexAxis: 'y', maintainAspectRatio: false, responsive: true, plugins: {
            legend: {
                display: false
            }
        }, scales: {
            x: {
                beginAtZero: true, max: 80, grid: {
                    borderColor: '#e2e5ec', color: '#f3f5f9'
                }, ticks: {
                    font: {
                        size: 11
                    }
                }
            }, y: {
                grid: {
                    borderWidth: 0, color: '#f3f5f9'
                }, ticks: {
                    color: '#212830', font: {
                        size: 14
                    }
                }
            }
        }
    };
    // Changing necessary options between dark and light skin
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    if (skin === 'dark') {
        optionBar.scales['x'].grid.color = '#222b41';
        optionBar.scales['x'].ticks.color = 'rgba(255,255,255,.65)';
        optionBar.scales['x'].grid.borderColor = '#222b41';
        optionBar.scales['y'].grid.color = '#222b41';
        optionBar.scales['y'].ticks.color = 'rgba(255,255,255,.65)';
    } else {
        optionBar.scales['x'].grid.color = '#edeff6';
        optionBar.scales['x'].ticks.color = '#42484e';
        optionBar.scales['x'].grid.borderColor = '#edeff6';
        optionBar.scales['y'].grid.color = '#edeff6';
        optionBar.scales['y'].ticks.color = '#42484e';
    }


    const switchSkin = (skin) => {
        const textPrimary = document.getElementsByClassName('text-primary-dark');

        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }

            for (const txt of textPrimary) {
                txt.classList.add('text-primary');
            }

        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }

            for (const txt of textPrimary) {
                txt.classList.remove('text-primary');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    return (
        <>
            <Header onSkin={setSkin}/>
            <div className={divStyle}>{children}</div>
        </>
    );
};
