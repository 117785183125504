import React from "react";
import Footer from "./../../../layouts/Footer";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";

export default function EFFICICENY() {
    const floorData = {
        "mode": "development",
        "result": {
            "categories": [
                "NB1 FINISHING",
                "NB2 FINISHING",
                "NB3 FINISHING",
                "NBG FINISHING",
                "OB1 FINISHING",
                "OB2 FINISHING",
                "OB3 FINISHING",
                "OBG FINISHING"
            ],
            "today_values": [
                "11020.80",
                "69000.00",
                "24150.00",
                "6676.13",
                "17403.10",
                "4161.00",
                "25092.25",
                "0.00"
            ],
            "uptodate_values": [
                "16.17",
                "54.70",
                "32.92",
                "21.48",
                "37.30",
                "36.95",
                "34.64",
                "16.88"
            ],
            "list": [
                {
                    "floor_id": "6",
                    "today_value": "11020.80",
                    "upto_date_value": "16.17",
                    "floor_name": "NB1 FINISHING",
                    "today_target": "48.5",
                    "upto_date_target": "54.71"
                },
                {
                    "floor_id": "4",
                    "today_value": "69000.00",
                    "upto_date_value": "54.70",
                    "floor_name": "NB2 FINISHING",
                    "today_target": "46.67",
                    "upto_date_target": "54.78"
                },
                {
                    "floor_id": "5",
                    "today_value": "24150.00",
                    "upto_date_value": "32.92",
                    "floor_name": "NB3 FINISHING",
                    "today_target": "49.67",
                    "upto_date_target": "52.19"
                },
                {
                    "floor_id": "10",
                    "today_value": "6676.13",
                    "upto_date_value": "21.48",
                    "floor_name": "NBG FINISHING",
                    "today_target": "48.5",
                    "upto_date_target": "61.33"
                },
                {
                    "floor_id": "9",
                    "today_value": "17403.10",
                    "upto_date_value": "37.30",
                    "floor_name": "OB1 FINISHING",
                    "today_target": "55.33",
                    "upto_date_target": "65.86"
                },
                {
                    "floor_id": "2",
                    "today_value": "4161.00",
                    "upto_date_value": "36.95",
                    "floor_name": "OB2 FINISHING",
                    "today_target": "82.33",
                    "upto_date_target": "64.81"
                },
                {
                    "floor_id": "3",
                    "today_value": "25092.25",
                    "upto_date_value": "34.64",
                    "floor_name": "OB3 FINISHING",
                    "today_target": "37.75",
                    "upto_date_target": "58.96"
                },
                {
                    "floor_id": "7",
                    "today_value": "0.00",
                    "upto_date_value": "16.88",
                    "floor_name": "OBG FINISHING",
                    "today_target": "65.33",
                    "upto_date_target": "69.85"
                }
            ]
        },
        "msg": false,
        "isLogin": true,
    }

    const lineData = {
        "mode": "development",
        "result": {
            "categories": [
                "NB1 LINE-D",
                "NB1 LINE-E"
            ],
            "today_values": [
                "4018.80",
                "7002.00"
            ],
            "uptodate_values": [
                "3.94",
                "13.55"
            ],
            "list": [
                {
                    "line_id": "39",
                    "today_value": "4018.80",
                    "upto_date_value": "3.94",
                    "line_name": "NB1 LINE-D",
                    "today_target": "42",
                    "upto_date_target": "36.5"
                },
                {
                    "line_id": "40",
                    "today_value": "7002.00",
                    "upto_date_value": "13.55",
                    "line_name": "NB1 LINE-E",
                    "today_target": "55",
                    "upto_date_target": "55.09"
                }
            ]
        },
        "msg": false,
        "isLogin": true,
    }

    const series = [{
        name: 'Today',
        data: [16.17,
            54.70,
            32.92,
            21.48,
            37.30,
            36.95,
            34.64,
            16.88]
    }, {
        name: 'Upto Date',
        data: [16.17,
            54.70,
            32.92,
            21.48,
            37.30,
            36.95,
            34.64,
            16.88]
    }];

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: floorData.result.categories,
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    }


    const series1 = [{
        name: 'Today',
        data: [16.17,
            54.70,
        ]
    }, {
        name: 'Upto Date',
        data: [16.17,
            54.70,]
    }];

    const options1 = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: lineData.result.categories,
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands"
                }
            }
        }
    }

    return (<React.Fragment>
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">EFFICICENY</li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3 my-3">
                    <ReactApexChart options={options} series={series} type="bar" height={350}/>

                </Row>
                <Row className="g-3 my-3">
                    <ReactApexChart options={options1} series={series1} type="bar" height={350}/>

                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}

