import { configureStore } from '@reduxjs/toolkit'
import loginReducer from './login-slice'
import loaderReducer from "./loader-slice";
import alertReducer from "./alert-slice";
import dateReducer from "./date-slice";


export const store = configureStore({
    reducer: {
        login: loginReducer,
        loader: loaderReducer,
        alert: alertReducer,
        checkdate: dateReducer,
    },
})
