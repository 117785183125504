import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const fetchDateWiseSewingData = async (fromData, toDate,unitId, token) => {
    const data = JSON.stringify({
        from_date: fromData,
        to_date: toDate,
        unit_id: unitId,
    });
    const res = await axios.post(`${BASE_URL}/sewing-profit-loss-date-wise`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchDayWiseData = async (date,unitId, token) => {
    const data = JSON.stringify({
        date,
        unit_id:unitId
    });
    const res = await axios.post(`${BASE_URL}/sewing-profit-loss-day-wise`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};
