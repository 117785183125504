import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const addHrInput = async (inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost, token) => {

    const data = JSON.stringify({
        input_date: inputDate,
        unit_id: unitId,
        floor_id: floor,
        department: department,
        lines,
        man_power:manPower,
        man_power_cost:manPowerCost,
        ot_hours:OTHours,
        ot_hours_cost:OTHoursCost
    });

    const res = await axios.post(`${BASE_URL}/add-hr-input`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};
export const fetchLinesByDateDepartmentFloor = async (unitId, inputDate, floor, department, token) => {
    const data = JSON.stringify({
        unit_id: unitId,
        input_date: inputDate,
        floor: floor,
        department: department
    });
    const res = await axios.post(`${BASE_URL}/get-lines-by-date-floor-department`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchFinishingCuttingDataIfAvailable = async (unitId, inputDate, floor, department, token) => {
    const data = JSON.stringify({
        unit_id: unitId,
        input_date: inputDate,
        floor: floor,
        department: department
    });
    const res = await axios.post(`${BASE_URL}/get-cutting-finishing-manpower-data-if-already-stored`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const fetchHrInputList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-hr-input-list`, data, config);
    return res.data;
};


export const getHRInput = async (ieId, token) => {
    const res = await axios.get(`${BASE_URL}/get-hr-input/${ieId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const updateHRInput = async (id,inputDate, unitId, floor, department, lines,manPower,manPowerCost,OTHours,OTHoursCost, token) => {

    const data = JSON.stringify({
        id: id,
        input_date: inputDate,
        unit_id: unitId,
        floor_id: floor,
        department: department,
        lines,
        man_power:manPower,
        man_power_cost:manPowerCost,
        ot_hours:OTHours,
        ot_hours_cost:OTHoursCost
    });

    const res = await axios.post(`${BASE_URL}/update-hr-input`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const deleteIeInput = async (ieId, token) => {
    const res = await axios.delete(`${BASE_URL}/delete-ie-input/${ieId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


