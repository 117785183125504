import {useDispatch, useSelector} from "react-redux";
import {loginActions, loginSelector} from "../../store/login-slice";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logout} from "../../services/user-services";
import {toast} from "react-toastify";


const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token}=useSelector(loginSelector);
    const [loader, setLoader] = useState(false);
    const logoutHandler=async ()=>{
        setLoader(true);
       try {
           const result=await logout(token);
           if (result.status){
               toast.success("Successfully logged out");
           }
           setLoader(false);
       } catch (e) {
           setLoader(false);
       }
    }
    useEffect( () => {
        logoutHandler();
        dispatch(loginActions.logout());
        navigate("/pages/signin")
    }, [])
    return (
        <div>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <h2>Logout Page</h2>
        </div>
    );
};
export default Logout;
