import {Button, Card, Col, Form, Row, Tab, Table, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {ErrorMessage, FieldArray, FormikProvider, useFormik} from "formik";
import {manPowerAllotmentValidationSchema} from "../../../schemas";
import {useDispatch, useSelector} from "react-redux";
import {checkDateActions, checkDateSelector} from "../../../store/date-slice";
import {
    addManPowerDataApi,
    fetchEmployeeData, fetchLinesByDate,
    fetchLineWiseData, fetchLineWiseOTData
} from "../../../services/input/man-power-allotment-service"
import {loginSelector} from "../../../store/login-slice";
import {toast} from "react-toastify";

const locations = [{label: "New Zeel Rainwear", value: 1}];


export default function ManPowerAllotmentForm() {
    const [loader, setLoader] = useState(false);
    const {toDate, location} = useSelector(checkDateSelector);
    const {token} = useSelector(loginSelector);
    const dispatch = useDispatch();
    const [key, setKey] = useState('tailor');

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [empData, setEmpData] = useState([]);
    const [isDataSubmitted, setIsDataSubmitted] = useState(false);
    const [lines, setLines] = useState([]);
    const [linesNotAssignedYet, setLinesNotAssignedYet] = useState([]);

    const [lineWiseData, setLineWiseData] = useState([])
    const [otLinesData, setOtLinesData] = useState([])


    function isValueAvailable(array, targetValue) {
        // Loop through each object in the array
        for (let i = 0; i < array.length; i++) {
            // Check if the 'value' property of the object matches the targetValue
            if (array[i].value === targetValue) {
                return targetValue; // Value found, return true
            }
        }
        // Value not found in any object, return false
        return "";
    }

    async function addManPowerData(data) {

        const {tailors, helpers} = data
        setLoader(true)
        try {
            const result = await addManPowerDataApi(tailors, helpers, toDate, location, token)
            if (result.status) {
                toast.success(result.message)
                await getLineData();
                await getOTLineData();
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            tailors: [],
            helpers: [],
        }, validationSchema: manPowerAllotmentValidationSchema, onSubmit: ((values) => {
            if (values.tailors.length > 0 && values.helpers.length > 0) {
                addManPowerData(values)
            }

            // addTailors(values.tailors);
            // formik.resetForm()
        })
    })


    function getLine(newValue) {
        return lines.find((line) => line.value === newValue);
    }

    function updateTodayDate() {
        // Get the current date
        const currentDate = new Date();
        // Format the dates as "YYYY-MM-DD"
        const todayDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };
        dispatch(checkDateActions.toDateUpdate({toDate: todayDate(currentDate)}))
    }

    async function getLineData() {
        setLoader(true);
        try {
            const result = await fetchLineWiseData(toDate, location, token);
            if (result.status) {
                setLineWiseData(result.data)
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log(e)
        }
    }

    async function getOTLineData() {
        setLoader(true);
        try {
            const result = await fetchLineWiseOTData(toDate, location, token);
            if (result.status) {
                setOtLinesData(result.data)
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log(e)
        }
    }

    async function getEmployeeData() {
        setLoader(true)
        try {
            const result = await fetchEmployeeData(toDate, location, token);
            if (result.status) {
                if (!result.data.isDataSubmitted) {
                    formik.resetForm();
                }
                setEmpData(result.data.empData);
                setIsDataSubmitted(result.data.isDataSubmitted);

            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }
    }

    function getLocation(newValue) {
        if (newValue) {
            return locations.find((lc) => lc.value === Number(newValue)).label;
        }
    }

    async function getLinesDataByDate() {
        setLoader(true);
        try {
            const result = await fetchLinesByDate(toDate, location, token);
            if (result.status) {
                if (result.data.length>0){
                    setLines(result.data.map(item => ({
                        value: item.id,
                        label: item.line_name
                    })));
                }else {
                    setLines([]);
                }

            }

            setLoader(false);
        } catch (e) {
            console.log(e)
            setLoader(false);
        }
    }


    useEffect(() => {
        if (location) {
            setSelectedLocation(locations.find((lc) => lc.value === Number(location)))
        }
    }, [location]);

    useEffect(() => {
        const lineArrayTailors = formik.values.tailors.map(item => item.line).filter(line => line !== "");
        const lineArrayHelpers = formik.values.helpers.map(item => item.line).filter(line => line !== "");
        const mergedArray = Array.from(new Set([...lineArrayTailors, ...lineArrayHelpers]));
        const result = lines.map(item => {
            if (mergedArray.includes(item.value)) {
                return null;
            } else {
                return item;
            }
        });

        const labels = result
            .filter(item => item !== null) // Remove null values
            .map(item => item.label) // Extract labels

        setLinesNotAssignedYet(labels);

    }, [formik.values.tailors, formik.values.helpers]);
    useEffect(() => {
        formik.resetForm()
        if (empData.length > 0) {

            const tailorsData = empData.filter((emp) => emp.designation === "tailor").map((emp) => ({
                type: emp.type ?? 'Line',
                line: isValueAvailable(lines, parseInt(emp.mp_line_id ?? emp.mapping_line_id)),
                allocated: emp.mp_line_id ? "alloc" : "",
                empCode: emp.emp_code ?? '',
                payCode: emp.pay_code ?? '',
                empName: emp.name,
                empDepartment: emp.department ?? '',
                empDesignation: emp.designation ?? '',
                day: emp.production_date ?? '',
                OTLine: isValueAvailable(lines, parseInt(emp.mp_ot_line_id ?? emp.mapping_ot_line_id)),
                // OTLine: parseInt(emp.mp_ot_line_id ?? emp.mapping_ot_line_id),
                inTime: emp.in_time,
                outTime: emp.out_time,
            }))
            const helperData = empData.filter((emp) => emp.designation !== "tailor").map((emp) => ({
                type: emp.type ?? 'Line',
                line: isValueAvailable(lines, parseInt(emp.mp_line_id ?? emp.mapping_line_id)),
                allocated: emp.mp_line_id ? "alloc" : "",
                empCode: emp.emp_code ?? '',
                payCode: emp.pay_code ?? '',
                empName: emp.name,
                empDepartment: emp.department ?? '',
                empDesignation: emp.designation ?? '',
                day: emp.production_date ?? '',
                OTLine: isValueAvailable(lines, parseInt(emp.mp_ot_line_id ?? emp.mapping_ot_line_id)),
                inTime: emp.in_time,
                outTime: emp.out_time,
            }))

            formik.setFieldValue('tailors', tailorsData)
            formik.setFieldValue('helpers', helperData)
        }

    }, [empData, lines]);


    useEffect(() => {
        if (toDate && location) {
            getLinesDataByDate();
            getEmployeeData();
            getOTLineData();
        }
    }, [toDate, location]);


    useEffect(() => {
        updateTodayDate();
    }, []);

    useEffect(() => {
        if (isDataSubmitted) {
            getLineData();
        } else {
            setLineWiseData([]);
        }
    }, [isDataSubmitted]);

    return (<>
            <Row className="py-3">
                {loader && (<div className="overlay">
                    <div className="loader"></div>
                </div>)}
                <Col xs={12} md={4} className="mt-2">
                    <Form.Label htmlFor="toDate">To </Form.Label>
                    <Form.Control type="date" name="toDate" id="toDate"
                                  value={toDate}
                                  onChange={(e) => {
                                      // dispatch(checkDateActions.toDateUpdate({toDate:e.target.value}))
                                      dispatch(checkDateActions.toDateUpdate({toDate: e.target.value}))
                                  }} placeholder="Select Date"
                    />
                </Col>
                <Col xs={12} md={4} className="mt-2">
                    <Form.Label htmlFor="location">Location </Form.Label>
                    <Select name="location" options={locations} isSearchable={true} isClearable
                            value={selectedLocation}
                            className="is-invalid" onChange={(newValue) => {
                        dispatch(checkDateActions.addLocation({location: newValue?.value}))
                    }}/>

                </Col>
            </Row>
            <Row>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                            className="mb-3"
                        >

                            <Tab eventKey="tailor" title="Tailor">

                                <FieldArray
                                    name="tailors"
                                    render={() => (<div>

                                        <Table striped bordered>
                                            <thead>

                                            <tr>

                                                <th>Type</th>
                                                <th>Line</th>
                                                <th>#</th>
                                                <th>Emp Code</th>
                                                <th>Pay Code</th>
                                                <th>Name</th>
                                                <th>Department</th>
                                                <th>Designation</th>
                                                <th>Date</th>
                                                <th>Ot Line</th>
                                                <th>In Time</th>
                                                <th>Out Time</th>
                                                {/* Add more table headers here */}
                                            </tr>

                                            </thead>
                                            <tbody>
                                            {location && formik.values.tailors.length > 0 && formik.values.tailors.map((tailor, index) => (
                                                <tr key={tailor.empCode}>
                                                    <td>{tailor.type}
                                                        <br/>
                                                        <ErrorMessage name={`tailors[${index}].type`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td><Select name="line" options={lines}
                                                                isSearchable={true} isClearable
                                                        // value={getLine(`values.tailors[${index}].line`)}
                                                                value={getLine(formik.values.tailors[index].line)}
                                                                className="is-invalid" onChange={(newValue) => {
                                                        formik.setFieldValue(`tailors[${index}].line`, newValue?.value)
                                                    }}/><br/>
                                                        <ErrorMessage name={`tailors[${index}].line`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.allocated}
                                                        <br/>
                                                        <ErrorMessage name={`tailors[${index}].allocated`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.empCode}
                                                        <br/>
                                                        <ErrorMessage name={`tailors[${index}].empCode`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.payCode}
                                                        <br/>
                                                        <ErrorMessage name={`tailors[${index}].payCode`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.empName}
                                                        <br/>
                                                        <ErrorMessage name={`tailors[${index}].empName`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.empDepartment}
                                                        <br/>
                                                        <ErrorMessage
                                                            name={`tailors[${index}].empDepartment`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.empDesignation} <br/>
                                                        <ErrorMessage
                                                            name={`tailors[${index}].empDesignation`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.day} <br/>
                                                        <ErrorMessage
                                                            name={`tailors[${index}].day`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>
                                                        <Select name="line" options={lines}
                                                                isSearchable={true} isClearable
                                                                value={getLine(formik.values.tailors[index].OTLine)}
                                                                className="is-invalid" onChange={(newValue) => {
                                                            formik.setFieldValue(`tailors[${index}].OTLine`, newValue?.value)
                                                        }}/><br/>
                                                        <ErrorMessage name={`tailors[${index}].OTLine`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.inTime} <br/>
                                                        <ErrorMessage
                                                            name={`tailors[${index}].inTime`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{tailor.outTime} <br/>
                                                        <ErrorMessage
                                                            name={`tailors[${index}].outTime`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </Table>
                                    </div>)}
                                />
                            </Tab>
                            <Tab eventKey="helper" title="Helper">
                                <FieldArray
                                    name="helpers"
                                    render={() => (<div>

                                        <Table striped bordered>
                                            <thead>

                                            <tr>
                                                <th>Type</th>
                                                <th>Line</th>
                                                <th>#</th>
                                                <th>Emp Code</th>
                                                <th>Pay Code</th>
                                                <th>Name</th>
                                                <th>Department</th>
                                                <th>Designation</th>
                                                <th>Date</th>
                                                <th>Ot Line</th>
                                                <th>In Time</th>
                                                <th>Out Time</th>
                                                {/* Add more table headers here */}
                                            </tr>

                                            </thead>
                                            <tbody>
                                            {location && formik.values.helpers.length > 0 && formik.values.helpers.map((helper, index) => (
                                                <tr key={helper.empCode}>
                                                    <td>{helper.type}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].type`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td><Select name="line" options={lines}
                                                                isSearchable={true} isClearable
                                                        // value={getLine(`values.helpers[${index}].line`)}
                                                                value={getLine(formik.values.helpers[index].line)}
                                                                className="is-invalid" onChange={(newValue) => {
                                                        formik.setFieldValue(`helpers[${index}].line`, newValue?.value)
                                                    }}/><br/>
                                                        <ErrorMessage name={`helpers[${index}].line`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.allocated}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].allocated`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.empCode}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].empCode`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.empCode}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].empCode`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.payCode}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].payCode`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.empName}
                                                        <br/>
                                                        <ErrorMessage name={`helpers[${index}].empName`}>{msg =>
                                                            <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.empDepartment}
                                                        <br/>
                                                        <ErrorMessage
                                                            name={`helpers[${index}].empDepartment`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.empDesignation} <br/>
                                                        <ErrorMessage
                                                            name={`helpers[${index}].empDesignation`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.day} <br/>
                                                        <ErrorMessage
                                                            name={`helpers[${index}].day`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>
                                                        <Select name="line" options={lines}
                                                                isSearchable={true} isClearable
                                                                value={getLine(formik.values.helpers[index].OTLine)}
                                                                className="is-invalid" onChange={(newValue) => {
                                                            formik.setFieldValue(`helpers[${index}].OTLine`, newValue?.value)
                                                        }}/><br/>
                                                        <ErrorMessage name={`helpers[${index}].OTLine`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.inTime} <br/>
                                                        <ErrorMessage
                                                            name={`helpers[${index}].inTime`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                    <td>{helper.outTime} <br/>
                                                        <ErrorMessage
                                                            name={`helpers[${index}].outTime`}>{msg => <div
                                                            className="text-danger">{msg}</div>}</ErrorMessage>
                                                    </td>
                                                </tr>))}
                                            </tbody>
                                        </Table>
                                    </div>)}
                                />
                            </Tab>
                        </Tabs>
                        <Col xs={12} md={5} className="d-flex align-items-center">
                            <Button type="submit" disabled={linesNotAssignedYet.length!==0} variant="primary" className="btn-sign mt-4">Submit</Button>
                        </Col>
                        {linesNotAssignedYet.length>0 &&(
                            <Col>
                               <span style={{color:"red"}}> {linesNotAssignedYet.join(',')} These Lines not Assigned Yet</span>
                            </Col>
                        )}

                    </Form>
                </FormikProvider>
            </Row>
            <Row>
                <Col className="col-md-6 col-12">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>{getLocation(location)} Count</Card.Title>
                            {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>
                                <Table striped bordered>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Line</th>
                                        <th>Unit</th>
                                        <th>Date</th>
                                        <th>Tailors</th>
                                        <th>Helpers</th>

                                        {/* Add more table headers here */}
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {lineWiseData.length > 0 && lineWiseData.map((item, index) => (
                                        <tr key={item.line}>
                                            <td>line</td>
                                            <td>{item.line_name}</td>
                                            <td>{item.unit_id}</td>
                                            <td>{item.production_date}</td>
                                            <td>{item.tailors}</td>
                                            <td>{item.helpers}</td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>{getLocation(location)} OT Count</Card.Title>
                            {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>
                                <Table striped bordered>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Line</th>
                                        <th>Unit</th>
                                        <th>Date</th>
                                        <th>Tailors</th>
                                        <th>Helpers</th>
                                        <th>Tailors OT Hours</th>
                                        <th>Helper OT Hours</th>

                                        {/* Add more table headers here */}
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {otLinesData.length > 0 && otLinesData.map((item, index) => (
                                        <tr key={item.line}>
                                            <td>line</td>
                                            <td>{item.line_name}</td>
                                            <td>{item.unit_id}</td>
                                            <td>{item.production_date}</td>
                                            <td>{item.tailors}</td>
                                            <td>{item.helpers}</td>
                                            <td>{item.tailors_ot_hours.toFixed(2)}</td>
                                            <td>{item.helpers_ot_hours.toFixed(2)}</td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>

    );
};