import {MainComponent} from "../../components/MainComponent";
import {Link} from "react-router-dom";
import {Row} from "react-bootstrap";
import Footer from "../../layouts/Footer";
import React from "react";

const SamRange = () => {
    return (
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Sam Range
                            </li>
                        </ol>
                    </div>
                </div>

                <Row className="g-3">
                    <h1>Sam Range</h1>
                </Row>
                <Row>
                    <h2>Sam Range</h2>
                </Row>
                <Footer/>
            </div>

        </MainComponent>
    );
};
export default SamRange
