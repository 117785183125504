import React from "react";
import Footer from "./../../../layouts/Footer";
import {Card, Col, Nav, ProgressBar, Row, Table,Form} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import {MainComponent} from "../../../components/MainComponent";
import Avatar from "../../../components/Avatar";

import img6 from "../../../assets/img/img6.jpg";
import img8 from "../../../assets/img/img8.jpg";
import img10 from "../../../assets/img/img10.jpg";
import img11 from "../../../assets/img/img11.jpg";
import img12 from "../../../assets/img/img12.jpg";

export default function Performance() {
    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };
    const seriesOne = [{
        data: [[0, 1000], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }, {
        data: [[0, 30], [1, 30], [2, 30], [3, 30], [4, 30], [5, 30], [6, 30], [7, 30], [8, 30], [9, 30], [10, 30], [11, 30], [12, 30], [13, 30], [14, 30], [15, 30], [16, 30], [17, 30], [18, 30], [19, 30], [20, 30], [21, 30], [22, 30], [23, 30], [24, 30], [25, 30], [26, 30], [27, 30], [28, 30], [29, 30], [30, 30]]
    }, {
        data: [[0, 800], [1, 600], [2, 500], [3, 400], [4, 600], [5, 500], [6, 800], [7, 1000], [8, 900], [9, 1100], [10, 1500], [11, 1700], [12, 1400], [13, 1300], [14, 1500], [15, 1300], [16, 1200], [17, 1000], [18, 1100], [19, 800], [20, 500], [21, 300], [22, 500], [23, 600], [24, 500], [25, 600], [26, 800], [27, 1000], [28, 900], [29, 800], [30, 500]]
    }];

    const optionOne = {
        chart: {
            parentHeightOffset: 0, stacked: true, toolbar: {show: false}
        }, dataLabels: {enabled: false}, grid: {
            borderColor: 'rgba(72,94,144, 0.07)', padding: {
                top: -20, left: 5
            }
        }, states: states, colors: ['#506fd9', '#fff', '#c8ccd4'], plotOptions: {
            bar: {
                columnWidth: '35%'
            },
        }, stroke: {
            curve: 'straight', lineCap: 'square', width: 0
        }, tooltip: {enabled: false}, fill: {opacity: 1}, legend: {show: false}, xaxis: {
            type: 'numeric', tickAmount: 11, decimalsInFloat: 0, labels: {
                style: {
                    fontSize: '11px'
                }
            }
        }, yaxis: {
            max: 4000, tickAmount: 8, labels: {
                style: {
                    colors: ['#a2abb5'], fontSize: '11px'
                }
            }
        }
    };

    const optionTwo = {
        chart: {
            parentHeightOffset: 0,
            type: 'line',
            toolbar: { show: false }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#c1ccf1', '#506fd9'],
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: -20,
                bottom: -5
            },
            yaxis: {
                lines: { show: false }
            }
        },
        fill: {
            type: ['solid', 'gradient'],
            gradient: {
                type: 'vertical',
                opacityFrom: 0.35,
                opacityTo: 0.2,
                gradientToColors: ['#f3f5fc']
            }
        },
        stroke: {
            width: [0, 1.5]
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 8,
            decimalsInFloat: 0,
            labels: {
                style: {
                    colors: '#6e7985',
                    fontSize: '9px'
                }
            },
            axisBorder: { show: false }
        },
        yaxis: {
            show: false,
            min: 0,
            max: 100
        },
        legend: { show: false },
        tooltip: { enabled: false }
    };


    return (<React.Fragment>

        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Performance</li>
                        </ol>
                    </div>
                </div>
<Row>
    <Col xs="12">
        <Card className="card-one">
            <Card.Header>
                <Card.Title as="h6">Agent Performance Score</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
            </Card.Header>
            <Card.Body>
                <ReactApexChart series={seriesOne} options={optionTwo} type="area" height={200} className="apex-chart-four mb-5" />

                <Table className="table-agent mb-0" responsive>
                    <thead>
                    <tr>
                        <th>
                            <Form.Check type="checkbox" />
                        </th>
                        <th>Agent ID</th>
                        <th>Agent Name</th>
                        <th>Status</th>
                        <th>Quota</th>
                        <th>Reached</th>
                        <th>Progress</th>
                        <th>Rating</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {[
                        {
                            "id": "00035",
                            "avatar": img6,
                            "name": "Allan R. Palban",
                            "email": "allan@themepixels.me",
                            "status": {
                                "badge": "success",
                                "label": "Active"
                            },
                            "quota": 120,
                            "reached": 64,
                            "progress": 50,
                            "star": ["fill","fill","fill","half-fill","line"]
                        }, {
                            "id": "00028",
                            "avatar": img8,
                            "name": "Charlene S. Plateros",
                            "email": "charlene@themepixels.me",
                            "status": {
                                "badge": "info",
                                "label": "Away"
                            },
                            "quota": 100,
                            "reached": 79,
                            "progress": 70,
                            "star": ["fill","fill","fill","fill","line"]
                        }, {
                            "id": "00025",
                            "avatar": img10,
                            "name": "Adrian M. Moniño",
                            "email": "adrian@themepixels.me",
                            "status": {
                                "badge": "success",
                                "label": "Active"
                            },
                            "quota": 130,
                            "reached": 108,
                            "progress": 75,
                            "star": ["fill","fill","fill","line","line"]
                        }, {
                            "id": "00024",
                            "avatar": img11,
                            "name": "Marianne B. Audrey",
                            "email": "marianne@themepixels.me",
                            "status": {
                                "badge": "warning",
                                "label": "Pending"
                            },
                            "quota": 110,
                            "reached": 45,
                            "progress": 50,
                            "star": ["fill","fill","fill","fill","line"]
                        }, {
                            "id": "00023",
                            "avatar": img12,
                            "name": "Carlyn Y. Salomon",
                            "email": "carlyn@themepixels.me",
                            "status": {
                                "badge": "secondary",
                                "label": "Inactive"
                            },
                            "quota": 125,
                            "reached": 32,
                            "progress": 25,
                            "star": ["fill","fill","fill","half-fill","line"]
                        }
                    ].map((item, index) => (
                        <tr key={index}>
                            <td>
                                <Form.Check type="checkbox" />
                            </td>
                            <td><span className="ff-numerals">{item.id}</span></td>
                            <td>
                                <div className="d-flex align-items-center gap-2">
                                    <Avatar img={item.avatar} />
                                    <div>
                                        <h6 className="mb-0">{item.name}</h6>
                                        <span className="fs-xs text-secondary">{item.email}</span>
                                    </div>
                                </div>
                            </td>
                            <td><span className={"badge bg-" + item.status.badge}>{item.status.label}</span></td>
                            <td><span className="ff-numerals">{item.quota}</span></td>
                            <td><span className="ff-numerals">{item.reached}</span></td>
                            <td>
                                <ProgressBar now={item.progress} className="ht-5 mb-0" />
                            </td>
                            <td>
                                <div className="d-flex gap-1 text-warning">
                                    {item.star.map((star, ind) => (
                                        <i key={ind} className={"ri-star-" + star}></i>
                                    ))}
                                </div>
                            </td>
                            <td>
                                <div className="d-flex justify-content-end">
                                    <Link to="" className="link-more"><i className="ri-more-2-fill"></i></Link>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    </Col>
</Row>
                <Row className="g-3">
                    <Card className="card-one card-product-inventory">
                        <Card.Header>
                            <Card.Title as="h6">Product Inventory</Card.Title>
                            <Nav className="nav-icon nav-icon-sm ms-auto">
                                <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                            </Nav>
                        </Card.Header>
                        <Card.Body className="p-3">
                            <ul className="legend mb-3 position-absolute">
                                <li>Remaining Quantity</li>
                                <li>Sold Quantity</li>
                            </ul>
                            <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={310}
                                            className="apex-chart-twelve mt-4 pt-3"/>
                        </Card.Body>
                    </Card>
                </Row>
                <Footer/>
            </div>
        </MainComponent>
    </React.Fragment>);
}


