import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const fetchUnits = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-units-list`, data, config);
    return res.data;
};

export const getUnit = async (unitId, token) => {
    const res = await axios.get(`${BASE_URL}/get-unit/${unitId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const updateUnit = async (
    unitId,
    workingHours,
    token,

) => {

    const data = JSON.stringify({
        id: unitId,
        working_hours:workingHours,
    });


    const res = await axios.post(`${BASE_URL}/update-unit`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};