import axios from "axios";
import { BASE_URL } from "../utils/urls";

export const fetchAllPermission = async (token) => {
    const res = await axios.get(`${BASE_URL}/all-permissions`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const createRole = async (roleName,permissions,plants,lines,token) => {
    const data = JSON.stringify({
        role_name:roleName,
        role_permissions:permissions,
        plants,lines
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/create-role`, data, config);
    return res.data;
};


export const getRole = async (roleId, token) => {
    const res = await axios.get(`${BASE_URL}/get-role/${roleId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};
export const updateRole = async (
    roleId,
    roleName,
    permissions,
    plants,
    lines,
    token
) => {
    const data = JSON.stringify({
        id: roleId,
        role_name: roleName,
        role_permissions: permissions,
        plants,lines
    });


    const res = await axios.post(`${BASE_URL}/update-role`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const getRoles = async (token) => {
    const res = await axios.get(`${BASE_URL}/get-roles`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const fetchRoleList = async (currentPage,itemsPerPage,token) => {
    const data = JSON.stringify({
        number_of_records:itemsPerPage,
        page_number:currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/role-list`, data, config);
    return res.data;
};


export const deleteRole = async (roleName,token) => {
    const data = JSON.stringify({
        role_name:roleName,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/delete-role`, data, config);
    return res.data;
};
