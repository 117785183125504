import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {workingHoursSchema} from "../../../schemas";
import {getUnit} from "../../../services/master/unit-service";

const initialValues = {id: "", unitName: "", workingHours: ""};
const WorkingHoursForm = ({isEditMode,clearForm, submitHandler, unitId, token}) => {
    const navigate=useNavigate();
    const [loader, setLoader] = useState(false);

    const {values, touched, resetForm, setFieldValue, errors, handleChange, handleSubmit} = useFormik({
        initialValues: initialValues,
        validationSchema: workingHoursSchema,
        onSubmit: ((values) => {
            submitHandler(values);
            resetForm();
        })
    })



    const getUnitById = async (id) => {
        setLoader(true);
        try {
            const result = await getUnit(id, token);
            if (result.status) {
                setFieldValue('id', result.data.id);
                setFieldValue('unitName', result.data.unit_name);
                setFieldValue('workingHours', result.data.working_hours);

            } else {
                toast.error(result.message);
                navigate("/working-hours");
            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }



    useEffect(() => {
        if (isEditMode && unitId > 0) {
            getUnitById(unitId);
        }
    }, [unitId, isEditMode]);



    useEffect(() => {
        resetForm();
    },[clearForm])

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>{isEditMode ? "Update" : "Create"} Working Hours</Card.Title>
                    {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                </Card.Header>
                <Card.Body className="p-4">
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={12} md={1} className="mt-2">
                                <Form.Label htmlFor="unitName" className="col-form-label">Unit Name</Form.Label>
                            </Col>

                            <Col xs={12} md={5} className="mt-2">
                                <Form.Control type="unitName" name="unitName" id="unitName" value={values.unitName}
                                              onChange={handleChange} placeholder="Unit Name" disabled
                                              isInvalid={!!errors.unitName && touched.unitName}/>
                                {errors.unitName && touched.unitName && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.unitName}
                                    </Form.Control.Feedback>
                                )}
                            </Col>

                            <Col xs={12} md={1} className="mt-2">
                                <Form.Label htmlFor="workingHours" className="col-form-label">Working Hours</Form.Label>
                            </Col>
                            <Col xs={12} md={5}  className="mt-2">
                                <Form.Control type="number" name="workingHours" id="workingHours" value={values.workingHours}
                                              onChange={handleChange} placeholder="Working Hours"
                                              isInvalid={!!errors.workingHours && touched.workingHours}/>
                                {errors.workingHours && touched.workingHours && (
                                    <Form.Control.Feedback type="invalid">
                                        {errors.workingHours}
                                    </Form.Control.Feedback>
                                )}
                            </Col>


                            <Col xs={12} md={5} className="mt-2">
                                <Button type="submit" variant="primary" className="btn-sign">Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};
export default WorkingHoursForm;
