import {Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {ErrorMessage, FieldArray, FormikProvider, useFormik} from "formik";
import {styleManPowerAllotmentValidationSchema} from "../../../../schemas";
import {useDispatch, useSelector} from "react-redux";
import {checkDateActions, checkDateSelector} from "../../../../store/date-slice";
import {
    fetchLineWiseData, fetchLineWiseOTData, fetchStyleWiseData, styleManPowerUpdate
} from "../../../../services/input/man-power-allotment-service"
import {loginSelector} from "../../../../store/login-slice";
import {toast} from "react-toastify";

const locations = [{label: "New Zeel Rainwear", value: 1}];

export default function StyleManPowerAllocationForm() {
    const [loader, setLoader] = useState(false);
    const {toDate, location} = useSelector(checkDateSelector);
    const {token} = useSelector(loginSelector);
    const dispatch = useDispatch();

    const [selectedLocation, setSelectedLocation] = useState(null);
    const [lineWiseData, setLineWiseData] = useState([])
    const [otLinesData, setOtLinesData] = useState([])
    const [styleWiseData, setStyleWiseData] = useState([])
    const [totalEmp, setTotalEmp] = useState({lineTailors: "", lineHelpers: ""})
    const [canISubmit, setCanISubmit] = useState(false);


    async function addStyleData(styles) {
        setLoader(true)
        try {
            if (styles.length > 0) {
                const result = await styleManPowerUpdate(styles, toDate, location, token)
                if (result.status) {
                    toast.success(result.message)
                }
            } else {
                toast.error("there is no data");
            }

            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }
    }

    const formik = useFormik({
        initialValues: {
            styles: [],
        }, validationSchema: styleManPowerAllotmentValidationSchema, onSubmit: ((values) => {
            addStyleData(values.styles);
            // formik.resetForm()
        })
    })

    console.log(formik.values.styles,"styles");


    function getLocation(newValue) {
        if (newValue) {
            return locations.find((lc) => lc.value === Number(newValue)).label;
        }
    }

    async function getLineData() {
        setLoader(true);
        try {
            const result = await fetchLineWiseData(toDate, location, token);
            if (result.status) {
                let totalTailors = 0;
                let totalHelpers = 0;
                result.data.forEach(item => {
                    totalTailors += Number(item.tailors) ?? 0;
                    totalHelpers += Number(item.helpers) ?? 0;
                });
                setTotalEmp({...totalEmp, lineTailors: totalTailors, lineHelpers: totalHelpers});

                setLineWiseData(result.data)
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log(e)
        }
    }

    async function getOTLineData() {
        setLoader(true);
        try {
            const result = await fetchLineWiseOTData(toDate, location, token);
            if (result.status) {
                setOtLinesData(result.data)
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
            console.log(e)
        }
    }

    async function getStyleWiseData() {
        setLoader(true)
        try {
            const result = await fetchStyleWiseData(toDate, location, token);
            if (result.status) {
                setStyleWiseData(result.data);
            }
            setLoader(false)
        } catch (e) {
            console.log(e)
            setLoader(false)
        }
    }

    useEffect(() => {
        if (location) {
            setSelectedLocation(locations.find((lc) => lc.value === Number(location)))
        }
    }, [location]);


    useEffect(() => {
        if (toDate && location) {
            getLineData()
            getOTLineData();
        }
    }, [toDate, location]);

    useEffect(() => {
        if (lineWiseData.length > 0) {
            getStyleWiseData();
        }
    }, [lineWiseData]);

    useEffect(() => {
        const arrayData = styleWiseData.map((style) => ({
            type: style.type ?? 'Line',
            line: style.line_name ?? '',
            lineId: style.line_id ?? '',
            runDays: style.run_days ?? '',
            style: style.style_name ?? '',
            styleId: style.style_id ?? '',
            orderQty: style.order_no,
            samValue: style.sam_value ?? '',
            shift: style.shift_hours ?? '',
            totalTailors: style.total_tailors ?? '',
            tailors: style.tailors ?? '',
            totalHelpers: style.total_helpers ?? '',
            helpers: style.helpers ?? '',
            tailorOT: style.tailor_ot,
            helperOT: style.helper_ot,
            tailorsOTHours:style.ot_tailors_hours,
            helpersOTHours:style.ot_helpers_hours,
            totalCutting: style.total_cutting_qty ?? "",
            stitchingToday: style.production_qty ?? "",
            productionDate: style.production_date ?? "",
            effPlan: style.eff_plan ?? "",
        }))


        formik.setFieldValue('styles', arrayData)
    }, [styleWiseData]);

    useEffect(() => {
        let totalTailors = 0;
        let totalHelpers = 0;
        formik.values.styles.forEach(item => {
            totalTailors += Number(item.tailors) ?? 0;
            totalHelpers += Number(item.helpers) ?? 0;
        });
        // console.log(totalHelpers , totalEmp.lineTailors)
        // console.log(typeof totalHelpers,typeof totalEmp.lineHelpers);
        setCanISubmit(false)
        if (totalTailors > totalEmp.lineTailors || totalHelpers > totalEmp.lineHelpers) {
            setCanISubmit(true)
        }
    }, [formik.values.styles]);

    return (<>
            <Row className="py-3">
                {loader && (<div className="overlay">
                    <div className="loader"></div>
                </div>)}
                <Col xs={12} md={4} className="mt-2">
                    <Form.Label htmlFor="toDate">To </Form.Label>
                    <Form.Control type="date" name="toDate" id="toDate"
                                  value={toDate}
                                  onChange={(e) => {
                                      // dispatch(checkDateActions.toDateUpdate({toDate:e.target.value}))
                                      dispatch(checkDateActions.toDateUpdate({toDate: e.target.value}))
                                  }} placeholder="Select Date"
                    />
                </Col>
                <Col xs={12} md={4} className="mt-2">
                    <Form.Label htmlFor="location">Location </Form.Label>
                    <Select name="location" options={locations} isSearchable={true} isClearable
                            value={selectedLocation}
                            className="is-invalid" onChange={(newValue) => {
                        dispatch(checkDateActions.addLocation({location: newValue.value}))
                    }}/>

                </Col>
            </Row>
            <Row>
                <Col className="col-md-6 col-12">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>{getLocation(location)} Count</Card.Title>
                            {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>
                                <Table striped bordered>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Line</th>
                                        <th>Unit</th>
                                        <th>Date</th>
                                        <th>Tailors</th>
                                        <th>Helpers</th>


                                        {/* Add more table headers here */}
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {lineWiseData.length > 0 && lineWiseData.map((item, index) => (
                                        <tr key={item.line}>
                                            <td>line</td>
                                            <td>{item.line_name}</td>
                                            <td>{item.unit_id}</td>
                                            <td>{item.production_date}</td>
                                            <td>{item.tailors}</td>
                                            <td>{item.helpers}</td>

                                        </tr>))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="">
                    <Card className="card-settings mt-4">
                        <Card.Header>
                            <Card.Title>{getLocation(location)} OT Count</Card.Title>
                            {/*<Card.Text> {isEditMode ? "Update" : "add"} fields in forms</Card.Text>*/}
                        </Card.Header>
                        <Card.Body className="p-4">
                            <div>
                                <Table striped bordered>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Line</th>
                                        <th>Unit</th>
                                        <th>Date</th>
                                        <th>Tailors</th>
                                        <th>Helpers</th>
                                        <th>Tailors OT Hours</th>
                                        <th>Helper OT Hours</th>


                                        {/* Add more table headers here */}
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {otLinesData.length > 0 && otLinesData.map((item, index) => (
                                        <tr key={item.line}>
                                            <td>line</td>
                                            <td>{item.line_name}</td>
                                            <td>{item.unit_id}</td>
                                            <td>{item.production_date}</td>
                                            <td>{item.tailors}</td>
                                            <td>{item.helpers}</td>
                                            <td>{item.tailors_ot_hours.toFixed(2)}</td>
                                            <td>{item.helpers_ot_hours.toFixed(2)}</td>
                                        </tr>))}
                                    </tbody>
                                </Table>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5">
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <FieldArray
                            name="styles"
                            render={() => (<div>
                                <Table hover responsive bordered striped>
                                    <thead>
                                    <tr>
                                        <th>Type</th>
                                        <th>Line</th>
                                        <th>Prod Date</th>
                                        <th>Run Day</th>
                                        <th>Style</th>
                                        <th>Order No</th>
                                        <th>Sam Value</th>
                                        <th>Shift Hours</th>
                                        <th>Tailors</th>
                                        <th>Helper</th>
                                        <th>Tailor OT</th>
                                        <th>Helper OT</th>
                                        <th>Total Cutting</th>
                                        <th>Stitching Today</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lineWiseData.length > 0 && formik.values.styles.length > 0 && formik.values.styles.map((style, index) => (
                                        <tr>

                                            <td>{style.type ?? 'Line'}</td>
                                            <td>{style.line ?? ''}</td>
                                            <td>{style.productionDate}</td>
                                            <td>{style.runDays}</td>
                                            <td>{style.style} <br/> <ErrorMessage
                                                name={`styles[${index}].style`}>{msg =>
                                                <div
                                                    className="text-danger">{msg}</div>}</ErrorMessage></td>
                                            <td>{style.orderQty}</td>
                                            <td>{style.samValue} <br/>
                                                <ErrorMessage name={`styles[${index}].samValue`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td>
                                                <Form.Control type="text" name={`styles[${index}].shift`}
                                                              value={formik.values.styles[index].shift}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue(`styles[${index}].shift`, e.target.value)
                                                              }} placeholder="Shift Hours"
                                                />
                                                <br/>
                                                <ErrorMessage name={`styles[${index}].shift`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td> <span>{formik.values.styles[index].totalTailors}</span> /
                                                <Form.Control type="text" name={`styles[${index}].tailors`}
                                                              value={formik.values.styles[index].tailors}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue(`styles[${index}].tailors`, e.target.value)
                                                              }} placeholder="Tailors"
                                                />
                                                <br/>
                                                <ErrorMessage name={`styles[${index}].tailors`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td>
                                                <span>{formik.values.styles[index].totalHelpers}</span> /
                                                <Form.Control type="text" name={`styles[${index}].helpers`}
                                                              value={formik.values.styles[index].helpers}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue(`styles[${index}].helpers`, e.target.value)
                                                              }} placeholder="Helper"
                                                />
                                                <br/>
                                                <ErrorMessage name={`styles[${index}].helpers`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td>
                                                {formik.values.styles[index].tailorsOTHours}/
                                                <Form.Control type="text" name={`styles[${index}].tailorOT`}
                                                              value={formik.values.styles[index].tailorOT}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue(`styles[${index}].tailorOT`, e.target.value)
                                                              }} placeholder="Tailor OT"
                                                />
                                                <br/>
                                                <ErrorMessage name={`styles[${index}].tailorOT`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td>
                                                {formik.values.styles[index].helpersOTHours}/
                                                <Form.Control type="text" name={`styles[${index}].helperOT`}
                                                              value={formik.values.styles[index].helperOT}
                                                              onChange={(e) => {
                                                                  formik.setFieldValue(`styles[${index}].helperOT`, e.target.value)
                                                              }} placeholder="Helper OT"
                                                />
                                                <br/>
                                                <ErrorMessage name={`styles[${index}].helperOT`}>{msg =>
                                                    <div
                                                        className="text-danger">{msg}</div>}</ErrorMessage>
                                            </td>
                                            <td>{style.totalCutting}</td>
                                            <td>{style.stitchingToday}</td>


                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </div>)}
                        />
                        <Col xs={12} md={5} className="d-flex align-items-center">
                            <Button type="submit" disabled={canISubmit} variant="primary"
                                    className="btn-sign mt-4">Submit</Button>
                        </Col>
                    </Form>
                </FormikProvider>
            </Row>
        </>

    );
};