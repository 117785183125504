import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const fetchStyles = async (token) => {
    const res = await axios.get(`${BASE_URL}/get-styles`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};