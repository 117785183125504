import {MainComponent} from "../../components/MainComponent";
import {Link} from "react-router-dom";
import Footer from "../../layouts/Footer";
import React, {useEffect, useState} from "react";
import CostKpis from "./indicators/cost-kpis";
import QualityKpis from "./indicators/quality-kpis";
import DeliveryKpis from "./indicators/delivery-kpis";
import HrKpis from "./indicators/hr-kpis";
import {useDispatch, useSelector} from "react-redux";
import {loginSelector} from "../../store/login-slice";
import {Button, Col, Form, Row} from "react-bootstrap";
import {checkDateActions, checkDateSelector} from "../../store/date-slice";
import Select from "react-select";

const locations = [{label: "New Zeel Rainwear", value: 1}];

const Dashboard = () => {
    const dispatch = useDispatch();
    const {access} = useSelector(loginSelector);
    const {fromDate, toDate} = useSelector(checkDateSelector);
    const {location} = useSelector(checkDateSelector);
    const [selectedLocation,setSelectedLocation]=useState(null);

    function updateDates() {
        // Get the current date
        const currentDate = new Date();

        // Get the starting date of the current month
        const startingDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

       // Format the dates as "YYYY-MM-DD"
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const startingDateFormatted = formatDate(startingDateOfMonth);
        const todayDateFormatted = formatDate(currentDate);
        dispatch(checkDateActions.updateDates({fromDate: startingDateFormatted, toDate: todayDateFormatted}))

    }



    const getComponent = (kpi) => {
        switch (kpi) {
            case "Cost KPIs":
                return (
                    <CostKpis fromDate={fromDate} toDate={toDate} location={location} />
                );
            case "Quality KPIs":
                return <QualityKpis  fromDate={fromDate} toDate={toDate} location={location} />;
            case "Delivery KPI":
                return <DeliveryKpis/>;
            case "HR KPI":
                return <HrKpis/>;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (location) {
            setSelectedLocation(locations.find((lc) => lc.value === Number(location)))
        }
    }, [location]);

    useEffect(() => {
        updateDates()
    }, []);


    return (
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                        </ol>
                    </div>
                </div>
                <Row style={{border: "1px solid #0d6efd"}} className="py-3">
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="fromDate">From
                            Date</Form.Label>
                        <Form.Control type="date" name="fromDate" id="fromDate"
                                      value={fromDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.fromDateUpdate({fromDate:e.target.value}))}} placeholder="fromDate"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="toDate">To
                            Date</Form.Label>
                        <Form.Control type="date" name="toDate" id="toDate"
                                      value={toDate}
                                      onChange={(e) => {
                                          dispatch(checkDateActions.toDateUpdate({toDate:e.target.value}))
                                      }} placeholder="To Date"
                        />

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Form.Label htmlFor="location">Location </Form.Label>
                        <Select name="location" options={locations} isSearchable={true} isClearable
                                value={selectedLocation}
                                className="is-invalid" onChange={(newValue) => {
                            dispatch(checkDateActions.addLocation({location: newValue?.value}))
                        }}/>

                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <Button type="submit" className="btn btn-primary mt-4">Submit</Button>
                    </Col>
                </Row>
                {access.map((access,index) => <div key={index}>{ getComponent(access)}</div>)}
                <Footer/>
            </div>
        </MainComponent>
    );
};

export default Dashboard;

