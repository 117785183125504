import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MainComponent} from "../../components/MainComponent";
import {
    fetchMenuConfigurationSetting,
    fetchModuleListForMenu,
    menuConfigurationSetting
} from "../../services/menu-service";
import {useSelector} from "react-redux";
import {loginSelector} from "../../store/login-slice";
import {toast} from "react-toastify";

const menus = ['Left Menu', 'Right Menu'];
export default function MenuConfiguration() {
    const [moduleList, setModuleList] = useState([]);
    const [loader, setLoader] = useState(false);
    const {token} = useSelector(loginSelector);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCheckboxChange = (moduleId, menu) => {
        const newItem = {moduleId, menu};
        setSelectedItems((prevSelectedItems) => {
            const itemAlreadySelected = prevSelectedItems.some(
                (item) => item.moduleId === moduleId && item.menu === menu
            );

            if (itemAlreadySelected) {
                return prevSelectedItems.filter(
                    (item) => item.moduleId !== moduleId || item.menu !== menu
                );
            } else {
                return [...prevSelectedItems, newItem];
            }
        });
    };

    const getModuleList = async () => {
        setLoader(true);
        try {
            const result = await fetchModuleListForMenu(token);
            if (result.status) {
                setModuleList(result.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err)
            setLoader(false);
        }

    }

    function formatString(inputString) {
        // Remove special characters using regular expression and replace with spaces
        let cleanedText = inputString.replace(/[^\w\s]/g, ' ');

        // Capitalize the first letter of each word
        return cleanedText.replace(/\b\w/g, (match) => match.toUpperCase());
    }


    async function getMenuConfigurationSetting() {
        setLoader(true);
        try {
            const result =await fetchMenuConfigurationSetting(token);
            if (result.status){
                setSelectedItems(result.data);
            }
            setLoader(false);
        }catch(e) {
            setLoader(false);
        }
    }

    useEffect(() => {
    	if (moduleList.length>0) {
            getMenuConfigurationSetting();
        }
    }, [moduleList]);

    useEffect(() => {
        (async () => {
            await getModuleList();
        })();
    }, []);

    async function submitConfig() {
        setLoader(true);
        try {
            const result = await menuConfigurationSetting(selectedItems,token);
            if (result.status){
                toast.success(result.message);
                getMenuConfigurationSetting();
            }else{
                toast.error(result.message);
            }
            setLoader(false);
        } catch (err) {
            setLoader(false);
        }
    }

    return (
        <MainComponent>
            <div className="main main-app p-3 p-lg-4">
                {loader && (<div className="overlay">
                    <div className="loader"></div>
                </div>)}
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link href="#" to="/">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Menu Configuration</li>
                        </ol>
                    </div>
                </div>
                <div>
                    <h1>Menu Configuration</h1>
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Main Module</th>
                        <th scope="col">Submodule</th>
                    </tr>
                    </thead>
                    <tbody>

                    {moduleList.length > 0 && moduleList.map((module) => {
                        return (<tr key={module.id}>
                            <th scope="row">{module.id}</th>
                            <td>{formatString(module.module_name)}</td>
                            <td>{module.submodules.map((submodule) => {
                                return (<div key={submodule.id} className="mb-2">
                                    <li><b>{formatString(submodule.submodule_name)}</b></li>
                                    {menus.map((menu) =>{
                                       return  <label  key={`${submodule.id}+${menu}`}>
                                           <input
                                               type="checkbox"
                                               checked={selectedItems.some(
                                                   (selectedItem) =>
                                                       selectedItem.moduleId === submodule.id && selectedItem.menu === menu
                                               )}
                                               onChange={() => handleCheckboxChange(submodule.id, menu)}
                                               className="m-2"
                                           />
                                           {menu}
                                       </label>
                                    })}
                                </div>)
                            })}</td>

                        </tr>)
                    })}


                    </tbody>
                </table>
                <div className="d-flex justify-content-center mt-2 mb-5">
                    <button onClick={submitConfig} className="btn btn-primary">Update Menu Setting</button>
                </div>

            </div>
        </MainComponent>
    );
};
