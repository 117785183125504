import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {FieldArray, FormikProvider, useFormik, ErrorMessage} from "formik";
import {StyleByDateUnit} from "../../../schemas";
import {fetchStylesByDateUnit, getDHUInput} from "../../../services/input/dhu-input-service";

const initialValues = {
    inputDate: "",
    unitId: 1,
    styles: [],
};
const DHUForm = ({isEditMode, clearForm, submitHandler, dhuId, token}) => {
    const [loader, setLoader] = useState(false);
    const [styles, setStyles] = useState([]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: StyleByDateUnit,
        onSubmit: ((values) => {
            submitHandler(values);
            formik.resetForm();
        })
    })


    const getDHUInputById = async (id) => {
        setLoader(true);
        try {
            const result=await getDHUInput(id,token);
            if (result.status){
              formik.setFieldValue('inputDate',result.data.quality_check_date);
              formik.setFieldValue('unitId',result.data.unit_id);
              setStyles(result.data.styles);
            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }
    useEffect(() => {
        if (isEditMode && dhuId > 0) {
            getDHUInputById(dhuId);
        }
    }, [dhuId, isEditMode]);

    const getStylesByDateUnit = async (inputDate, Department) => {
        setLoader(true);
        try {
            const result = await fetchStylesByDateUnit(inputDate, Department, token);
            if (result.status) {
                setStyles(result.data);
            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
        }

    }

    useEffect(() => {
        if (formik.values.inputDate && formik.values.unitId) {
            getStylesByDateUnit(formik.values.inputDate, formik.values.unitId);
        }
    }, [formik.values.inputDate, formik.values.unitId])


    useEffect(() => {
        formik.resetForm();
    }, [clearForm])

    useEffect(() => {

        if (styles.length > 0) {
            formik.setFieldValue('styles', []);
            const arrayData = styles.map((style) => ({
                unitId:style.unit??'',
                lineId: style.line_id ?? '',
                styleId: style.style_id?? '',
                floorId: style.floor_id ?? '',
                styleName: style.style_name ?? '',
                lineName: style.line_name,
                floorName: style.floor_name ?? '',
                totalQty:style.production_qty,
                productionDate:style.production_date,
                passQty:style.passed_qty??"",
                failQty:style.failed_qty??"",
            }))
            formik.setFieldValue('styles', arrayData);

        }else {
            formik.setFieldValue('styles', []);
        }
    }, [styles]);


    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>{isEditMode ? "Update" : "Create"} DHU Input</Card.Title>
                </Card.Header>
                <Card.Body className="p-4">
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                {/*<Col xs={12} md={2}>*/}
                                {/*    /!*<Form.Label htmlFor="Date" className="col-form-label"><h6>Date</h6></Form.Label>*!/*/}
                                {/*    <h6 className="col-form-label">Date</h6>*/}
                                {/*</Col>*/}
                                <Col xs={12} md={4} className="mt-2">
                                    <h6 className="col-form-label">Date</h6>
                                    <Form.Control type="date" name="inputDate" id="Date" value={formik.values.inputDate}
                                                  onChange={(e) => {
                                                      formik.setFieldValue('inputDate', e.target.value)
                                                  }} placeholder="Date"
                                                  isInvalid={!!formik.errors.inputDate && formik.touched.inputDate}
                                                  className="form-control"/>
                                    {formik.errors.inputDate && formik.touched.inputDate && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.inputDate}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                {/*<Col xs={12} md={2} className="ml-5">*/}
                                {/*    /!*<Form.Label htmlFor="unitId" className="col-form-label"><h6>Unit</h6></Form.Label>*!/*/}
                                {/*    <h6 className="col-form-label">Unit</h6>*/}
                                {/*</Col>*/}
                                <Col xs={12} md={4} className="mt-2">
                                    <h6 className="col-form-label">Unit</h6>
                                    <Form.Control type="text" name="unitId" id="unitId"
                                                  value="New Zeel Rainwear"
                                                  onChange={(e) => {
                                                      // formik.setFieldValue('unitId', e.target.value)
                                                  }} placeholder="Company Name"
                                                  disabled={true}
                                                  isInvalid={!!formik.errors.unitId && formik.touched.unitId}/>
                                    {formik.errors.unitId && formik.touched.unitId && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.unitId}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                            </Row>
                            <Row>

                                {formik.values.inputDate !== "" && formik.values.styles.length > 0 && (
                                    <FieldArray
                                        name="styles"
                                        render={() => (
                                            <div>
                                                {formik.values.styles.length > 0 && formik.values.styles.map((style, index) => (
                                                    <Row key={index}>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Floor*/}
                                                            {/*    Name</Form.Label>*/}
                                                            <h6 className="col-form-label">Floor Name</h6>
                                                            <Form.Control type="text"
                                                                          name={`styles[${index}].floorName`}
                                                                          value={formik.values.styles[index].floorName ?? "no line"}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].floorName`, e.target.value)
                                                                          }} placeholder="Floor"
                                                                          disabled={true}
                                                            />
                                                            <ErrorMessage name={`styles[${index}].floorName`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Line</Form.Label>*/}
                                                            <h6 className="col-form-label">Line</h6>
                                                            <Form.Control type="text" name={`styles[${index}].lineName`}
                                                                          value={formik.values.styles[index].lineName ?? "no line"}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].lineName`, e.target.value)
                                                                          }} placeholder="Line"
                                                                          disabled={true}
                                                            />
                                                            <ErrorMessage name={`styles[${index}].lineName`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Line</Form.Label>*/}
                                                            <h6 className="col-form-label">Style</h6>
                                                            <Form.Control type="text" name={`styles[${index}].styleName`}
                                                                          value={formik.values.styles[index].styleName ?? "no line"}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].styleName`, e.target.value)
                                                                          }} placeholder="Style"
                                                                          disabled={true}
                                                            />
                                                            <ErrorMessage name={`styles[${index}].styleName`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Man*/}
                                                            {/*    Power</Form.Label>*/}
                                                            <h6 className="col-form-label">Total Quantity</h6>
                                                            <Form.Control type="text" name={`styles[${index}].totalQty`}
                                                                          value={formik.values.styles[index].totalQty}
                                                                          disabled
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].totalQty`, e.target.value)
                                                                          }} placeholder="Total Quantity"
                                                            />

                                                            <ErrorMessage name={`styles[${index}].totalQty`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">ManPower*/}
                                                            {/*    Cost</Form.Label>*/}
                                                            <h6 className="col-form-label">Defected Items</h6>
                                                            <Form.Control type="text"
                                                                          name={`styles[${index}].failQty`}
                                                                          value={formik.values.styles[index].failQty}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].failQty`, e.target.value)
                                                                          }} placeholder="Defect Items"
                                                            />
                                                            <ErrorMessage name={`styles[${index}].failQty`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">OT Hours</Form.Label>*/}
                                                            <h6 className="col-form-label">Passed Items</h6>
                                                            <Form.Control type="text" name={`styles[${index}].passQty`}
                                                                          value={formik.values.styles[index].passQty}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`styles[${index}].passQty`, e.target.value)
                                                                          }} placeholder="Pass Items"
                                                            />
                                                            <ErrorMessage name={`styles[${index}].passQty`}>{msg => <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        )}
                                    />
                                )}


                                <Col xs={12} md={5} className="d-flex align-items-center">
                                    <Button type="submit" variant="primary" className="btn-sign mt-4">Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </FormikProvider>
                </Card.Body>
            </Card>
        </>
    );
};
export default DHUForm;
