import React, {useCallback, useState} from "react";
import {Button, Card, Col, Container, Form, Nav, Row} from "react-bootstrap";
import {Link, useNavigate, useParams} from "react-router-dom";

import pageSvg from "../../assets/svg/forgot_password.svg";
import {useFormik} from "formik";
import {passwordResetSchema} from "../../schemas";
import {resetPassword} from "../../services/auth-services";
import {toast} from "react-toastify";

const initialValues = {
    password: "",confirm_password: "",
};

export default function ResetPassword() {
    const [loader, setLoader] = useState(false);

    const { token } = useParams();
    const navigate = useNavigate();
    const submitResetPassword = useCallback(
        async (password1) => {
            try {
                setLoader(true);
                const res = await resetPassword(password1,token);
                if (res.status) {
                    toast(res.message);
                } else {
                  toast(res.message);
                }
                setLoader(false);

            } catch (error) {
                // console.log(error)
            }
        },
        [token]
    );

    const {values, errors, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
        initialValues: initialValues, validationSchema: passwordResetSchema, onSubmit: ((values) => {
            submitResetPassword(values.password);
            resetForm();
        })
    })
    document.body.classList.remove("sidebar-show");

    return (
        <div className="page-auth">
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <div className="header">
                <Container>
                    <Link to="/" className="header-logo">SmartFactory</Link>
                    <Nav className="nav-icon">
                        <Nav.Link href=""><i className="ri-twitter-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-github-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-dribbble-line"></i></Nav.Link>
                    </Nav>
                </Container>
            </div>

            <div className="content">
                <Container>
                    <Card className="card-auth">
                        <Card.Body className="text-center">
                            <div className="mb-5">
                                <object type="image/svg+xml" data={pageSvg} className="w-50"
                                        aria-label="svg image"></object>
                            </div>
                            <Card.Title>Reset your password</Card.Title>
                            <Card.Text className="mb-5">Enter your username or email address and we will send you a link
                                to reset your password.</Card.Text>

                            <Form onSubmit={handleSubmit}>
                                <Row className="g-2">
                                    <Col sm="12"><Form.Control type="password" name="password" value={values.password}
                                                              onChange={handleChange} onBlur={handleBlur}
                                                              placeholder="Password"
                                                              isInvalid={!!errors.password}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.password}
                                        </Form.Control.Feedback>
                                    </Col>

                                    <Col sm="12"><Form.Control type="text" name="confirm_password" value={values.confirm_password}
                                                              onChange={handleChange} onBlur={handleBlur}
                                                              placeholder="Confirm Password"
                                                              isInvalid={!!errors.confirm_password}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.confirm_password}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm="12"><Button type="submit" variant="primary">Reset</Button></Col>

                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </div>
    );
}
