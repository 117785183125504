import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const fetchDateWiseFinishingData = async (fromData, toDate,unitId, token) => {
    const data = JSON.stringify({
        from_date: fromData,
        to_date: toDate,
        unit_id: unitId,
    });
    const res = await axios.post(`${BASE_URL}/finishing-profit-loss-date-wise`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchDayWiseData = async (fromDate,selectedDate,unitId, token) => {
    const data = JSON.stringify({
        from_date:fromDate,
        selected_date:selectedDate,
        unit_id:unitId
    });
    const res = await axios.post(`${BASE_URL}/finishing-profit-loss-day-wise`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};
