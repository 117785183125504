import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import React, {useEffect, useState} from "react";
import {FieldArray, FormikProvider, useFormik, ErrorMessage} from "formik";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {hrInputSchema} from "../../../schemas";
import {fetchFloorsByDateDepartment} from "../../../services/master/floor-service";
import Select from "react-select";
import {
    fetchFinishingCuttingDataIfAvailable,
    fetchLinesByDateDepartmentFloor,
    getHRInput
} from "../../../services/input/hr-input-service";

const initialValues = {
    inputDate: "",
    unitId: 1,
    floor: "",
    department: "",
    lines: [],
    manPower: "",
    manPowerCost: "",
    OTHours: "",
    OTHoursCost: "",
};
const departmentType = [{label: "Cutting", value: 1}, {label: "Sewing", value: 2}, {label: "Finishing", value: 3}];
const HrForm = ({isEditMode, clearForm, submitHandler, hrInputId, token}) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [floors, setFloors] = useState([]);
    const [selectedFloor, setSelectedFloor] = useState({});
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [lines, setLines] = useState([])


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: hrInputSchema,
        onSubmit: ((values) => {
            submitHandler(values);
            formik.resetForm();
            setSelectedFloor({});
            setSelectedDepartment({});
        })
    })



    const getHrInputById = async (id) => {
        setLoader(true);
        try {

            const result = await getHRInput(id, token);
            if (result.status) {
                formik.setFieldValue('inputDate', result.data.production_date);
                formik.setFieldValue('unitId', result.data.unit_id);
                formik.setFieldValue('floor', result.data.floor_id);
                formik.setFieldValue('department', result.data.department);
                if (result.data.department === 2) {
                    setLines(result.data.lines);
                } else {
                    formik.setFieldValue('manPower', result.data.man_power);
                    formik.setFieldValue('manPowerCost', result.data.man_power_cost);
                    formik.setFieldValue('OTHours', result.data.ot_hours);
                    formik.setFieldValue('OTHoursCost', result.data.ot_hours_cost);
                }
                setSelectedDepartment(departmentType.filter((department) => department.value === result.data.department))
            } else {
                toast.error(result.message);
                navigate("/input-forms/hr-input");
            }
        } catch (e) {
            console.log(e)
        }
        setLoader(false);
    }

    async function getLinesByFloor(unitId, date, floor, department) {
        setLoader(true);
        try {
            const result = await fetchLinesByDateDepartmentFloor(unitId, date, floor, department, token);
            if (result.status) {
                if (result.data.length === 0) {
                    toast.error("there are no lines");
                }
                setLines(result.data);
            } else {
                toast.error(result.message)
            }
            setLoader(false);

        } catch (e) {
            setLoader(false);
            console.log(e)
        }

    }
    async function getDataIfAvailable(unitId, date, floor, department) {
        setLoader(true);
        try {
            const result = await fetchFinishingCuttingDataIfAvailable(unitId, date, floor, department, token);
            if (result.status) {
               formik.setFieldValue('manPower',result.data?.man_power??"")
               formik.setFieldValue('manPowerCost',result.data?.man_power_cost??"")
               formik.setFieldValue('OTHours',result.data?.ot_hours??"")
               formik.setFieldValue('OTHoursCost',result.data?.ot_hours_cost??"")
            }
            setLoader(false);

        } catch (e) {
            setLoader(false);
            console.log(e)
        }

    }


    useEffect(() => {
        if (isEditMode && hrInputId > 0) {
            getHrInputById(hrInputId);
        }
    }, [hrInputId, isEditMode]);

    const getFloorsByDateDepartment = async (inputDate,Department) => {
        setLoader(true);
        try {
            const result = await fetchFloorsByDateDepartment(inputDate,Department,token);
            if (result.status) {
                if (result.data.length>0){
                    setFloors(result.data.map(item => ({
                        value: item.id,
                        label: item.floor_name
                    })));
                }else {
                    setFloors([]);
                }

            }
            setLoader(false);
        } catch (e) {
            setLoader(false);
        }

    }

    useEffect(() => {
        if (formik.values.inputDate && formik.values.department){
            getFloorsByDateDepartment(formik.values.inputDate,formik.values.department);
        }
    }, [formik.values.inputDate,formik.values.department])


    useEffect(() => {
        if (formik.values.inputDate && formik.values.floor && formik.values.department === 2 && isEditMode === false) {
            getLinesByFloor(formik.values.unitId, formik.values.inputDate, formik.values.floor, formik.values.department)
        }
    }, [formik.values.unitId, formik.values.inputDate, formik.values.floor, formik.values.department]);

    useEffect(() => {
        if ((formik.values.department === 1 || formik.values.department === 3) &&  formik.values.inputDate && formik.values.floor  && isEditMode === false) {
            getDataIfAvailable(formik.values.unitId, formik.values.inputDate, formik.values.floor, formik.values.department)
        }
    }, [formik.values.unitId, formik.values.inputDate, formik.values.floor, formik.values.department]);

    useEffect(() => {
        if (formik.values.floor && floors.length > 0) {
            setSelectedFloor(floors.filter((floor) => floor.value === formik.values.floor));
        }
    }, [formik.values.floor, floors]);

    useEffect(() => {
        formik.resetForm();
    }, [clearForm])


    useEffect(() => {

        if (formik.values.department && lines.length > 0) {
            formik.setFieldValue('lines', []);
            if (formik.values.department === 2) {
                const arrayData = lines.map((line) => ({
                    lineId: line.id ?? '',
                    floorId: line.floor_id ?? '',
                    lineName: line.line_name,
                    floorName: line.floor_name ?? '',
                    manPower: line.man_power ?? '',
                    manPowerCost: line.man_power_cost ?? '',
                    OTHours: line.ot_hours ?? '',
                    OTHoursCost: line.ot_hours_cost ?? '',
                }))
                formik.setFieldValue('lines', arrayData);
            }

        } else {
            formik.setFieldValue('lines', []);
        }
    }, [lines, formik.values.department]);

    return (
        <>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <Card className="card-settings mt-4">
                <Card.Header>
                    <Card.Title>{isEditMode ? "Update" : "Create"} HR Input</Card.Title>
                </Card.Header>
                <Card.Body className="p-4">
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit}>
                            <Row>
                                <Col xs={12} md={2}>
                                    {/*<Form.Label htmlFor="Date" className="col-form-label"><h6>Date</h6></Form.Label>*/}
                                    <h6 className="col-form-label">Date</h6>
                                </Col>
                                <Col xs={12} md={4} className="mt-2">
                                    <Form.Control type="date" name="inputDate" id="Date" value={formik.values.inputDate}
                                                  onChange={(e) => {
                                                      formik.setFieldValue('inputDate', e.target.value)
                                                  }} placeholder="Date"
                                                  isInvalid={!!formik.errors.inputDate && formik.touched.inputDate}
                                                  className="form-control"/>
                                    {formik.errors.inputDate && formik.touched.inputDate && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.inputDate}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                <Col xs={12} md={2} className="ml-5">
                                    {/*<Form.Label htmlFor="unitId" className="col-form-label"><h6>Unit</h6></Form.Label>*/}
                                    <h6 className="col-form-label">Unit</h6>
                                </Col>
                                <Col xs={12} md={4} className="mt-2">
                                    <Form.Control type="text" name="unitId" id="unitId"
                                                  value="New Zeel Rainwear"
                                                  onChange={(e) => {
                                                      // formik.setFieldValue('unitId', e.target.value)
                                                  }} placeholder="Company Name"
                                                  disabled={true}
                                                  isInvalid={!!formik.errors.unitId && formik.touched.unitId}/>
                                    {formik.errors.unitId && formik.touched.unitId && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.unitId}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                <Col xs={12} md={2}>
                                    {/*<Form.Label htmlFor="department" className="col-form-label"><h6>Select*/}
                                    {/*    Department</h6></Form.Label>*/}
                                    <h6 className="col-form-label">Department</h6>
                                </Col>
                                <Col xs={12} md={4} className="mt-2">
                                    <Select name="department" options={departmentType} isSearchable={true} isClearable
                                            value={selectedDepartment}
                                            className="is-invalid" onChange={(newValue) => {
                                        setSelectedDepartment(newValue);
                                        formik.setFieldValue('department', newValue.value);
                                    }}/>
                                    {formik.errors.department && formik.touched.department && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.department}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>
                                <Col xs={12} md={2}>
                                    {/*<Form.Label htmlFor="floor" className="col-form-label"><h6>Floors</h6></Form.Label>*/}
                                    <h6 className="col-form-label ml-3">Floors</h6>
                                </Col>
                                <Col xs={12} md={4} className="mt-2">

                                    <Select name="floor" options={floors} isSearchable={true} isClearable
                                            value={selectedFloor}
                                            className="is-invalid" onChange={(newValue) => {
                                        setSelectedFloor(newValue);
                                        formik.setFieldValue('floor', newValue.value);
                                    }}/>

                                    {formik.errors.floor && formik.touched.floor && (
                                        <Form.Control.Feedback type="invalid">
                                            {formik.errors.floor}
                                        </Form.Control.Feedback>
                                    )}
                                </Col>



                                {(formik.values.department === 1 || formik.values.department === 3) && formik.values.floor!==""  && (
                                    <>
                                        <Col xs={12} md={2}>
                                            {/*<Form.Label htmlFor="manPower"*/}
                                            {/*            className="col-form-label">ManPower</Form.Label>*/}
                                            <h6 className="col-form-label">ManPower</h6>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-2">
                                            <Form.Control type="number" name="manPower" id="manPower"
                                                          value={formik.values.manPower}
                                                          onChange={(e) => {
                                                              formik.setFieldValue('manPower', e.target.value)
                                                          }} placeholder="manPower"
                                                          isInvalid={!!formik.errors.manPower && formik.touched.manPower}
                                                          className="form-control"/>
                                            {formik.errors.manPower && formik.touched.manPower && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.manPower}
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs={12} md={2}>
                                            {/*<Form.Label htmlFor="cost" className="col-form-label">ManPower*/}
                                            {/*    Cost</Form.Label>*/}
                                            <h6 className="col-form-label">ManPower Cost</h6>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-2">
                                            <Form.Control type="number" name="manPowerCost" id="manPowerCost"
                                                          value={formik.values.manPowerCost}
                                                          onChange={(e) => {
                                                              formik.setFieldValue('manPowerCost', e.target.value)
                                                          }} placeholder="manPowerCost"
                                                          isInvalid={!!formik.errors.manPowerCost && formik.touched.manPowerCost}
                                                          className="form-control"/>
                                            {formik.errors.manPowerCost && formik.touched.manPowerCost && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.manPowerCost}
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs={12} md={2}>
                                            {/*<Form.Label htmlFor="OTHours" className="col-form-label">OT*/}
                                            {/*    Hours</Form.Label>*/}
                                            <h6 className="col-form-label">OT Hours</h6>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-2">
                                            <Form.Control type="number" name="OTHours" id="OTHours"
                                                          value={formik.values.OTHours}
                                                          onChange={(e) => {
                                                              formik.setFieldValue('OTHours', e.target.value)
                                                          }} placeholder="OTHours"
                                                          isInvalid={!!formik.errors.OTHours && formik.touched.OTHours}
                                                          className="form-control"/>
                                            {formik.errors.OTHours && formik.touched.OTHours && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.OTHours}
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs={12} md={2}>
                                            {/*<Form.Label htmlFor="OTHoursCost" className="col-form-label">OT Hours*/}
                                            {/*    Cost</Form.Label>*/}
                                            <h6 className="col-form-label">OT Hours Cost</h6>
                                        </Col>
                                        <Col xs={12} md={4} className="mt-2">
                                            <Form.Control type="number" name="OTHoursCost" id="OTHoursCost"
                                                          value={formik.values.OTHoursCost}
                                                          onChange={(e) => {
                                                              formik.setFieldValue('OTHoursCost', e.target.value)
                                                          }} placeholder="OTHoursCost"
                                                          isInvalid={!!formik.errors.OTHoursCost && formik.touched.OTHoursCost}
                                                          className="form-control"/>
                                            {formik.errors.OTHoursCost && formik.touched.OTHoursCost && (
                                                <Form.Control.Feedback type="invalid">
                                                    {formik.errors.OTHoursCost}
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>

                                    </>
                                )}

                                {formik.values.floor !== "" && formik.values.lines.length > 0 && formik.values.department === 2 && (
                                    <FieldArray
                                        name="lines"
                                        render={() => (
                                            <div>
                                                {formik.values.lines.length > 0 && formik.values.lines.map((line, index) => (
                                                    <Row key={index}>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Floor*/}
                                                            {/*    Name</Form.Label>*/}
                                                            <h6 className="col-form-label">Floor Name</h6>
                                                            <Form.Control type="text" name={`lines[${index}].floorName`}
                                                                          value={formik.values.lines[index].floorName ?? "no line"}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].floorName`, e.target.value)
                                                                          }} placeholder="Floor"
                                                                          disabled={true}
                                                            />
                                                            <ErrorMessage name={`lines[${index}].floorName`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Line</Form.Label>*/}
                                                            <h6 className="col-form-label">Line</h6>
                                                            <Form.Control type="text" name={`lines[${index}].lineName`}
                                                                          value={formik.values.lines[index].lineName ?? "no line"}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].lineName`, e.target.value)
                                                                          }} placeholder="Line"
                                                                          disabled={true}
                                                            />
                                                            <ErrorMessage name={`lines[${index}].lineName`}>{msg => <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">Man*/}
                                                            {/*    Power</Form.Label>*/}
                                                            <h6 className="col-form-label">Man Power</h6>
                                                            <Form.Control type="text" name={`lines[${index}].manPower`}
                                                                          value={formik.values.lines[index].manPower}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].manPower`, e.target.value)
                                                                          }} placeholder="Man Power"
                                                            />

                                                            <ErrorMessage name={`lines[${index}].manPower`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>
                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">ManPower*/}
                                                            {/*    Cost</Form.Label>*/}
                                                            <h6 className="col-form-label">ManPower Cost</h6>
                                                            <Form.Control type="text"
                                                                          name={`lines[${index}].manPowerCost`}
                                                                          value={formik.values.lines[index].manPowerCost}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].manPowerCost`, e.target.value)
                                                                          }} placeholder="Man Power Cost"
                                                            />
                                                            <ErrorMessage name={`lines[${index}].manPowerCost`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>

                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">OT Hours</Form.Label>*/}
                                                            <h6 className="col-form-label">OT Hours</h6>
                                                            <Form.Control type="text" name={`lines[${index}].OTHours`}
                                                                          value={formik.values.lines[index].OTHours}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].OTHours`, e.target.value)
                                                                          }} placeholder="OT Hours"
                                                            />
                                                            <ErrorMessage name={`lines[${index}].OTHours`}>{msg => <div
                                                                className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                        <Col xs={12} md={2} className="mt-2">
                                                            {/*<Form.Label className="col-form-label">OT Hours*/}
                                                            {/*    Cost</Form.Label>*/}
                                                            <h6 className="col-form-label">OT Hours Cost</h6>
                                                            <Form.Control type="text"
                                                                          name={`lines[${index}].OTHoursCost`}
                                                                          value={formik.values.lines[index].OTHoursCost}
                                                                          onChange={(e) => {
                                                                              formik.setFieldValue(`lines[${index}].OTHoursCost`, e.target.value)
                                                                          }} placeholder="OT Hours Cost"
                                                            />
                                                            <ErrorMessage name={`lines[${index}].OTHoursCost`}>{msg =>
                                                                <div
                                                                    className="text-danger">{msg}</div>}</ErrorMessage>

                                                        </Col>
                                                    </Row>
                                                ))}
                                            </div>
                                        )}
                                    />
                                )}


                                <Col xs={12} md={5} className="d-flex align-items-center">
                                    <Button type="submit" variant="primary" className="btn-sign mt-4">Submit</Button>
                                </Col>
                            </Row>
                        </Form>
                    </FormikProvider>
                </Card.Body>
            </Card>
        </>
    );
};
export default HrForm;
