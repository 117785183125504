import axios from "axios";
import {BASE_URL} from "../../utils/urls";

export const createLine = async (lineName,floor, token) => {
    const data = JSON.stringify({
        line_name:lineName,
        floor_id:floor
    });
    const res = await axios.post(`${BASE_URL}/create-line`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};

export const fetchLineList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/get-line-list`, data, config);
    return res.data;
};


export const getLine = async (lineId, token) => {
    const res = await axios.get(`${BASE_URL}/get-line/${lineId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const getLines = async (floorId, token) => {
    const res = await axios.get(`${BASE_URL}/get-lines/${floorId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const updateLine = async (
    lineId,
    lineName,
    floor,
    token,

) => {

    const data = JSON.stringify({
        id: lineId,
        line_name:lineName,
        floor_id:floor
    });


    const res = await axios.post(`${BASE_URL}/update-line`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const deleteLine = async (lineId, token) => {
    const res = await axios.delete(`${BASE_URL}/delete-line/${lineId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


