import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import {Link} from "react-router-dom";

export default function HrKpis() {
    function capitalizeEveryWord(sentence) {
        return sentence.replace(/\b\w/g, c => c.toUpperCase());
    }
    function kpisButton(icon, color, name, data,route) {

        {/*return <Col sm={12} md={3} lg={12} xl={3} style={{height: 80}}>*/}
        {/*    <Link to={route}>*/}
        {/*        <Card className="shadow">*/}
        {/*            <div className="d-flex">*/}
        {/*                <div className={`w-30 p-2 ${color} text-white d-flex justify-content-center align-items-center`}>*/}
        {/*                    <i class={`${icon} larger-icon`}></i>*/}
        {/*                </div>*/}
        {/*                <div className={`w-70 p-2 ${isPositiveNumber(data)?"text-success":"text-danger"}`}>*/}
        {/*                    <span className="text-nowrap">{name}</span> <br/> {data}*/}
        {/*                </div>*/}
        {/*            </div>*/}
        {/*        </Card>*/}
        {/*    </Link>*/}
        {/*</Col>;*/}
        return <Col xs="6" xl="3">
            <Link to={route}>
                <Card className="card-one">
                    <Card.Body>
                        <Card.Title as="label" className="fs-sm fw-medium mb-1">{capitalizeEveryWord(name)}</Card.Title>
                        <h3 className="card-value mb-1"><i className={icon}></i> {data}</h3>
                        <small><span
                            className={"d-inline-flex text-" + (("up" === "up") ? "success" : "danger")}>10% <i
                            className={"ri-arrow-" + (("down" === "up") ? "up" : "down") + "-line"}></i></span> than
                            last week</small>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
    }

    return (
        <>
            <Row className="g-3">
                <h2 className="text-center">HR Kips</h2>
            </Row>
            <Row className="g-3 mt-2">
                {kpisButton("ri-briefcase-4-line", "bg-success", "Attrition", "44544554","hr/attrition")}
                {kpisButton("ri-briefcase-4-line", "bg-danger", "ABSENTEEISM", "70%","hr/absenteeism")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "MMR", "10/40","hr/mmr")}
                {kpisButton("ri-briefcase-4-line", "bg-info", "MALE FELAME RATIO", "40/60","hr/male-felame-ratio")}
                {kpisButton("ri-briefcase-4-line", "bg-success", "STRENGTH DEPARTMENT WISE", "20-40","hr/strength-department-wise")}
            </Row>
        </>
    );
};