import React, {useEffect, useState} from "react";
import {Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import FloorTable from "./FloorTable";
import {loginSelector} from "../../../store/login-slice";
import {MainComponent} from "../../../components/MainComponent";
import Footer from "../../../layouts/Footer";
import {fetchFloorList} from "../../../services/master/floor-service";

export default function Floor() {

    const {token} = useSelector(loginSelector);
    const [loader, setLoader] = useState(false);

    //table
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10; // Number of items to display per page

    const currentPageHandle = (page) => {
        setCurrentPage(page);
    }

    const getAllFloorList = async () => {
        setLoader(true);
        try {
            const result = await fetchFloorList(currentPage, itemsPerPage, token);
            if (result.status) {
                setItems(result.data.data);
                setCurrentPage(result.data.page_number);
                setCurrentPage(result.data.page_number);
                setTotalPages(result.data.total_records >= 0 ?Math.ceil(result.data.total_records / itemsPerPage) : 1)
            }
        } catch (err) {
            console.log(err);
        }
        setLoader(false);

    };


    useEffect(() => {
        getAllFloorList();
    }, [currentPage]);




    return (
        <React.Fragment>
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <MainComponent>
                <div className="main main-app p-3 p-lg-4">
                    <div className="d-md-flex align-items-center justify-content-between mb-4">
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item"><Link href="#" to="/dashboard">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Floors</li>
                            </ol>
                            <h4 className="main-title mb-0">Floors</h4>
                        </div>
                    </div>

                    {/*<Row className="g-3">*/}
                    {/*    <FloorForm*/}
                    {/*        floorId={floorId || 0}*/}
                    {/*               isEditMode={isEditMode}*/}
                    {/*               clearForm={resetForm}*/}
                    {/*               submitHandler={(obj) => {*/}
                    {/*                   if (isEditMode) return updateHandler(floorId || 0, obj);*/}
                    {/*                   return submitHandler(obj);*/}
                    {/*               }}*/}
                    {/*               token={token}/>*/}
                    {/*</Row>*/}
                    <Row className="g-3">
                        <FloorTable items={items} currentPage={currentPage} totalPages={totalPages}
                                    currentPageHandle={currentPageHandle}/>
                    </Row>
                    <Footer/>
                </div>
            </MainComponent>
        </React.Fragment>
    );
}
