import axios from "axios";
import { BASE_URL } from "../utils/urls";

export const login = async (email, password) => {
    const data = JSON.stringify({
        email,
        password,
    });
    const res = await axios.post(`${BASE_URL}/auth/login`, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
    return res.data;
};


export const forgetPassword = async (email) => {
    const data = JSON.stringify({
        email
    });
    const res = await axios.post(`${BASE_URL}/auth/forget-password`, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
    return res.data;
};

export const resetPassword = async (password,token) => {
    const data = JSON.stringify({
        password,token
    });
    const res = await axios.post(`${BASE_URL}/auth/reset-password`, data, {
        headers: {
            "Content-Type": "application/json",
        },
    });
    return res.data;
};


export const logout = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/logout`, {}, config);
    return res.data;
};

