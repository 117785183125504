import React, {useState} from "react";
import {Button, Card, Col, Container, Form, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

import pageSvg from "../../assets/svg/forgot_password.svg";
import {useFormik} from "formik";
import {forgetPasswordSchema} from "../../schemas";
import {forgetPassword} from "../../services/auth-services";
import {toast} from "react-toastify";

const initialValues = {
    email: "",
};
export default function ForgotPassword() {
    const [loader, setLoader] = useState(false);

    const resetPassword = async (email) => {
        setLoader(true);
        try {
            const result = await forgetPassword(email);
            if (result.status) {
                toast("Password Successfully Reset ! Check your Mail");
                setLoader(false);
            } else {
                toast(result.message);
                setLoader(false);
            }
        } catch (err) {
            setLoader(false);
        }
    }
    const {values, errors, handleBlur, handleChange, handleSubmit, resetForm} = useFormik({
        initialValues: initialValues, validationSchema: forgetPasswordSchema, onSubmit: ((values) => {
        resetPassword(values.email);
            resetForm();
        })
    })
    document.body.classList.remove("sidebar-show");

    return (
        <div className="page-auth">
            {loader && (<div className="overlay">
                <div className="loader"></div>
            </div>)}
            <div className="header">
                <Container>
                    <Link to="/" className="header-logo">SmartFactory</Link>
                    <Nav className="nav-icon">
                        <Nav.Link href=""><i className="ri-twitter-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-github-fill"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-dribbble-line"></i></Nav.Link>
                    </Nav>
                </Container>
            </div>

            <div className="content">
                <Container>
                    <Card className="card-auth">
                        <Card.Body className="text-center">
                            <div className="mb-5">
                                <object type="image/svg+xml" data={pageSvg} className="w-50"
                                        aria-label="svg image"></object>
                            </div>
                            <Card.Title>Reset your password</Card.Title>
                            <Card.Text className="mb-5">Enter your username or email address and we will send you a link
                                to reset your password.</Card.Text>

                            <Form onSubmit={handleSubmit}>
                                <Row className="g-2">
                                    <Col sm="8"><Form.Control type="text" name="email" value={values.email}
                                                              onChange={handleChange} onBlur={handleBlur}
                                                              placeholder="Enter your email address"
                                                              isInvalid={!!errors.email}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.email}
                                        </Form.Control.Feedback>
                                    </Col>
                                    <Col sm="4"><Button type="submit" variant="primary">Reset</Button></Col>

                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </div>
    );
}
