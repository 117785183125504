import axios from "axios";
import {BASE_URL} from "../utils/urls";

export const createUser = async (name, email, role, userIdType, empId, token, mobile) => {
    const data = JSON.stringify({
        name,
        email,
        user_role: role,
        emp_id_type: userIdType,
        emp_id: empId,
        mobile
    });
    const res = await axios.post(`${BASE_URL}/create-user-with-roles`, data, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const getUser = async (userId, token) => {
    const res = await axios.get(`${BASE_URL}/get-user-with-roles/${userId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};


export const updateUser = async (
    userId,
    name,
    email,
    role,
    token,
    userIdType,
    empId,
    mobile
) => {

    const data = JSON.stringify({
        id: userId,
        name,
        email,
        user_role: role,
        emp_id_type: userIdType,
        emp_id: empId,
        mobile
    });


    const res = await axios.post(`${BASE_URL}/update-user-with-roles`, data, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const fetchUserList = async (currentPage, itemsPerPage, token) => {
    const data = JSON.stringify({
        number_of_records: itemsPerPage,
        page_number: currentPage,
    });
    const config = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.post(`${BASE_URL}/user-list`, data, config);
    return res.data;
};


export const deleteUser = async (userId, token) => {
    const res = await axios.delete(`${BASE_URL}/delete-user-with-roles/${userId}`, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });
    return res.data;
};


export const getRouteAccess = async (token) => {
    const res = await axios.get(`${BASE_URL}/route-access`, {
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    });

    return res.data;
};

export const logout = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const res = await axios.get(`${BASE_URL}/logout`, config);
    return res.data;
};

