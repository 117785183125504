import React from "react";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Signin from "../pages/auth/Signin";
import ResetPassword from "../pages/auth/ResetPassword";

const publicRoutes = [
  { path: "pages/signin", element: <Signin /> },
  { path: "pages/forgot", element: <ForgotPassword /> },
  { path: "authentication/reset-password/:token", element: <ResetPassword /> },

];

export default publicRoutes;
